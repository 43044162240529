/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "survey-core/defaultV2.min.css";
import { useState, useContext } from "react";
import { useEffect } from "react";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import Toaster from "../Toaster/Toaster";
import { AppContext } from "../../store/context";
import LoadSurveyForm from "../../modules/ProgramDetails/ProgramView/LoadSurveyForm";
import EditIcon from "../../assets/edit.svg";
import moment from "moment";
import Modal from "../modal/Modal";

const UserViewComponent = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFeedbackAdmin, setIsFeedbackAdmin] = useState(false);
  const [isUserEditFeedback, setIsUserEditFeedback] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [modalJson, setModalJson] = useState();
  const [moduleDetail, setModuleDetail] = useState({});
  const [programDetail, setProgramDetail] = useState(null);
  const [moduleAnswerJSON, setModuleAnswerJSON] = useState(null);
  const [moduleAnswersJSON, setModuleAnswersJSON] = useState(null);
  const [moduleQuestionJSON, setModuleQuestionJSON] = useState(null);
  const [feedbackText, setFeedbacktext] = useState("");
  const { userDetails, participants, dispatchParticipantsEvent } =
    useContext(AppContext);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [feedbackForEdit, setFeedbackForEdit] = useState(null);
  const [feedbackForDelete, setFeedbackForDelete] = useState(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [showApprove, setShowApprove] = useState(false);
  useEffect(() => {
    if (moduleDetail?.programModuleID !== undefined) {
      getModuleQuestionList();
    }
  }, [moduleDetail]);

  useEffect(() => {
    if (moduleDetail?.programModuleID && programDetail?.programID) {
      getProgramModuleAnswers();
    }
  }, [programDetail]); //[modalJson]

  useEffect(() => {
    if (
      userDetails?.userID &&
      moduleDetail?.programModuleID &&
      programDetail?.programID
    ) {
      getFeedbackList();
    }

    if (userDetails?.email && moduleDetail) {
      if (
        userDetails?.roleNameList === "Security User" ||
        userDetails?.roleNameList === "Medical User" ||
        userDetails?.roleNameList === "Legal User" ||
        userDetails?.roleNameList === "Risk Management User" ||
        userDetails?.roleNameList === "Youth Safety Admin"
      ) {
        setShowApprove(true);
      } else {
        setShowApprove(false);
      }
      if (
        moduleDetail?.moduleStatus !== null &&
        (moduleDetail?.moduleStatus === "Completed" ||
          moduleDetail?.moduleStatus === "Submitted" ||
          moduleDetail?.moduleStatus === "Approved" ||
          moduleDetail?.moduleStatus === "Not Approved")
        // ||moduleDetail?.moduleStatus === "Requires Follow Up"
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
      if (
        userDetails?.roleNameList === "Youth Safety Admin" ||
        userDetails?.roleNameList === "Intl Bbops User" ||
        userDetails?.roleNameList === "Jr. NBA International Admin" ||
        userDetails?.roleNameList === "Intl Bbops Admin"
      ) {
        setIsFeedbackAdmin(true);
        setIsUserEditFeedback(true);
      } else if (
        userDetails?.roleNameList === "US Progam User" ||
        userDetails?.roleNameList === "Regional Program User"
      ) {
        setIsFeedbackAdmin(false);
      } else if (
        userDetails?.roleNameList === "Medical User" &&
        moduleDetail?.programModuleName === "Medical Module"
      ) {
        setIsFeedbackAdmin(true);
        setIsUserEditFeedback(false);
      } else if (
        userDetails?.roleNameList === "Legal User" &&
        moduleDetail?.programModuleName === "Legal & Registration Module"
      ) {
        setIsFeedbackAdmin(true);
        setIsUserEditFeedback(false);
      } else if (
        userDetails?.roleNameList === "Risk Management User" &&
        moduleDetail?.programModuleName === "Risk Management Module"
      ) {
        setIsFeedbackAdmin(true);
        setIsUserEditFeedback(false);
      } else if (
        userDetails?.roleNameList === "Security User" &&
        moduleDetail?.programModuleName === "Security Module"
      ) {
        setIsFeedbackAdmin(true);
        setIsUserEditFeedback(false);
      } else if (
        userDetails?.roleNameList === "Youth Events User" &&
        moduleDetail?.programModuleName === "Youth Safety Module"
      ) {
        setIsFeedbackAdmin(true);
        setIsUserEditFeedback(false);
      }
    }
  }, [userDetails, moduleDetail, programDetail]);

  useEffect(() => {
    if (userDetails?.roleNameList && location.state != null) {
      const moduleType = location.state.moduleDetails;
      setUserType(moduleType);
    } else if (userDetails?.email != "") {
      getProgramModule();
      if (location.pathname.includes("feedback")) {
        setTabActive(1);
      } else {
        setTabActive(0);
      }
    }
  }, [userDetails, location.state]);

  useEffect(() => {
    const stateparam = location.state;

    if (stateparam != null && stateparam.moduleDetails != undefined) {
      setTabActive(stateparam?.isfeedbackTab === true ? 1 : 0);
      let moduleProps = stateparam.moduleDetails;

      if (
        moduleProps?.moduleStatus !== null &&
        (moduleProps?.moduleStatus === "Completed" ||
          moduleProps?.moduleStatus === "Submitted" ||
          moduleProps?.moduleStatus === "Approved" ||
          moduleProps?.moduleStatus === "Not Approved")
        // ||moduleProps?.moduleStatus === "Requires Follow Up"
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
      setModuleDetail(moduleProps);
    }
    if (stateparam != null && stateparam?.programDetail) {
      setProgramDetail(stateparam.programDetail);
    } else {
      getProgramDetailsById();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const setUserType = (moduleType) => {
    const roleName = userDetails?.roleNameList;
    if (roleName) {
      let adminUpdate = false;
      if (
        roleName === "Youth Safety Admin" ||
        roleName === "Intl Bbops User" ||
        roleName === "Regional Program User" ||
        roleName === "US Progam User" ||
        roleName === "Jr. NBA International Admin" ||
        roleName === "Intl Bbops Admin"
      ) {
        adminUpdate = true;
      } else if (
        userDetails?.roleNameList === "Medical User" &&
        moduleType?.programModuleID == 7
      ) {
        adminUpdate = true;
      } else if (
        userDetails?.roleNameList === "Security User" &&
        moduleType?.programModuleID == 5
      ) {
        adminUpdate = true;
      } else if (
        userDetails?.roleNameList === "Youth Events User" &&
        moduleType?.programModuleID == 3
      ) {
        adminUpdate = true;
      } else if (
        userDetails?.roleNameList === "Legal User" &&
        moduleType?.programModuleID == 4
      ) {
        adminUpdate = true;
      } else if (
        userDetails?.roleNameList === "Risk Management User" &&
        moduleType?.programModuleID == 6
      ) {
        adminUpdate = true;
      } else {
        adminUpdate = false;
      }

      setIsAdmin(adminUpdate);
    }
  };

  const getProgramModule = () => {
    setLoading(true);
    const programID = location.pathname.split("/")[2];
    const moduleID = location.pathname.split("/")[4];
    const payload = {
      UserId: userDetails.userID,
      ProgramID: programID !== null ? programID : 0,
    };
    APIHelper.post(APIURLConstant.GET_PROGRAM_MODULES, payload)
      .then((res) => {
        console.log(res);
        setLoading(false);
        let updatedModule = [];
        res?.forEach((item) => {
          updatedModule.push({
            ...item,
            moduleStatus:
              item?.moduleStatus == null
                ? "To Do"
                : item?.moduleStatus == "Not Approved"
                ? "Requires Follow Up"
                : item?.moduleStatus,
          });
        });
        if (updatedModule) {
          updatedModule.forEach((item) => {
            if (item.programModuleID == moduleID) {
              setModuleDetail(item);
              setUserType(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProgramDetailsById = (programId) => {
    const programID = location.pathname.split("/")[2];
    setLoading(true);

    APIHelper.get(APIURLConstant.GET_PROGRAM_DETAILS_BY_ID(programID))
      .then((res) => {
        console.log(res);
        setProgramDetail(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getFeedbackList = () => {
    setLoading(true);
    APIHelper.get(
      APIURLConstant.GET_MODULE_FEEDBACK(
        programDetail.programID,
        userDetails.userID,
        moduleDetail.programModuleID
      )
    )
      .then((res) => {
        setFeedbackList(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getModuleQuestionList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_MODULE_QUESTION_JSON, {
      ModuleID: moduleDetail.programModuleID,
    })
      .then((res) => {
        if (res.questionsJSON) {
          const obj = JSON.parse(res.questionsJSON);
          const pages = JSON.parse(obj);
          const page0 = pages.pages;
          const allPages = { name: "Participant Module", elements: page0 };
          //setModalJson(allPages);
          //setModalJson(page0[0]);
          setModalJson(pages);
          //setModalJson(page0);
          setModuleQuestionJSON(res);
        }
        if (moduleAnswersJSON && Object.keys(moduleAnswersJSON).length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProgramModuleAnswers = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_MODULE_ANSWERJS_BY_PROGRAM_AND_MODULE, {
      ProgramID: programDetail.programID,
      ModuleID: moduleDetail.programModuleID,
    })
      .then((res) => {
        setModuleAnswerJSON(res);
        if (res.moduleAnswerJS) {
          const obj = JSON.parse(res.moduleAnswerJS);
          setModuleAnswersJSON({ ...obj });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function storeResult(
    surveyanswersJSON,
    answersJSON,
    isAllQuestionsAnswered,
    isMarkAsCompleted
  ) {
    setLoading(true);
    const payLoad = {
      moduleAnswersJSID: moduleAnswerJSON
        ? moduleAnswerJSON.moduleAnswersJSID
        : 0,
      moduleQuestionJSID: moduleQuestionJSON
        ? moduleQuestionJSON.moduleQuestionJSID
        : 0,
      programID: programDetail.programID,
      moduleAnswerJS: answersJSON, //JSON.stringify(sender.data, null, 3),
      ModuleSurveyAnswerJS: surveyanswersJSON,
      isDeleted: true,
      isActive: true,
    };
    APIHelper.post(APIURLConstant.POST_MODULE_ANSWER_JSON, payLoad)
      .then((res) => {
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Module answer submitted successfully",
        });
        //update Participant count
        if (
          moduleQuestionJSON.moduleID === 1 &&
          Object.keys(JSON.parse(answersJSON)).length > 0
        ) {
          // const participantObj = JSON.parse(answersJSON).question3[0];
          // const youthsCount  = participantObj.question4 || participantObj.question9 || 0
          // const coachesCount = participantObj.question5 || participantObj.question10 || 0
          // const volunteerscount = participantObj.question6 || participantObj.question11 || 0
          // if (participants) {
          //   participants.coachesCount = participants.coachesCount + parseInt(coachesCount)
          //   participants.youthsCount = participants.youthsCount + parseInt(youthsCount)
          //   participants.volunteerscount = participants.volunteerscount + parseInt(volunteerscount)
          //   dispatchParticipantsEvent("ADD_COUNT", participants)
          // }
          setLoading(true);
          APIHelper.post(APIURLConstant.GET_PARTICIPANTS_COUNT, {
            ProgramID: null,
            UserEmail: userDetails.email,
          })
            .then((data) => {
              if (data) {
                const participantObj = data;
                dispatchParticipantsEvent("ADD_COUNT", participantObj);
                setLoading(false);
              } else {
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
        let stausText = "";
        if (
          moduleDetail?.programModuleID == 4 ||
          moduleDetail?.programModuleID == 6 ||
          moduleDetail?.programModuleID == 7 ||
          moduleDetail?.programModuleID == 3 ||
          moduleDetail?.programModuleID == 5
        ) {
          stausText = isAdmin ? "Submitted" : "In Progress";
        } else {
          stausText = isAdmin ? "Completed" : "In Progress";
        }
        updateNotification(null, null, "Module Update Notification");
        updateModuleStatus(
          programDetail.programID,
          moduleDetail.programModuleID,
          isAllQuestionsAnswered ? stausText : "InProgress",
          isMarkAsCompleted
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while submitting a module answer",
        });
      });
  }

  const updateModuleStatus = (
    programId,
    moduleId,
    moduleStatus,
    isMarkAsCompleted
  ) => {
    setLoading(true);
    const payload = {
      ProgramId: programId,
      ModulesId: moduleId.toString(),
      Status: moduleStatus,
    };
    APIHelper.post(APIURLConstant.UPDATE_PROGRAM_MODULE_STATUS, payload)
      .then((res) => {
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Module status updated successfully",
        });
        if (isMarkAsCompleted) {
          navigate(`/program-details/${programDetail?.programID}`, {
            state: { selectedProgram: [programDetail], tabTwoActive: true },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while updating module status",
        });
      });
  };

  // feedback submit

  const handleSubmitFeedback = (status) => {
    let payLoad = {};

    if (feedbackForDelete !== null && feedbackForDelete?.feedbackID) {
      payLoad = {
        feedbackID:
          feedbackForDelete !== null ? feedbackForDelete?.feedbackID : 0,
        moduleID: feedbackForDelete?.moduleID,
        programID: feedbackForDelete?.programID,
        feedbackText: feedbackText,
        moduleName: moduleDetail?.programModuleName,
        isDeleted: true,
        createdBy:
          feedbackForDelete !== null
            ? feedbackForDelete?.createdBy
            : userDetails?.userID,
        createdDate:
          feedbackForDelete !== null
            ? feedbackForDelete?.createdDate
            : moment(new Date()).format(),
        modifiedBy: userDetails?.userID,
        modifiedDate: moment(new Date()).format(),
        status: status,
      };
      saveFeedback(payLoad);
    } else {
      if (feedbackText && feedbackText.length > 0) {
        payLoad = {
          feedbackID:
            feedbackForEdit !== null ? feedbackForEdit?.feedbackID : 0,
          moduleID: moduleDetail?.programModuleID,
          programID: programDetail?.programID,
          feedbackText: feedbackText,
          moduleName: moduleDetail?.programModuleName,
          isDeleted: false,

          createdBy:
            feedbackForEdit !== null
              ? feedbackForEdit?.createdBy
              : userDetails?.userID,
          createdDate:
            feedbackForEdit !== null
              ? feedbackForEdit?.createdDate
              : moment(new Date()).format(),
          modifiedBy: userDetails?.userID,
          modifiedDate: moment(new Date()).format(),
          status: status,
        };
        saveFeedback(payLoad);
      } else {
        setToasterMessage({
          errorType: "error",
          showToaster: true,
          message: "Feedback field is mandatory.",
        });
      }
    }
  };

  const saveFeedback = (payLoad) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.POST_MODULE_FEEDBACK, payLoad)
      .then((res) => {
        const messageText =
          feedbackForEdit !== null
            ? "Feedback updated successfully."
            : feedbackForDelete !== null
            ? "Feedback Deleted successfully."
            : "Feedback submitted successfully.";
        setToasterMessage({
          errorType: "success",
          showToaster: true,
          message: messageText,
        });
        updateNotification(feedbackText, res, "Module Feedback Notification");
        setLoading(false);
        setFeedbackForEdit(null);
        setFeedbackForDelete(null);
        setFeedbacktext("");
        getFeedbackList();
      })
      .catch((err) => {
        console.log(err);

        setToasterMessage({
          errorType: "error",
          showToaster: true,
          message: "Error while submitting a feedback.",
        });
        setLoading(false);
      });
  };

  const updateNotification = (feedbackText, feedbackId, notificationType) => {
    const notificationObject = {
      NotificationID: 0,
      ProgramID: programDetail?.programID,
      FeedbackID: feedbackId,
      ModuleID: moduleDetail?.programModuleID,
      CreatedBy: userDetails.userID,
      IsDelete: false,
      IsRead: false,
      IsNotificationDelete: false,
      ToUserID: 0,
      NotificationIDs: null,
      NotificationType: notificationType,
      SendEmail: true,
      CommentText: feedbackText,
    };
    APIHelper.post(APIURLConstant.ADD_UPDATE_NOTIFICATIONS, notificationObject)
      .then((data) => {
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleEditFeedback = (item) => {
    setShowModal(true);
    setFeedbackForEdit(item);
    setModalMessage({
      title: "Edit",
      message: "Do you want to Edit this Feedback ?",
    });
  };
  const handleEditConfirm = () => {
    setFeedbacktext(feedbackForEdit?.feedbackText);
    setShowModal(false);
  };
  const handleDeleteFeedback = (item) => {
    setShowModal(true);
    setFeedbackForDelete(item);
    setModalMessage({
      title: "Delete",
      message: "Do you want to Delete this Feedback ?",
    });
  };

  const handleDeletConfirm = () => {
    setShowModal(false);
    handleSubmitFeedback(showApprove ? "Approved" : "Submitted");
  };
  return (
    <div className="mt-16 md:px-5 px-2">
      <Loader showLoading={loading} />

      <div className="flex flex-wrap">
        <button
          className="text-[#1D428A] mr-2"
          data-te-toggle="tooltip"
          title="Back"
          onClick={() =>
            navigate(`/program-details/${programDetail?.programID}`, {
              state: { selectedProgram: [programDetail], tabTwoActive: true },
            })
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
              clipRule="evenodd"
            />
          </svg>{" "}
        </button>
        <button
          onClick={() => setTabActive(0)}
          className={`font-medium hidden md:block px-4 py-2 text-md ${
            tabActive === 0
              ? "text-[#1D428A] border-b-2 border-[#C8102E] "
              : " text-neutral-500"
          }  `}
        >
          Information
        </button>
        <button
          onClick={() => setTabActive(1)}
          className={`font-medium hidden md:block px-4 py-2 text-md ${
            tabActive === 1
              ? "text-[#1D428A] border-b-2 border-[#C8102E] "
              : " text-neutral-500"
          }  `}
        >
          Feedback
        </button>
      </div>
      {modalJson || moduleAnswersJSON ? (
        <>
          {tabActive === 0 ? (
            <div className="md:p-2 p-1 bg-white border rounded">
              <LoadSurveyForm
                surveyDataElements={moduleAnswersJSON}
                modalJson={modalJson}
                storeResult={storeResult}
                isAdmin={isAdmin}
                isModuleData={true}
                moduleDetails={moduleDetail}
              />
            </div>
          ) : (
            <div className="p-2">
              {/* Feedback form */}
              {isFeedbackAdmin === true && (
                <div className=" border bg-white shadow-md rounded p-2">
                  <textarea
                    placeholder="Enter feedback..."
                    className="border rounded w-full px-1 md:h-[6rem] focus:outline-none focus:shadow-outline"
                    value={feedbackText}
                    onChange={(e) => setFeedbacktext(e.target.value)}
                  />
                  <div className="w-full flex justify-end gap-2 flex-wrap">
                    <button
                      onClick={() => {
                        setFeedbackForEdit(null);
                        setFeedbacktext("");
                      }}
                      className="bg-white p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-blue-900 border border-blue-900"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleSubmitFeedback("Submitted")}
                      className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      data-galabel={
                        feedbackForEdit == null
                          ? `Create Feedback ${programDetail?.localProgramName} ${moduleDetail?.programModuleName}`
                          : `Edit Feedback ${programDetail?.localProgramName} ${moduleDetail?.programModuleName}`
                      }
                    >
                      {feedbackForEdit !== null ? "Update" : "Submit"}
                    </button>

                    {showApprove && (
                      <button
                        disabled={disableButton}
                        onClick={() => handleSubmitFeedback("Approved")}
                        className={`${
                          disableButton ? "bg-gray-400" : "bg-blue-900"
                        } p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white`}
                        data-galabel={`Approve Module ${programDetail?.localProgramName} ${moduleDetail?.programModuleName}`}
                      >
                        Approve
                      </button>
                    )}
                    {showApprove && (
                      <button
                        // disabled={disableButton}
                        onClick={() =>
                          handleSubmitFeedback("Requires Follow up")
                        }
                        className={`bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white`}
                        data-galabel={`Requires to follow up Module ${programDetail?.localProgramName} ${moduleDetail?.programModuleName}`}
                      >
                        Requires Follow Up
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/* Feedback list */}

              <div className="mt-4">
                {feedbackList && feedbackList.length > 0 ? (
                  <>
                    {feedbackList.map((item) => {
                      if (item.isDeleted !== true) {
                        return (
                          <div
                            className={`border bg-white shadow-md rounded w-full px-2 flex grid grid-cols-1 md:grid-cols-2 ${
                              item.modifiedByName !== null
                                ? "lg:grid-cols-7"
                                : "lg:grid-cols-6"
                            }  items-center mb-2`}
                          >
                            <div className="w-full md:col-span-2 text-center md:text-start truncate py-2">
                              <p>{item.feedbackText}</p>
                            </div>
                            <div className="w-full text-center md:text-start lg:text-center py-2">
                              <p className="text-[12px] bold">Status :</p>

                              <p>{item.status}</p>
                            </div>
                            <div className="w-full text-center md:text-start lg:text-center py-2">
                              <p className="text-[12px] bold">Created By :</p>
                              <p>{item.userName}</p>
                            </div>
                            {item.modifiedByName !== null && (
                              <div className="w-full text-center md:text-start lg:text-center py-2">
                                <p className="text-[12px] bold">
                                  ModifiedBy By :
                                </p>

                                <p>{item.modifiedByName}</p>
                              </div>
                            )}
                            <div className="w-full text-center md:text-start lg:text-center py-2">
                              <p className="text-[12px] bold">
                                Modified Date :
                              </p>
                              <p>
                                {moment(item.modifiedDate).format("MM/DD/YYYY")}
                              </p>
                            </div>
                            {isFeedbackAdmin === true && (
                              <div className="w-full flex  gap-2 flex-wrap justify-center md:justify-start lg:justify-end py-2">
                                <button
                                  data-te-toggle="tooltip"
                                  title="Edit"
                                  className={`${
                                    isUserEditFeedback === true
                                      ? "block"
                                      : isUserEditFeedback === false &&
                                        userDetails?.userID === item.createdBy
                                      ? "block"
                                      : "hidden"
                                  }`}
                                  onClick={() => handleEditFeedback(item)}
                                >
                                  <img
                                    src={EditIcon}
                                    className="h-15 w-5"
                                    alt="edit"
                                  />
                                </button>
                                <button
                                  className={`text-[#c8102e] ${
                                    isUserEditFeedback === true
                                      ? "block"
                                      : isUserEditFeedback === false &&
                                        userDetails?.userID === item.createdBy
                                      ? "block"
                                      : "hidden"
                                  }`}
                                  data-te-toggle="tooltip"
                                  title="Delete"
                                  onClick={() => handleDeleteFeedback(item)}
                                  data-galabel={`Delete Feedback ${programDetail?.localProgramName} ${moduleDetail?.programModuleName}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
                  </>
                ) : (
                  <div className="border bg-white shadow-md rounded w-full text-center">
                    No Data
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      <Modal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Edit" ? handleEditConfirm : handleDeletConfirm
        }
      />
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};

export default UserViewComponent;
