import React, { useState, useEffect } from "react";
import Select from "react-select";
import CloseIcon from "../../assets/close-blue.svg";
import Toaster from "../../sharedComponent/Toaster/Toaster";

ReviwerModal.defaultProps = {
  isModalVisible: false,
};

export default function ReviwerModal(props) {
  // const { title, message } = props.modalMessage;
  const [programReviwer, setProgramReviwer] = useState(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const handleChangeProgramReviwer = (value) => {
    setProgramReviwer(value);
  };

  const handleUpdateReviewerClick = (e)=> {
    if (programReviwer !== null){
      setProgramReviwer(null)
      props.handleClick(programReviwer)
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select Reviewer!",
      });
    }
  };

  useEffect(() => {
    setProgramReviwer(null)
  }, [props.reviwerList]);

  return (
    <>
      {props.showModal ? (
        <>
            <div
              id="medium-modal"
              tabIndex="-1"
              className="justify-center md:ustify-center md:items-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none"
            >
              <div className="relative w-full h-full max-w-lg md:h-auto">
                <div className="relative bg-white rounded-lg shadow-xl dark:bg-gray-900">
                <div className="flex items-start opacity-6 justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-2xl font-semibold">
                      {props.modalMessage.title}
                    </h3>
                    <button
                      className="p-1 ml-auto outline-none focus:outline-none"
                      onClick={() => props.handleClose()}
                    >
                      <img
                        src={CloseIcon}
                        className="h-15 w-5 transparent "
                        alt="close"
                      />
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative flex-auto text-center">
                    <p className="mt-4 text-slate-500 text-lg leading-relaxed ml-4" style={{ textAlign: "justify" }}>
                      <ol>
                        {props.selectedPrograms?.map(item => {
                          return <li key={item}>- {item}</li>;
                        })}
                      </ol>
                    </p>
                    <p className="mt-4 text-slate-500 text-lg leading-relaxed">
                      {props.modalMessage.message}
                    </p>
                  </div>
                  <div className="p-4 flex flex-wrap">
                  <Select
                    value={programReviwer}
                    options={props.reviwerList}
                    className={`w-full focus:outline-none focus:shadow-outline text-gray-900 rounded`}
                    placeholder="Select Program Reviewer"
                    onChange={(e) => handleChangeProgramReviwer(e)}
                  />
                  </div>
                  <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                          type="button"
                          onClick={() => props.handleClose()}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-900 h-8 p-1 w-2/6 font-semibold rounded-md text-white"
                          type="button"
                          onClick={(e) => handleUpdateReviewerClick(e)}
                        >
                          Update Reviewer
                        </button>
                      </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            <Toaster
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />
        </>
      ) : null}
    </>
  );
}
