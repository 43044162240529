import { CSVLink } from "react-csv";
import DownloadExcel from "../../assets/download_excel.svg";

const exportExcel = (props) => {
  const headers = [
    { label: "Program ID", key: "programID" },
    { label: "Program Category", key: "programCategory" },
    { label: "Program Type", key: "programType" },
    { label: "Program Duration", key: "duration" },
    { label: "Local Program Name", key: "localProgramName" },
    { label: "Region", key: "regionName" },
    { label: "State", key: "stateName" },
    { label: "City", key: "city" },
    { label: "Country", key: "countryName" },
    { label: "Start Date", key: "overallStartDate" },
    { label: "End Date", key: "overallEndDate" },
    { label: "Agency", key: "agencyList" },
    { label: "Sponsors", key: "sponsorsList" },
    { label: "Program Stage", key: "programStage" },
    { label: "Program Description", key: "programDescription" },
  ];

  return (
    <CSVLink
      filename={"Huddle Program List.csv"}
      headers={headers}
      data={props.data}
    >
      <img src={DownloadExcel} className="h-8 w-8" alt="downloadExcel" />
    </CSVLink>
  );
};

export default exportExcel;
