import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Select from "react-select";
import { AutoComplete, Modal, Table } from "../../sharedComponent";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import Loader from "../../sharedComponent/Loader/Loader";
import Pagination from "../../sharedComponent/Pagination/Pagination";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import { Multiselect } from "multiselect-react-dropdown";
import Helper from "../../utils/config/helper";

const ProgramType = () => {
  const { userDetails, userCategories, dispatchCategories } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [programCategory, setProgramCategory] = useState([]);
  const [selectedProgramCategory, setSelectedProgramCategory] = useState(null);
  const [programTypeInputValue, setProgramTypeInputValue] = useState("");
  const [programTypeValue, setProgramTypeValue] = useState([]);
  const [programTypeText, setProgramTypensTypeText] = useState("");
  const [programType, setProgramType] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState(null);
  const [disableProgramType, setDisableprogramType] = useState(true);
  const [programTypeList, setProgramTypeList] = useState([]);

  const [isActive, setIsActive] = useState(true);
  const [pageSize, setPageSize] = useState(Helper.getStorage("pageSize"));
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedProgramTypeObj, setSelectedProgramTypeObj] = useState(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    programCategoryError: false,
    programTypeError: false,
  });
  const createOption = (label) => ({
    label,
    value: label,
  });
  const [sortStatus, setSortStatus] = useState("normal");
  const [sortStatusText, setSortStatusText] = useState("");
  //
  const [searchText, setSearchText] = useState("");
  const [programCategoryFilters, setProgramCategoryFilters] = useState("");
  const [programTypesFilters, setProgramTypesFilters] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#0D47A1",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFFFFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#0D47A1",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    if (userDetails.roleIDList) {
      getProgramCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.roleIDList]);

  useEffect(() => {
    getProgramType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgramCategory]);

  useEffect(() => {
    getProgramTypeList(
      currentPage * 10,
      pageSize,
      searchText,
      programCategoryFilters.value,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
  }, [currentPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (programTypeList.length > 0) {
      setTotalCount(programTypeList[0].totalCount.value);
    }
  }, [programTypeList]);

  const resetState = () => {
    setSelectedProgramCategory(null);
    setProgramTypeInputValue("");
    setProgramTypeValue([]);
    setProgramTypensTypeText("");
    setSelectedProgramType(null);
    setProgramType([]);
    setDisableprogramType(true);
    setErrorMessage({
      programCategoryError: false,
      programTypeError: false,
    });
    setSelectedProgramTypeObj(null);
  };

  const column = [
    { heading: "SR.NO", value: "checkbox", width: "w-11" },
    { heading: "PROGRAM TYPE", value: "type", width: "w-40" },
    {
      heading: "PROGRAM CATEGORIES",
      value: "programCategory",
      width: "w-40",
    },
    { heading: "STATUS", value: "isActive", width: "w-20", noSorting: true },
    { heading: "ACTION", value: "action", width: "w-20", noSorting: true },
  ];

  const getProgramTypeList = (
    recordCount,
    rowCount = pageSize,
    searchTextParam,
    programCategoryIDParam,
    sortBy,
    sortDirection
  ) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAM_TYPE_LIST, {
      RecordCount: (recordCount =
        currentPage === 0 ? 0 : currentPage * pageSize),
      RowCount: rowCount,
      SearchText: searchTextParam === "" ? null : searchTextParam,
      ProgramCategoryID:
        programCategoryIDParam === null
          ? null
          : parseInt(programCategoryIDParam),
      sortBy: sortBy === null ? "null" : sortBy,
      sortDirection: sortDirection === null ? "null" : sortDirection,
    })
      .then((res) => {
        let arrayList = [];
        if (res && res.length > 0) {
          res.forEach((item) => {
            arrayList.push({
              isActive: { value: item.isActive },
              isDeleted: { value: item.isDeleted },
              programCategory: { value: item.programCategory },
              programCategoryID: { value: item.programCategoryID },
              programTypeID: { value: item.programTypeID },
              type: { value: item.type },
              isSelected: { value: false },
              userID: { value: item.userID },
              totalCount: { value: item.totalCount },
            });
          });
          setCanNextPage(true);
        } else {
          setCurrentPage(0);
          setCanPreviousPage(false);
          setCanNextPage(false);
          setTotalCount(0);
        }
        setProgramTypeList(arrayList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: userDetails.roleIDList,
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Categories`,
          });
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const getProgramType = () => {
    setProgramTypeInputValue("");
    setProgramTypeValue([]);
    setProgramTypensTypeText("");
    setSelectedProgramType(null);
    setProgramType([]);
    setSelectedProgramType(null);
    if (selectedProgramCategory && selectedProgramCategory.value) {
      setLoading(true);

      const payLoad = {
        programCategoryID: selectedProgramCategory.value,
        category: selectedProgramCategory.label,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMTYPE, payLoad)
        .then((res) => {
          if (res) {
            const programType = res.map((item) => {
              return {
                value: item.programTypeID,
                label: item.type,
              };
            });
            if (programType && programType.length > 0) {
              setProgramType([...programType]);
              setProgramTypes([...programType]);
              if (
                selectedProgramTypeObj &&
                selectedProgramTypeObj.programTypeID.value
              ) {
                const filterData = programType.filter(
                  (item) =>
                    item.value === selectedProgramTypeObj.programTypeID.value
                );
                console.log(programType, selectedProgramTypeObj);
                setSelectedProgramType(filterData[0]);
                setProgramTypensTypeText(filterData[0]);
                setProgramTypeInputValue(filterData[0]?.label);
                setProgramTypeValue(filterData[0]);
                setLoading(false);
              } else {
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);

          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Types`,
          });
          setLoading(false);
        });
    }
  };

  const handleChangeProgramCategory = (value) => {
    errorMessage.programCategoryError = false;
    setSelectedProgramCategory(value);
    setDisableprogramType(false);
  };

  const handleChangeProgramCategoryFilter = (value) => {
    setProgramCategoryFilters(value);
    setProgramTypesFilters([]);
  };

  const handleChangeProgramType = (value) => {
    errorMessage.programTypeError = false;
    setProgramTypensTypeText(value);
    setSelectedProgramType(value);
  };

  const isValidateForm = () => {
    let validForm = true;
    if (selectedProgramCategory == null) {
      errorMessage.programCategoryError = true;
      validForm = false;
    }
    if (selectedProgramType === null) {
      errorMessage.programTypeError = true;
      validForm = false;
    }

    return validForm;
  };
  const handleSubmit = () => {
    if (isValidateForm()) {
      const payLoad = {
        programTypeID:
          selectedProgramTypeObj === null
            ? 0
            : selectedProgramTypeObj.programTypeID.value,
        programCategoryID: selectedProgramCategory.value,
        type: selectedProgramType.label,
        userID: userDetails && userDetails.userID ? userDetails.userID : 0,
        programCategory: selectedProgramCategory.label,
        isActive: selectedProgramTypeObj === null ? true : isActive,
      };
      setLoading(true);
      APIHelper.post(APIURLConstant.CREATE_PROGRAM_TYPE, payLoad)
        .then((res) => {
          const message =
            selectedProgramTypeObj === null
              ? "Program type created successfully"
              : "Program type updated successfully";
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: message,
          });
          setLoading(false);
          resetState();
          getProgramTypeList(
            0,
            pageSize,
            searchText,
            programCategoryFilters.value,
            sortStatusText,
            sortStatus === "ascending" ? "ASC" : "DESC"
          );
          setSelectedProgramTypeObj(null);
          setIsAddFormVisible(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error while creating a program type",
          });
          setLoading(false);
        });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter the required fields",
      });
    }
  };

  const onSelectTableRow = (e, object) => {
    e.stopPropagation();
    const SelectedData = programTypeList?.map((ele) => {
      if (ele.programTypeID === object.programTypeID) {
        return { ...ele, ...{ isSelected: { value: !ele?.isSelected.value } } };
      } else {
        return ele;
      }
    });
    setProgramTypeList(SelectedData);
  };
  const onClickSelectAll = (e, isAllSelected) => {
    e.stopPropagation();
    const rowData = programTypeList?.map((ele) => {
      if (isAllSelected) {
        return { ...ele, ...{ isSelected: { value: false } } };
      } else {
        return { ...ele, ...{ isSelected: { value: true } } };
      }
    });
    setProgramTypeList(rowData);
  };
  const handleClickEdit = (obj) => {
    resetState();
    setShowModal(true);
    setModalMessage({
      title: "Edit",
      message: "Do you want to Edit this Program type ?",
    });
    setSelectedProgramTypeObj(obj);
  };
  const handleDeleteprogramType = (obj) => {
    setShowModal(true);
    setModalMessage({
      title: "Delete",
      message: "Do you want to Delete this Program type ?",
    });
    setSelectedProgramTypeObj(obj);
  };
  const handleDeleteMulti = () => {
    let deleteRequests = [];
    // eslint-disable-next-line array-callback-return
    programTypeList.map((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.programTypeID.value);
      }
    });
    let messageString = "";
    if (deleteRequests.length !== programTypeList.length) {
      messageString = "Do you want to Delete selected Program types ?";
    } else {
      messageString = "Do you want to Delete All Program types ?";
    }
    if (deleteRequests.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Multiple Delete",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one Program types",
      });
    }
  };

  const handleEditConfirm = () => {
    setShowModal(false);
    setIsAddFormVisible(true);
    const programCatValue = programCategory.filter(
      (item) => item.value === selectedProgramTypeObj.programCategoryID.value
    );
    setIsActive(selectedProgramTypeObj.isActive.value);

    setSelectedProgramCategory(programCatValue[0]);
    setDisableprogramType(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleMultipleDeleteConfirm = () => {
    setShowModal(false);
    let deleteRequests = [];
    // eslint-disable-next-line array-callback-return
    programTypeList.map((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.programTypeID.value);
      }
    });
    if (deleteRequests.length > 0) {
      setLoading(true);
      const payload = {
        ProgramIds: deleteRequests.join(","),
        IsDeleted: true,
        ModifiedBy: userDetails.userID ? userDetails.userID : 0,
      };
      APIHelper.post(APIURLConstant.DELETT_PROGRAM_TYPE_LIST, payload)
        .then((res) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: "Program type Deleted successfully",
          });
          setLoading(false);
          getProgramTypeList(
            currentPage * 10,
            pageSize,
            searchText,
            programCategoryFilters.value,
            sortStatusText,
            sortStatus === "ascending" ? "ASC" : "DESC"
          );
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error While Deleting a Program type!",
          });
          setLoading(false);
        });
    }
  };
  const handleDeletConfirm = () => {
    setShowModal(false);
    setLoading(true);
    const payload = {
      ProgramIds: JSON.stringify(selectedProgramTypeObj.programTypeID.value),
      IsDeleted: true,
      ModifiedBy: userDetails.userID ? userDetails.userID : 0,
    };
    APIHelper.post(APIURLConstant.DELETT_PROGRAM_TYPE_LIST, payload)
      .then((res) => {
        setToasterMessage({
          showToaster: true,
          err: "success",
          message: "Program type deleted successfully",
        });
        setSelectedProgramTypeObj(null);
        setLoading(false);
        getProgramTypeList(
          currentPage * 10,
          pageSize,
          searchText,
          programCategoryFilters.value,
          sortStatusText,
          sortStatus === "ascending" ? "ASC" : "DESC"
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          err: "error",
          message: "Error while deleting a program type",
        });
      });
  };
  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleSort = (name) => {
    if (sortStatus === "normal" || sortStatusText !== name) {
      setSortStatus("ascending");
      setSortStatusText(name);
    } else if (sortStatus === "ascending") {
      setSortStatus("descending");
      setSortStatusText(name);
    } else if (sortStatus === "descending") {
      setSortStatus("ascending");
      setSortStatusText(name);
    }
  };
  useEffect(() => {
    if (sortStatus && sortStatusText) {
      setSortConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus, sortStatusText]);

  const setSortConfig = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    getProgramTypeList(
      0,
      pageSize,
      searchText,
      programCategoryFilters.value,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    // let sortableItems = [...programTypeList];

    // if (sortStatus === "ascending" || sortStatus === "descending") {
    //   if (sortStatusText === "PROGRAM TYPE") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.type.value.toLowerCase() < b.type.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.type.value.toLowerCase() > b.type.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramTypeList(dataSorted);
    //   }

    //   if (sortStatusText === "PROGRAM CATEGORIES") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.programCategory.value.toLowerCase() <
    //         b.programCategory.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.programCategory.value.toLowerCase() >
    //         b.programCategory.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramTypeList(dataSorted);
    //   }
    //   if (sortStatusText === "STATUS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.isActive.value.toLowerCase() < b.isActive.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.isActive.value.toLowerCase() > b.isActive.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setProgramTypeList(dataSorted);
    //   }
    // }
  };

  const handleSearchText = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    //need to call APi to get Program Type List
    getProgramTypeList(
      currentPage * 10,
      pageSize,
      searchText,
      programCategoryFilters.value,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
  };

  const handleSearch = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    getProgramTypeList(
      currentPage * 10,
      pageSize,
      searchText,
      programCategoryFilters.value,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );

    //need to call APi to get Program Type List
  };

  const handleChangeProgramTypes = (value) => {
    setProgramTypesFilters(value);
  };

  // filter reset
  const resetFilter = () => {
    setSearchText("");
    setProgramCategoryFilters("");
    setProgramTypesFilters([]);
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    //need to call APi to get Program Type List
    getProgramTypeList(
      currentPage * 10,
      pageSize,
      null,
      null,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
  };

  return (
    <>
      <Loader showLoading={loading} />

      <div className="mx-auto mt-9 md:p-7 p-2">
        {isAddFormVisible ? (
          <>
            <p className="text-[#c8102e] font-semibold text-[19px] py-3">
              {selectedProgramTypeObj === null
                ? "Create Program Type"
                : "Edit Program Type"}
            </p>
            <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
              <div className="flex-none md:flex lg:space-x-4 py-4">
                <p className="text-sm pb-4 lg:pt-2 lg:p-0 md:p-0 lg:text-end text-center required text-[#394150] md:w-3/12 lg:w-2/12  w-full font-bold">
                  Program Category:
                </p>
                <div className="w-full md:w-6/12 lg:w-4/12">
                  <Select
                    value={selectedProgramCategory}
                    options={programCategory}
                    className={`w-full focus:outline-none focus:shadow-outline ${
                      errorMessage.programCategoryError === true
                        ? "border border-red-400"
                        : ""
                    } text-gray-900 rounded`}
                    placeholder="Select Program Category"
                    onChange={(e) => handleChangeProgramCategory(e)}
                  />
                  {errorMessage.programCategoryError && (
                    <span className="text-red-500 text-sm ml-2">
                      Please select the program category
                    </span>
                  )}
                </div>
              </div>
              <div className="flex-none md:flex lg:space-x-4 py-4">
                <p className="text-sm pb-4 lg:pt-2 lg:p-0 md:p-0 lg:text-end text-center required text-[#394150] md:w-3/12 lg:w-2/12  w-full font-bold">
                  Program Type:
                </p>
                <div className="w-full md:w-6/12 lg:w-4/12">
                  <div className="">
                    <AutoComplete
                      tagSuggestion={programType}
                      isCloseOutsideClick={true}
                      createOption={createOption}
                      inputValue={programTypeInputValue}
                      disableOptions={true}
                      isMulti={false}
                      className={`${
                        errorMessage.programTypeError === true
                          ? "border border-red-400"
                          : ""
                      } rounded`}
                      disable={disableProgramType}
                      setInputValue={setProgramTypeInputValue}
                      value={programTypeValue}
                      setValue={setProgramTypeValue}
                      onChange={handleChangeProgramType}
                      placeholder={"Select Program Type"}
                      styles={colourStyles}
                    />
                    {errorMessage.programTypeError && (
                      <span className="text-red-500 text-sm ">
                        Please enter the program type
                      </span>
                    )}
                  </div>
                </div>
                {selectedProgramTypeObj !== null && (
                  <div className="inline-flex items-center">
                    <span className=" text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 mx-2 font-bold">
                      {isActive ? "Active" : "InActive"}
                    </span>
                    <label className="inline-flex relative items-center  w-11">
                      <input
                        type="checkbox"
                        checked={isActive}
                        value={isActive}
                        className="sr-only peer cursor-pointer"
                        onChange={(e) => setIsActive(e.target.checked)}
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                )}
              </div>
              <div className="flex-none md:flex lg:space-x-4 py-4">
                <div className="md:w-3/12 lg:w-2/12 w-full" />
                <ButtonContainer
                  handleSubmit={() => handleSubmit()}
                  handleCancel={() => {
                    resetState();
                    setIsAddFormVisible(false);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="">
            <div className="flex-none justify-between md:flex py-3">
              <p className="text-[#c8102e] font-semibold text-[19px]">
                Program Type List
              </p>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4">
              <div className="w-full ">
                <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="w-full focus:outline-none h-10 rounded-lg border border-grey-300 px-1 mt-2 md:mt-0"
                  placeholder="Search by program type..."
                />
              </div>
              <div className="w-full ">
                <Select
                  value={programCategoryFilters}
                  options={programCategory}
                  className="mt-2 md:mt-0 w-full  focus:outline-none focus:shadow-outline text-gray-900 rounded"
                  placeholder="Select Program Category"
                  onChange={(e) => handleChangeProgramCategoryFilter(e)}
                />
              </div>

              <div className="w-full flex gap-4 flex-wrap md:justify-between">
                <div className="flex  flex-wrap gap-2">
                  <button
                    disabled={
                      programCategoryFilters !== "" ||
                      searchText !== "" ||
                      programTypesFilters.length > 0
                        ? false
                        : true
                    }
                    className={
                      programCategoryFilters !== "" ||
                      searchText !== "" ||
                      programTypesFilters.length > 0
                        ? "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                        : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                    }
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    Filter
                  </button>
                  <button
                    className={
                      programCategoryFilters !== "" ||
                      searchText !== "" ||
                      programTypesFilters.length > 0
                        ? "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                        : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                    }
                    onClick={() => {
                      resetFilter();
                    }}
                    data-te-toggle="tooltip"
                    title="Reset filter"
                  >
                    Reset
                  </button>
                </div>
                <div className="flex flex-wrap gap-2">
                  <button
                    className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
                    onClick={() => {
                      setIsAddFormVisible(true);
                      setSelectedProgramTypeObj(null);
                      getProgramCategory();
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] rounded-md text-white text-sm"
                    onClick={() => handleDeleteMulti()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>

            <Table
              data={programTypeList}
              column={column}
              isDeleteButton={true}
              handleClickEdit={handleClickEdit}
              handleDeleteUser={handleDeleteprogramType}
              //   handleOpenTagList={handleOpenTagList}
              onClickSelectAll={onClickSelectAll}
              onSelectTableRow={onSelectTableRow}
              sortStatusText={sortStatusText}
              sortStatus={sortStatus}
              handleSort={handleSort}
              sourceName={"ProgramType"}
            />
            <Pagination
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              totalCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              setPageSize={setPageSize}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              setCanNextPage={setCanNextPage}
              setCanPreviousPage={setCanPreviousPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
      {/* Modal Popup */}
      <Modal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Edit"
            ? handleEditConfirm
            : modalMessage.title === "Multiple Delete"
            ? handleMultipleDeleteConfirm
            : handleDeletConfirm
        }
      />

      {/* Toaster */}

      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </>
  );
};
export default ProgramType;
