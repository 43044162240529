import React, { useState, useEffect, useContext } from "react";
import Loader from "../../sharedComponent/Loader/Loader";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { AppContext } from "../../store/context";
// import { AutoComplete, Modal, Table } from "../../sharedComponent";
import MethodAndVariable from "../../utils/config/variableConstant";
import Helper from "../../utils/config/helper";
import UnauthorizedUI from "../../sharedComponent/AccessComponents/unauthorized";

const ChangeUser = () => {
  const {
    userDetails,
    dispatchUserEvent,
    dispatchRegions,
    dispatchCategories,
  } = useContext(AppContext);
  const isAccessible = userDetails && userDetails.roleIDList === "1" ? 1 : -1;

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [originalUserList, setOriginalUserList] = useState([]);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });

  useEffect(() => {
    if (isAccessible === 1) {
      getUserList();
    }
  }, [isAccessible]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_USERS_LIST, {
      searchText: searchText,
      recordCount: 0,
      rowCount: 10000,
      status: true,
      roleId: 0,
      regionIds: "null",
    })
      .then((data) => {
        data = data.filter(
          (item) => item.email.toLowerCase() !== userDetails.email.toLowerCase()
        );
        setUserList(data);
        setOriginalUserList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSearchUserList = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      const newUserList = originalUserList.filter(
        (item) =>
          item.firstName.toLowerCase().includes(value.toLowerCase()) ||
          item.lastName.toLowerCase().includes(value.toLowerCase())
      );
      // console.log(newUserList, 'newUserList');
      setUserList(newUserList);
      // getUserList(searchText, 0, true, 10000);
    } else {
      setUserList(originalUserList);
    }
  };

  const handleUserChange = (e, userEmail) => {
    e.preventDefault();
    Helper.setStorage("impersonateEmail", userEmail);
    const user = { impersonateEmail: userEmail };

    dispatchRegions("ADD_REGIONS", []);
    dispatchCategories("ADD_CATEGORY", []);
    dispatchUserEvent("ADD_USER", user);
    window.location.replace(MethodAndVariable.getENVDetails().RETURN_URL);
    // getUserRoleandPermission(userEmail)
  };

  // const getUserRoleandPermission = (userEmail) => {
  //     // user.email = 'spathak3@nba.com'
  //     setLoading(true);
  //     APIHelper.get(APIURLConstant.GET_ALL_ROLES_AND_PERMISSIONS_BY_EMAIL(userEmail)).then((data) => {
  //         let user = {};

  //         if (data && data.length > 0) {
  //             user.permissionNameList = data[0].permissionNameList;
  //             user.roleNameList = data[0].roleNameList;
  //             user.permissionIDList = data[0].permissionIDList;
  //             user.roleIDList = data[0].roleIDList;
  //             user.regionID = data[0].regionID;
  //             user.userID = data[0].userID;
  //             user.firstName = data[0].firstName;
  //             user.lastName = data[0].lastName;
  //             user.isImpersonate = true;
  //             user.impersonateEmail = userEmail;
  //             dispatchUserEvent("ADD_USER", user);
  //             setLoading(false);
  //         } else {
  //             console.log(
  //                 `Logged in user doesn't have any role / permission added`
  //             );
  //             // setToasterMessage({
  //             //   showToaster: true,
  //             //   errorType: "error",
  //             //   message: "Logged in user doesn't have any role/permission added",
  //             // });
  //             user.isImpersonate = false;
  //             dispatchUserEvent("ADD_USER", user);
  //             setLoading(false);
  //         }
  //     })
  //         .catch((err) => {
  //             console.log(err);
  //             setLoading(false);
  //         });
  // };

  return (
    <div>
      {isAccessible === 1 ? (
        <div>
          {/* Loader */}
          <Loader showLoading={loading} />
          <div className="mx-auto mt-9 md:p-7 p-2">
            <div>
              <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                Users List
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pb-4">
                <div className="flex gap-2">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full h-10 focus:outline-none focus:shadow-outline border px-2 border-grey-300 rounded"
                    onChange={(e) => handleSearchUserList(e.target.value)}
                    value={searchText}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && searchText?.length > 0) {
                        handleSearchUserList(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="justify-between items-center flex gap-4">
                  <div className="flex gap-3">
                    <button
                      disabled={!searchText}
                      className={
                        searchText
                          ? "bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                          : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      }
                      onClick={() => {
                        handleSearchUserList(searchText);
                      }}
                    >
                      Search
                    </button>
                    {/* {searchText.length > 0 && (
                                    <button onClick={() => resetFilter()}>
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-6 h-6 text-blue-900 mt-1"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                )} */}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {userList?.map((items, index) => {
                  return (
                    <button
                      className="border h-full text-center rounded-md shadow-lg px-2 py-1 flex items-center gap-2"
                      key={index + items.email}
                      onClick={(e) => handleUserChange(e, items.email)}
                    >
                      <p className="bg-[#1D428A] text-[0.9rem] w-8 h-8 flex items-center justify-center rounded-full text-white cursor-pointer">
                        {MethodAndVariable.getInitials(
                          items.firstName,
                          items.lastName,
                          ""
                        )}
                      </p>
                      <p className="text-[#323232] flex items-center justify-center  cursor-pointer">
                        {items.firstName} {items.lastName}
                      </p>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <Modal
                showModal={showModal}
                handleClose={() => setShowModal(false)}
                modalMessage={modalMessage}
                handleClick={
                    modalMessage.title === "Edit" ? handleEditConfirm : handleDeletConfirm
                }
            /> */}
          <Toaster
            toasterMessage={toasterMessage}
            setToasterMessage={setToasterMessage}
          />
        </div>
      ) : (
        <UnauthorizedUI />
      )}
    </div>
  );
};

export default ChangeUser;
