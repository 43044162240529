import React from "react";
import { useNavigate } from "react-router-dom";
import LockClose from "../../assets/lockclose.svg";
import Home from "../../assets/home.svg";
import "./unauthorized.css";
import MethodAndVariable from "../../utils/config/variableConstant";

const UnauthorizedUI = (props) => {
  const navigate = useNavigate();
  const gotoHome = () => {
    navigate("/");
  };

  let logoutURL = `https://login.microsoftonline.com/e898ff4a-4b69-45ee-a3ae-1cd6f239feb2/oauth2/logout?post_logout_redirect_uri=`;
  logoutURL = logoutURL + MethodAndVariable.getENVDetails().RETURN_URL;

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear()
    props.setIsLoggedIn(false)
    props.setShowLogin(true)
    props.setIsAuthorized(true)
    localStorage.removeItem("accessToken");
  };

  return (
    <div className="flex justify-center items-center w-full h-screen ">
      <div className=" grid place-items-center text-center">
        <img src={LockClose} className="" alt="lock" />
        <p className="font-bold text-lg	">Access denied</p>
        {props.isHmoeIconDisabled === undefined ?
        <>
        <p className="text-gray-600 ">You do not have access to this section</p>
        
        <img
          src={Home}
          className="h-10 w-10 mt-2"
          alt="Go Home"
          onClick={() => gotoHome()}
        /></>:
        <>
        <p className="text-gray-600 ">You do not have access to website.</p>
        <p className="text-gray-600 ">Please contact Administrator</p>
        <div>
        <a
                        className="block w-full whitespace-nowrap bg-transparent py-4 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                        onClick={(e) => {
                          handleLogout(e);
                        }}
                        href={logoutURL}
                      >
                        <h4 className="font-medium text-gray-600 dark:text-gray-300">
                          Logout
                        </h4>
                      </a>
        </div>
        </>
        }
      </div>
    </div>
  );
};

export default UnauthorizedUI;
