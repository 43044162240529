const APIURLConstant = {
  GET_QUESTIONS_LIST: `api/GetQuestionList`,
  SUBMIT_QUESTION: `api/PostQuestion`,
  GET_TAGS_BY_TYPE: (tagTypeId) => `api/GetTagsByType/${tagTypeId}`,
  GET_TAGS_BY_TYPE_NAME: `api/GetTagsByTypeName`,
  POST_ADD_TAG: `api/InsertTagDetails`,
  GET_ANSWERTYPE: () => `api/GetAnswerTypes`,
  // GET_PROGRAMCATEGORY: () => `api/Program/GetProgramCategories`,
  GET_PROGRAMCATEGORY_BY_USER_ROLE: `api/Program/GetProgramCategoriesByUserRole`,
  GET_PROGRAMTYPE: `api/Program/GetProgramTypesByCategory`,
  // GET_PROGRAMTYPES: () => `api/Program/GetProgramTypes`,
  GET_PROGRAMTYPESALL: `api/Program/GetProgramTypesAll`,
  GET_PROGRAMDURATIONS: () => `api/Program/GetProgramDurations`,
  GET_PROGRAMREGIONS: `api/Program/GetProgramRegions`,
  GET_PROGRAMCOUNTRIES: () => `api/Program/GetProgramCountries`,
  GET_PROGRAMCOUNTRIES_BY_REGION: (regionId) =>
    `api/Program/GetProgramCountriesByRegion/${regionId}`,
  GET_PROGRAMSTATES_BY_COUNTRY: `/api/Program/GetProgramStatesByCountry`,
  GET_PROGRAMCOUNTRIES_BY_REGION_IDS: `api/Program/GetProgramCountriesByRegionIds`,
  CREATE_PROGRAM: `api/Program/CreateProgram`,
  // GET_PROGRAMTYPECONFIGURATIONS: (programTypeId, programId) =>
  //   `api/Program/GetProgramTypeConfigByTypeId/${programTypeId}/${programId}`,
  // CREATE_ANSWERS_FOR_EXTRACONFIGS: `/api/CreateAnswers`,
  DELETE_QUESTION_LIST: `/api/DeleteQuestionsByIds`,
  GET_COMPONENTS: `/api/GetTagsByTypeName`,
  GET_QUESTIONS_BY_TAG_IDS: `/api/GetQuestionsByTagIds`,
  CREATE_COMPONENT: `api/Component/CreateComponent`,
  CREATE_COMPONENT_WITH_TAGS: `api/Component/CreateComponentWithTags`,
  CREATE_COMPONENT_WITH_QUESTIONS: `api/Component/CreateComponentWithQuestions`,
  GET_QUESTIONS_FOR_SELECTED_COMPONENT: `/api/Component/GetQuestionListForSelectedComponent`,
  GET_COMPONENTSELECTED_LIST: `/api/Component/GetComponentSelectorList`,
  POST_CREATE_PROGRAM_COMPONENT_TAGS: (programId) =>
    `/api/Program/CreateProgramComponentTags/${programId}`,
  POST_CREATE_PROGRAM_QUESTIONS: (programId) =>
    `/api/Program/CreateProgramQuestions/${programId}`,
  GET_PROGRAM_LIST: `/api/Program/GetProgramList`,
  GET_COMPONENT_LIST: `/api/Component/GetComponentList`,
  GET_COMPONENT_LIST_BY_PROGRAM_CATEGORY_AND_TYPE: `/api/Component/GetComponentListByCategoryAndType`,
  DELETE_PROGRAM_LIST: `/api/Program/DeleteProgramsByIds`,
  DELETE_Component_LIST: `/api/Component/DeleteMultipleComponent`,
  CHANGE_COMPONENT_STATUS: `/api/Component/ChangeStatusMultipleComponent`,
  CHANGE_PROGRAM_STATUS: `/api/Program/UpdateProgramStatus`,
  GET_PROGRAM_MODULES: `/api/ProgramModule/GetProgramModules`,
  SAVE_MODULE_QUESTION: "api/ProgramModule/PostModuleQuestion",
  GET_MODULE_QUESTIONS_LIST: `/api/ProgramModule/GetQuestionListByModuleID`,
  POST_MODULE_QUESTION_JSON: `/api/ModuleQuestions/PostModuleQuestionsJSON`,
  GET_MODULE_QUESTION_JSON: `/api/ModuleQuestions/GetModuleQuestionsJSON`,
  GET_USERS_LIST: `/api/User/GetUsers`,
  GET_ALL_USERS_LIST: `/api/User/GetAllUsers`,
  GET_USER_ROLES: `/api/User/GetRoles`,
  POST_CREATE_USER: "/api/User/PostUser",
  POST_CREATE_USER_WITH_ROLE: "/api/User/PostUserWithRoles",
  GET_MODULE_ANSWER_JSON: (moduleID) =>
    `/api/ModuleAnswers/GetModuleAnswersJSON?moduleID=${moduleID}`,
  POST_MODULE_ANSWER_JSON: `/api/ModuleAnswers/PostModuleAnswersJSON`,
  //GET_ALL_ROLES_AND_PERMISSIONS: `/api/User/GetAllRolesAndPermissions`,
  GET_ALL_ROLES_AND_PERMISSIONS_BY_EMAIL: `/api/User/GetRolesAndPermissionsByEmail`,
  POST_USER_WITH_PERMISSIONS: `/api/User/PostUserWithPermissions`,
  GET_USER_EMAIL: `/api/User/GetUserEmail`,
  GET_USER_DETAILS: `/api/User/GetUserDetails`,
  UPDATE_USER_LAST_LOGIN: `/api/User/UpdateUsersLastLogin`,
  GET_PROGRAM_LIST_FOR_CALENDER: `/api/Program/GetProgramListForCalendar`,
  // GET_PROGRAM_TYPES: `/api/Program/GetProgramTypes`,
  CREATE_PROGRAM_TYPE: `/api/Program/CreateProgramType`,
  GET_PROGRAM_TYPE_LIST: `/api/Program/GetProgramTypes`,
  DELETT_PROGRAM_TYPE_LIST: `/api/Program/DeleteProgramTypeByIds`,
  GET_QUESTIONS_BY_PROGRAMCATEGORYANDTYPE: `/api/GetQuestionsByProgramCategoryAndType`,
  GET_PROGRAM_ANSWER_JSON: `/api/GetProgramAnswerJSON`,
  GET_COMPONENT_ANSWER_JSON: `/api/GetComponentAnswerJSON`,
  CREATE_ANSWERS_JSON_FOR_PROGRAM_CATEGORY_TYPE: `/api/CreateAnswersJSON`,
  GET_MODULE_ANSWERJS_BY_PROGRAM_AND_MODULE: `/api/ModuleAnswers/GetModuleAnswerJSByProgramAndModule`,
  UPDATE_PROGRAM_MODULE_STATUS: `/api/Program/UpdateProgramModuleStatus`,
  POST_EDIT_PROGRAM_COMMENT: `/api/Program/PostEditProgramComment`,
  GET_PROGRAM_COMMENT_LIST: `/api/Program/GetProgramComment`,
  CREATE_DOCUMENT: `/api/CreateDocument`,
  GET_DOCUMENT_SECTION_BY_ID: `/api/GetDocumentSectionById`,
  GET_RESOURCE_LIST: (id) => `api/GetDocumentsBySectionId/${id}`,
  GET_RESOURCE_CATEGORY_BY_USER_ROLE_ID: (userRoleId) =>
    `api/GetResourceCategoryByUserRoleId/${userRoleId}`,
  CREATE_RESOURCE_CATEGORY: "/api/CreateResourceCategory",
  UPDATE_RESOURCE_CATEGORY_ORDER: "/api/UpdateResourceCategorySortOrders",
  GET_ALL_RESOURCES_CATEGORIES: (recordCount, rowCount) =>
    `/api/GetAllResourceCategories?recordCount=${recordCount}&rowCount=${rowCount}`,
  DELETE_RESOURCE_CATEGORY: (sectionIDs, isDeleted, modifiedBy) =>
    `/api/DeleteResourceCategory/${sectionIDs}/${isDeleted}/${modifiedBy}`,
  ADD_UPDATE_NOTIFICATIONS: "/api/Notifications/AddUpdateNotifications",
  GET_NOTIFICATIONS_BY_USERID: `/api/Notifications/GetNotificationsByUserId`,
  GET_PARTICIPANTS_COUNT: `/api/ModuleAnswers/GetParticipantsCount`,
  GET_AGENCY_LIST: (userEmail) =>
    `/api/Program/GetAgencyList?userEmail=${userEmail}`,
  INSERT_AGENCY: `/api/Program/InsertAgency`,
  GET_SPONCER_LIST: (userEmail) =>
    `/api/Program/GetSponsorList?userEmail=${userEmail}`,
  INSERT_SPONCER: `/api/Program/InsertSponsor`,
  GET_PROGRAM_BY_CATEGORY_TYPE: `/api/Program/GetProgramsByCategoryAndType`,
  CREATE_RECAP_REPORT: `/api/CreateRecapReport`,
  CREATE_RECAP_REPORT_IMAGES: `/api/CreateRecapReportImages`,
  CREATE_RECAP_REPORT_SOCIALMEDIALINKS: `/api/CreateRecapReportSocialMediaLinksTest`, //`/api/CreateRecapReportSocialMediaLinks`,
  GET_RECAP_REPORT_BY_PROGRAMID: `/api/GetRecapReportByProgramId`,
  GET_RECAP_REPORT_IMAGES_BY_REPORTID: `/api/GetRecapReportImagesByReportId`,
  GET_RECAP_REPORT_SOCIALMEDIA_IMAGES_BY_REPORTID: `/api/GetRecapReportSocialMediaLinksByReportId`,
  SIGN_OUT: `/api/SignOut`,
  POST_MODULE_FEEDBACK: `/api/CreateModuleFeedback`,
  GET_MODULE_FEEDBACK: (programID, userID, moduleID) =>
    `/api/GetModuleFeedbacks?programId=${programID}&userId=${userID}&moduleId=${moduleID}`,
  GET_REACH_PARTICIAPNTS_FORMULA: `/api/ReachParticipantsFormula/GetReachParticipantsFormulas`,
  UPDATE_REACH_PARTICIPANTS_FORMULA: `/api/ReachParticipantsFormula/InsUpdateReachParticipantFormula`,
  GET_PROGRAM_AUDIT_HISTORY: (programID) =>
    `/api/Program/GetProgramHistoryById/${programID}`,
  CREATE_SUBCATEGORY: `/api/CreateDocumentSubSection`,
  GET_SUBCATEGORIES_BY_CATEGORY_ID: `/api/GetDocumentSubSectionsBySectionId`,
  GET_SUBCATEGORIES_BY_SUBSECTION_ID: `/api/GetDocumentSubSectionsBySubSectionId`,
  GET_ALL_SECTIONS_AND_SUBSECTIONS: `/api/GetDocumentSectionsAndSubSections`,
  CREATE_CUSTOME_NOTIFICATION: `/api/Notifications/AddUpdateCustomNotifications`,
  GET_CUSTOME_NOTIFICATION: `/api/Notifications/GetCustomNotifications`,
  DELETE_CUSTOME_NOTIFICATION: `/api/Notifications/DeleteCustomNotifications`,
  SENT_CUSTOM_NOTIFICATION: `/api/Notifications/SendCustomNotifications`,
  GET_RESOURCE_BY_CATEGORY: (
    sectionID,
    subSectionId,
    childSubSectionId,
    isAllDocsRequired
  ) =>
    `/api/GetDocumentsBySectionAndSubSectionId?isAllDocsRequired=${isAllDocsRequired}&sectionId=${sectionID}&subSectionId=${subSectionId}&childSubSectionId=${childSubSectionId}`,
  GET_PROGRAME_COUNT_BY_CATEGORY: `/api/Program/GetProgramsCountByCategory`,
  GET_PARTICIPANTS_ACTUAL_AND_REACH_COUNT: `/api/Program/GetParticipantsActualAndReachedCount`,
  GET_REVIEWERS_LIST: `/api/Program/GetProgramReviewerList`,
  UPDATE_REVIEWER: `/api/Program/UpdateProgramReviewer`,
  GET_PROGRAM_DETAILS_BY_ID: (programID) =>
    `/api/Program/GetProgramById/${programID}`,
  GET_PROGRAM_LIST_BY_START_DATE: `/api/Program/GetProgramsByStartDate`,
  UPDATE_USER_EXPIRATION_DATE: `/api/User/UpdateUserExpirationDate`,
};

export default APIURLConstant;
