import React, { useEffect, useState } from "react";

export default function Pagination(props) {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  const gotoPage = (pageNumber) => {
    if (Math.ceil(props.totalCount / props.pageSize) - 1 === 0) {
      return false;
    }
    props.setCurrentPage(pageNumber);
    if (pageNumber === Math.ceil(props.totalCount / props.pageSize) - 1) {
      props.setCanNextPage(false);
      props.setCanPreviousPage(true);
    } else if (pageNumber === 0) {
      props.setCanNextPage(true);
      props.setCanPreviousPage(false);
    } else {
      props.setCanNextPage(true);
      props.setCanPreviousPage(true);
    }
    props.gotoPage(pageNumber);
  };

  const previousPage = () => {
    if (Math.ceil(props.totalCount / props.pageSize) - 1 === 0) {
      return false;
    }
    if (props.currentPage - 1 <= 0) {
      props.setCanPreviousPage(false);
      props.setCanNextPage(true);
    } else {
      props.setCurrentPage(props.currentPage - 1);
      props.setCanPreviousPage(true);
      props.setCanNextPage(true);
    }
    props.previousPage();
  };

  const nextPage = () => {
    if (Math.ceil(props.totalCount / props.pageSize) - 1 === 0) {
      return false;
    }
    if (
      props.currentPage + 1 ===
      Math.ceil(props.totalCount / props.pageSize) - 1
    ) {
      props.setCanNextPage(false);
      props.setCanPreviousPage(true);
    } else if (props.currentPage - 1 !== 0) {
      props.setCurrentPage(props.currentPage + 1);
      props.setCanNextPage(true);
      props.setCanPreviousPage(true);
    }
    props.nextPage();
  };

  const setButtonsEnabled = () => {
    return props.currentPage + 1 >= 1;
  };

  const setPageSize = (e) => {
    props.setCurrentPage(0);
    props.setCanPreviousPage(false);
    props.setCanNextPage(true);
    props.setPageSize(Number(e.target.value));
  };

  const ArraowDouble = ({ rotate }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        className={`w-5 h-5 ${rotate}`}
      >
        <path
          fillRule="evenodd"
          d="M4.72 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 010-1.06zm6 0a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 010-1.06z"
          clipRule="evenodd"
        />
      </svg>
    );
  };
  const ArraowSingle = ({ rotate }) => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`w-5 h-5 ${rotate}`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    );
  };

  return (
    <div className="my-3 flex justify-between flex-wrap">
      <div className="flex gap-2 ">
        <p className="mt-2 font-bold">Rows per page:</p>
        <select
          value={props.pageSize}
          className="border h-10 rounded w-16"
          onChange={(e) => setPageSize(e)}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        <button
          className={`w-[45px] h-[45px] rounded-[25px] flex items-center justify-center border  ${
            props.canPreviousPage ? "bg-white" : "bg-[#ccc]"
          }
          }  rounded-md `}
          onClick={() => gotoPage(0)}
          disabled={!props.canPreviousPage || !setButtonsEnabled()}
        >
          <ArraowDouble
            rotate={`rotate-180 ${
              props.canPreviousPage ? "text-blue-900" : "text-[#333]"
            }`}
          />
        </button>
        <button
          className={`w-[45px] h-[45px] rounded-[25px] flex items-center justify-center border ${
            props.canPreviousPage ? "bg-white" : "bg-[#ccc]"
          }
          }  rounded-md `}
          onClick={() => previousPage()}
          disabled={!props.canPreviousPage || !setButtonsEnabled()}
        >
          <ArraowSingle
            rotate={`rotate-180 ${
              props.canPreviousPage ? "text-[#1D428A]" : "text-[#333]"
            }`}
          />
        </button>
        <button
          className={`w-[45px] h-[45px] rounded-[25px] flex items-center justify-center border ${
            props.canNextPage ? "bg-white" : "bg-[#ccc]"
          }
          }  rounded-md `}
          onClick={() => nextPage()}
          disabled={!props.canNextPage || !setButtonsEnabled()}
        >
          <ArraowSingle
            rotate={`rotate-0 ${
              props.canNextPage ? "text-[#1D428A]" : "text-[#333]"
            }`}
          />
        </button>
        <button
          className={`w-[45px] h-[45px] rounded-[25px] flex items-center justify-center border ${
            props.canNextPage ? "bg-white" : "bg-[#ccc]"
          }
        }  rounded-md `}
          onClick={() =>
            gotoPage(Math.ceil(props.totalCount / props.pageSize) - 1)
          }
          disabled={!props.canNextPage || !setButtonsEnabled()}
        >
          <ArraowDouble
            rotate={`rotate-0 ${
              props.canNextPage ? "text-[#1D428A]" : "text-[#333]"
            }`}
          />
        </button>
        <p>
          Page
          {(props.totalCount > 0 ? <span className="font-bold">
            {`${props.currentPage + 1} of ${Math.ceil(
              props.totalCount / props.pageSize
            )}`}
          </span> : <span className="font-bold">
            {`1 of 1`}
          </span>)}
        </p>
        <span>
          | Go to page:
          <input
            type="number"
            className="focus:outline-none focus:shadow-outline border h-10 pl-4 ml-2 border-grey-300 rounded"
            // defaultValue={currentPage + 1}
            value={currentPage + 1}
            onChange={(e) => {
              let pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
              if (pageNumber <= 0) {
                pageNumber = 0;
              }
              if (pageNumber >= Math.ceil(props.totalCount / props.pageSize)) {
                pageNumber = Math.ceil(props.totalCount / props.pageSize) - 1;
              }
              gotoPage(pageNumber);
            }}
            style={{ width: "50px" }}
          />
        </span>{" "}
      </div>
    </div>
  );
}
