import React from "react";
import CloseIcon from "../../assets/close-blue.svg";

const ViewNotification = (props) => {
  const { notification, setSelectedNotificationForView } = props;
  return (
    <div class="fixed flex justify-center inset-0 z-[10000] items-center  overflow-y-auto overflow-x-hidden outline-none">
      <div class="pointer-events-none flex items-center relative h-4/6 w-full md:w-3/6 translate-y-[-50px]  transition-all duration-300 ease-in-out ">
        <div class="pointer-events-auto relative flex max-h-[100%] w-full flex-col overflow-hidden rounded-md border-none shadow-[0px_5px_10px_#333] bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          {/*header*/}
          <div className="flex items-start justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-2xl font-semibold truncate">
              {notification?.subject}
            </h3>

            <button
              className="p-1 ml-auto outline-none focus:outline-none text-red-700"
              onClick={() => setSelectedNotificationForView(null)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          <div class="relative overflow-y-auto p-3">
            <div
              dangerouslySetInnerHTML={{ __html: notification?.emailBody }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotification;
