import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Select from "react-select";

const AddUser = (props) => {
  const [userList, setUserList] = useState([]);
  const [originalUserList, setOriginalUserList] = useState([]);
  const { userDetails, dispatchUserRoles, userRoles } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userRoleList, setUserRoleList] = useState([]);
  const [roleValue, setRoleValue] = useState(null);
  const [alluserSelectForRemove, setAlluserSelectForRemove] = useState(false);
  useEffect(() => {
    if (userDetails?.userID !== "") {
      getUserList();
      getUsersRoles();
    }
  }, [userDetails]);
  const getUserList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_USERS_LIST, {
      recordCount: 0,
      regionIds: "null",
      roleId: 0,
      rowCount: 10000,
      searchText: "null",
      sortBy: "",
      sortDirection: "DESC",
      status: true,
    })
      .then((data) => {
        let dataList = [];
        const notification = props.selectedNotificationForAdd;

        if (
          notification.userIDs !== null &&
          notification.userIDs !== undefined
        ) {
          const selectedUser = notification.userIDs?.split(",");

          data.filter((word) => {
            if (props.type === "Add") {
              if (!selectedUser.includes(JSON.stringify(word.userID))) {
                dataList.push({
                  selected: false,
                  ...word,
                });
              }
            } else if (props.type === "View") {
              if (selectedUser.includes(JSON.stringify(word.userID))) {
                dataList.push({
                  selected: false,
                  ...word,
                });
              }
            }
          });
        } else {
          if (props.type === "Add") {
            data.map((item) => {
              dataList.push({
                selected: false,
                ...item,
              });
            });
          }
        }
        setOriginalUserList(dataList);
        setUserList(dataList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        props.setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Failed to load User Directory ",
        });
      });
  };

  const getUsersRoles = () => {
    if (userRoles === undefined || userRoles?.length === 0) {
      setLoading(true);
      APIHelper.get(APIURLConstant.GET_USER_ROLES)
        .then((res) => {
          const updatedTags = res.map((item) => {
            return {
              label: item.roleName,
              value: item.roleName,
              ...item,
            };
          });
          if (updatedTags && updatedTags.length > 0) {
            setUserRoleList([...updatedTags]);
            const roles = [...updatedTags];
            dispatchUserRoles("ADD_ROLES", roles);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setUserRoleList([...userRoles]);
    }
  };

  const handleSearchUserList = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      const newUserList = originalUserList.filter(
        (item) =>
          item.firstName.toLowerCase().includes(value.toLowerCase()) ||
          item.lastName.toLowerCase().includes(value.toLowerCase())
      );

      setUserList(newUserList);
    } else {
      setUserList(originalUserList);
    }
  };

  const handleSelectUserForRemove = (user, value) => {
    let SelectedData;
    if (alluserSelectForRemove === false) {
      SelectedData = originalUserList?.map((ele) => {
        if (ele.userID === user.userID) {
          return { ...ele, ...{ selected: value } };
        } else {
          return ele;
        }
      });
    } else {
      setAlluserSelectForRemove(false);
      SelectedData = originalUserList?.map((ele) => {
        if (ele.userID === user.userID) {
          return { ...ele, ...{ selected: false } };
        } else {
          return { ...ele, ...{ selected: true } };
        }
      });
    }
    setOriginalUserList(SelectedData);
    setSearchText("");
    setRoleValue(null);
    setUserList(SelectedData);
  };

  const handleSelectUser = (user) => {
    const SelectedData = originalUserList?.map((ele) => {
      if (ele.userID === user.userID) {
        return { ...ele, ...{ selected: !ele?.selected } };
      } else {
        return ele;
      }
    });

    setOriginalUserList(SelectedData);
    setSearchText("");
    setRoleValue(null);
    setUserList(SelectedData);
  };

  const handleSelectAllUser = () => {
    if (roleValue !== null) {
      const SelectedData = originalUserList?.map((ele) => {
        if (ele.roleList === roleValue?.roleName) {
          return { ...ele, ...{ selected: !ele?.selected } };
        } else {
          return ele;
        }
      });
      setSearchText("");

      setUserList([]);
      // setRoleValue(null);
      setOriginalUserList(SelectedData);
    } else {
      const SelectedData = originalUserList?.map((ele) => {
        return { ...ele, ...{ selected: !ele?.selected } };
      });
      setSearchText("");

      setUserList([]);
      setRoleValue(null);
      setOriginalUserList(SelectedData);
    }
  };

  const handleChangeUserRole = (value) => {
    setRoleValue(value);

    if (value !== null) {
      let filteredUser = [];
      originalUserList?.map((item) => {
        if (item.roleList === value?.roleName && item.selected === false) {
          filteredUser.push(item);
        }
      });
      setUserList(filteredUser);
    } else {
      setUserList(originalUserList);
    }
  };

  const handleAdd = () => {
    if (
      props.selectedNotificationForAdd !== null &&
      props.selectedNotificationForAdd !== undefined
    ) {
      const notification = props.selectedNotificationForAdd;
      let selectedList = [];
      if (props.type === "Add") {
        originalUserList.map((item) => {
          if (item.selected === true) {
            selectedList.push(item.userID);
          }
        });
      } else {
        userList.map((item) => {
          if (item.selected === false) {
            selectedList.push(item.userID);
          }
        });
      }

      const payLoad = {
        customNotificationID: notification.customNotificationID,
        notificationType: "Admin Custom Notification",
        emailBody: notification.emailBody,
        subject: notification.subject,
        userName: notification.subject,
        status: notification.status,
        createdBy: notification?.createdBy,
        createdDate: notification.createdDate,
        modifiedBy: notification?.modifiedBy,
        modifiedDate: notification.modifiedDate,
        isDeleted: notification.isDeleted,
        isActive: notification.isActive,
        isNotificationForEmail: notification.isNotificationForEmail,
        isNotificationForApp: notification.isNotificationForApp,
        userRoleIDs: null,
        userIDs:
          props.type === "Add"
            ? notification.userIDs === null
              ? selectedList?.length > 0
                ? selectedList.join(",")
                : null
              : selectedList?.length > 0
              ? notification.userIDs + "," + selectedList.join(",")
              : notification.userIDs
            : selectedList?.length > 0
            ? selectedList.join(",")
            : null,
        userRoleNames: null,
        userNames: null,
      };

      updateNotification(payLoad);
    }
  };

  const updateNotification = (payLoad) => {
    props.setLoading(true);

    APIHelper.post(APIURLConstant.CREATE_CUSTOME_NOTIFICATION, payLoad)
      .then((res) => {
        props.setLoading(false);
        props.setToasterMessage({
          errorType: "success",
          showToaster: true,
          message:
            props.type === "Add"
              ? "User added successfully."
              : "User removed successfully.",
        });
        props.setSelectedNotificationForAdd(null);
        props.getCustomeNotification();
        props.setAddUsers(false);
        props.setViewUsers(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.setToasterMessage({
          errorType: "error",
          showToaster: true,
          message:
            props.type === "Add"
              ? "Error while adding a User."
              : "Error while removing a User.",
        });
      });
  };

  const handleSelectAllUserForRemove = (value) => {
    let updatedList = [];
    console.log(value);
    setAlluserSelectForRemove(value);
    userList.map((item) => {
      updatedList.push({ ...item, selected: value });
    });
    setUserList(updatedList);
  };

  return (
    <div class="fixed flex justify-center inset-0 z-[10000] items-center  overflow-y-auto overflow-x-hidden outline-none">
      <div class="pointer-events-none relative h-5/6 w-full md:w-3/6 translate-y-[-50px]  transition-all duration-300 ease-in-out ">
        <div class="pointer-events-auto relative flex max-h-[100%] w-full flex-col overflow-hidden rounded-md border-none shadow-[0px_5px_10px_#333] bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <h5
              className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
              id="exampleModalScrollableLabel"
            >
              {props.type} User
            </h5>

            <button
              className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              aria-label="Close"
              onClick={() => {
                props.setViewUsers(false);
                props.setAddUsers(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {props.type === "Add" ? (
            <div
              className={`${
                props.type === "Add"
                  ? "grid grid-cols-1 md:grid-cols-2 overflow-hidden"
                  : "w-full overflow-hidden h-full"
              }`}
            >
              <div className="h-full border-r overflow-hidden min-h-[25rem]">
                <div className="p-2 h-1/4">
                  <Select
                    className="w-full pb-2 h-10"
                    placeholder={"Select User Role"}
                    isClearable
                    onChange={(value) => {
                      handleChangeUserRole(value);
                    }}
                    value={roleValue}
                    options={userRoleList}
                  />
                  <input
                    type="text"
                    className="w-full focus:outline-none h-10 rounded-lg border border-grey-300 px-1 "
                    placeholder="Search by user..."
                    onChange={(e) => handleSearchUserList(e.target.value)}
                    value={searchText}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && searchText?.length > 0) {
                        handleSearchUserList(e.target.value);
                      }
                    }}
                  />
                </div>

                <div className="relative overflow-y-auto overflow-x-hidden  h-3/4 w-full p-4">
                  {loading ? (
                    <div className="flex justify-center h-full w-full p-2 ">
                      <span className="loader"></span>
                    </div>
                  ) : (
                    <>
                      {userList && userList?.length > 0 && (
                        <div className="border-b p-2 n">
                          <button onClick={() => handleSelectAllUser()}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-4 text-blue-900"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>

                          <label className=" ml-2">Add all User</label>
                        </div>
                      )}
                      {userList && userList?.length > 0 ? (
                        userList.map((user) => {
                          if (user?.selected === false)
                            return (
                              <div className="border-b p-2 flex gap-2 flex-wrap">
                                <button onClick={() => handleSelectUser(user)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-5 h-4 text-blue-900"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                                <div className="">
                                  <p className="">
                                    {user.firstName} {user.lastName}
                                  </p>
                                  <p className="text-blue-800 text-[12px]">
                                    {user.email}
                                  </p>
                                </div>
                              </div>
                            );
                        })
                      ) : (
                        <p className="p-2 border-y ">No Data</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="h-full w-full overflow-hidden p-2">
                <p className="font-bold h-1/6">
                  Selected users
                  {" ("}
                  {
                    originalUserList?.filter((item) => item.selected === true)
                      ?.length
                  }
                  {")"}
                </p>
                <div className="relative overflow-y-auto overflow-x-hidden h-5/6 w-full  p-4">
                  {originalUserList &&
                    originalUserList?.length > 0 &&
                    originalUserList.map((user) => {
                      if (user?.selected) {
                        return (
                          <div className="border-b p-2 flex gap-2 flex-wrap">
                            <button onClick={() => handleSelectUser(user)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-5 h-4 text-red-500 "
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <div className="">
                              <p className="">
                                {user.firstName} {user.lastName}
                              </p>
                              <p className="text-blue-800 text-[12px]">
                                {user.email}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="relative overflow-y-auto  h-full w-full p-4">
                {loading ? (
                  <div className="flex justify-center w-full p-2 ">
                    <span className="loader"></span>
                  </div>
                ) : (
                  <>
                    {props.tabActive === 0 && userList?.length > 0 && (
                      <div className="border-b p-2 ">
                        <input
                          type="checkbox"
                          checked={alluserSelectForRemove}
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                          onChange={(e) =>
                            handleSelectAllUserForRemove(e.target.checked)
                          }
                        />

                        <label className="font-bold ml-2">Select all</label>
                      </div>
                    )}
                    {userList &&
                      userList?.length > 0 &&
                      userList.map((user) => {
                        return (
                          <div className="border-b p-2 flex gap-2 flex-wrap">
                            {props.tabActive === 0 && (
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                                checked={user?.selected}
                                onChange={(e) =>
                                  handleSelectUserForRemove(
                                    user,
                                    e.target.checked
                                  )
                                }
                              />
                            )}

                            <div className="">
                              <p className="">
                                {user.firstName} {user.lastName}
                              </p>
                              <p className="text-blue-800 text-[12px]">
                                {user.email}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </>
          )}
          {props.tabActive === 0 && (
            <div className="flex flex-wrap gap-3 justify-end items-center rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 ">
              <button
                onClick={() => {
                  props.setViewUsers(false);
                  props.setAddUsers(false);
                  props.setSelectedNotificationForAdd(null);
                }}
                className="bg-white p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-blue-900 border border-blue-900"
              >
                Cancel
              </button>

              <button
                className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                onClick={() => handleAdd()}
              >
                {props.type === "Add" ? "Add" : "Remove"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddUser;
