import "./Table.css";
import DeleteIcon from "../../assets/delete.svg";
import EditIcon from "../../assets/edit.svg";
import TagIcon from "../../assets/tag-button.svg";
import ArrowIcon from "../../assets/arrow-down.svg";
import CloneIcon from "../../assets/program_clone.svg";
import AuditHistory from "../../assets/audit_history.svg";
import moment from "moment";

const TableView = (props) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
      <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
        <thead className=" text-[#323232] uppercase bg-gray-100">
          <tr>
            {props.column?.map((item, index) => {
              return (
                <TableHeadItems
                  setAllSelected={props.setAllSelected}
                  onClickSelectAll={props.onClickSelectAll}
                  isAllSelected={props.isAllSelected}
                  item={item}
                  {...props}
                  key={Math.random()}
                />
              );
            })}
          </tr>
        </thead>
        {props.tableData.length > 0 ? (
          <tbody className="">
            {props.tableData?.map((items, index) => {
              return (
                <TableRow
                  item={items}
                  key={Math.random()}
                  column={props.column}
                  onSelectTableRow={props.onSelectTableRow}
                  {...props}
                />
              );
            })}
          </tbody>
        ) : (
          <tbody className="border w-full">
            <tr>
              <td className="text-center" colSpan={props.column.length}>
                No Data
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

const TableHeadItems = (props) => {
  if (props.showCheckboxInHeader && props.item.value === "checkbox") {
    return (
      <th scope="col" className={`px-3 py-3 ${props.item.width}`}>
        <input
          className="w-4 h-4 text-blue-600 bg-gray-100 rounded"
          type={props.item.value}
          onChange={(e) => {
            props.setAllSelected(!props.isAllSelected);
            props.onClickSelectAll(e, props.isAllSelected);
          }}
          checked={props.isAllSelected}
        />
      </th>
    );
  } else {
    return (
      <>
        {props.item.heading !== "SR.NO" && (
          <th scope="col" className={`px-3 py-3 ${props.item.width}`}>
            <div className="flex gap-1">
              <button
                className={`${
                  props.item.noSorting !== undefined &&
                  props.item.noSorting === true
                    ? "cursor-text"
                    : "cursor-pointer"
                } whitespace-nowrap`}
                onClick={() =>
                  props.item.heading !== "ACTION" &&
                  props.item.heading !== "ACTIONS" &&
                  props.item.heading !== "STATUS" &&
                  props.item.heading !== "REVIEWER" &&
                  props.handleSort(props.item.heading)
                }
              >
                {props.item.heading}
              </button>
              {props?.sortStatus !== undefined &&
              props?.sortStatus === "ascending" &&
              props.item.heading === props?.sortStatusText ? (
                <span>▲</span>
              ) : props?.sortStatus !== undefined &&
                props?.sortStatus === "descending" &&
                props.item.heading === props?.sortStatusText ? (
                <span>▼</span>
              ) : null}
            </div>
          </th>
        )}
      </>
    );
  }
};

const TableRow = (props) => {
  const { column, item } = props;

  const getStartEndDates = (dateParam) => {
    return dateParam === null ? '' : moment(dateParam).format("MMM DD, YYYY");
  };

  return (
    <tr
      key={Math.random()}
      className="bg-white border-b text-[#394150] dark:bg-gray-800 dark:border-gray-700"
    >
      {column.map((columnItem, index) => {
        if (props.showCheckboxInHeader && columnItem.value === "checkbox") {
          return (
            <td
              className={`px-3 py-3  ${columnItem.width} `}
              key={Math.random()}
            >
              {!item.isEditable ||
              (item.isEditable && item.isEditable.value === true) ? (
                <input
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded "
                  type={columnItem.value}
                  onChange={(e) => {
                    props.onSelectTableRow(e, item);
                    props.setAllSelected(false);
                  }}
                  disabled={
                    item.isEditable
                      ? item.isEditable.value === true
                        ? false
                        : true
                      : false
                  }
                  checked={item.isSelected.value}
                />
              ) : (
                <></>
              )}
            </td>
          );
        } else if (columnItem.value === "action") {
          return (
            <td
              className={`flex ${
                props.sourceName !== "Program-List-Calender" ? "flex-wrap" : ""
              }  gap-2 px-3 py-3 ${columnItem.width}`}
              key={Math.random()}
            >
              {props.isViewButton &&
                (item.programStatus.value === "Published" ? (
                  <button
                    onClick={() => props.handleClickView(item)}
                    data-te-toggle="tooltip"
                    title="View"
                    data-galabel={
                      props.sourceName == "Program-List"
                        ? "Program Details Program List"
                        : props.sourceName == "MyPrograms"
                        ? "Program Details My Program List"
                        : `${props.sourceName}`
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-6 text-blue-900"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                ) : (
                  <div className="w-6"></div>
                ))}
              {props.showHistoryButton !== undefined &&
                props.showHistoryButton === true && (
                  <button
                    className=""
                    data-te-toggle="tooltip"
                    title="History"
                    onClick={() => props.handleClickHistory(item)}
                  >
                    <img
                      src={AuditHistory}
                      className="h-[1rem] w-[1rem]"
                      alt="history"
                    />
                  </button>
                )}
              {!item.isEditable ||
              (item.isEditable && item.isEditable.value === true)
                ? props.showCloneButton !== undefined &&
                  props.showCloneButton === true && (
                    <button
                      onClick={() => props.handleCloneButton(item)}
                      data-te-toggle="tooltip"
                      title="Clone"
                      data-galabel={`${
                        props.sourceName === "MyPrograms"
                          ? "Clone My Program List"
                          : props.sourceName === "Program-List"
                          ? "Clone Program List"
                          : `Clone ${props.sourceName}`
                      } `}
                    >
                      <img src={CloneIcon} className="h-15 w-5" alt="clone" />
                    </button>
                  )
                : null}

              {!item.isEditable ||
              (item.isEditable && item.isEditable.value === true) ? (
                <>
                  <button
                    disabled={
                      item.isEditable
                        ? item.isEditable.value === true
                          ? false
                          : true
                        : false
                    }
                    onClick={() => props.handleClickEdit(item)}
                    data-te-toggle="tooltip"
                    title="Edit"
                    data-galabel={`${
                      props.sourceName === "MyPrograms"
                        ? "Edit My Program List"
                        : props.sourceName === "Program-List"
                        ? "Edit Program List"
                        : `Edit ${props.sourceName}`
                    } `}
                  >
                    <img src={EditIcon} className="h-15 w-5" alt="edit" />
                  </button>
                  <button
                    className="text-[#c8102e]"
                    disabled={
                      item.isEditable
                        ? item.isEditable.value === true
                          ? false
                          : true
                        : false
                    }
                    data-te-toggle="tooltip"
                    title="Delete"
                    onClick={() => props.handleDeleteUser(item)}
                    data-galabel={
                      props.sourceName == "Program-List"
                        ? "Delete Program List"
                        : props.sourceName == "MyPrograms"
                        ? "Delete My Program List"
                        : `Delete ${props?.sourceName}`
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </>
              ) : (
                <></>
              )}
            </td>
          );
        } else if (columnItem.value === "isActive") {
          return (
            <td className={`px-3 py-3 ${columnItem.width}`} key={Math.random()}>
              {(item['isUserExpired']!== undefined && item['isUserExpired'].value === true) ? "Expired" : item[`${columnItem.value}`].value === true
                ? "Active"
                : "InActive"}
            </td>
          );
        } else if (
          columnItem.value === "tagList" ||
          columnItem.value === "userRoleNames"
        ) {
          const tags =
            item[`${columnItem.value}`].value &&
            item[`${columnItem.value}`].value.split(/[,]+/);
          return (
            <td className={`px-3 py-3 ${columnItem.width}`} key={Math.random()}>
              <div className="flex flex-wrap">
                {tags &&
                  tags.length > 0 &&
                  // eslint-disable-next-line array-callback-return
                  tags.map((allTags, index) => {
                    if (allTags.length !== 0 && index < 2) {
                      return (
                        <>
                          <p className="bg-[#394150] mr-2 w-fit mb-2 flex rounded">
                            <span className="px-2 mx-1  whitespace-nowrap text-white text-sm font-normal">
                              {allTags}
                            </span>
                            <img
                              src={TagIcon}
                              className="h-15 w-5 mr-1 "
                              alt="Tag"
                            />
                          </p>
                        </>
                      );
                    }
                  })}
                {tags && tags.length > 2 && (
                  <button
                    onClick={() => props.handleOpenTagList(tags)}
                    className="pl-1 bg-[#394150] w-fit mb-2 rounded flex align-center text-center"
                  >
                    <span className="text-white text-sm ">view...</span>
                    <img
                      src={ArrowIcon}
                      className="h-3 w-5 arrow-img mt-1"
                      alt="arrow"
                    />
                  </button>
                )}
              </div>
            </td>
          );
        } else if (columnItem.value === "module") {
          return (
            <td
              className={` px-3 py-3 ${columnItem.width}`}
              key={Math.random()}
            >
              <button
                onClick={() => props.handleOpenModule(item)}
                className="text-blue-800"
              >
                open
              </button>
            </td>
          );
        } else if (columnItem.value === "startDate" || columnItem.value === "endDate" || columnItem.value === "expirationDate") {
          return (
            <td
              className={` px-3 py-3 ${columnItem.width}`}
              key={Math.random()}
              style={{
                width: "7%",
              }}
            >
              {columnItem.value === "startDate" ? getStartEndDates(
                        item[`${columnItem?.value}`]?.value) :
                        getStartEndDates(
                          item[`${columnItem?.value}`]?.value
                        )
                      }
            </td>
          );
        } else
          return (
            <>
              {columnItem.value !== "checkbox" && (
                <td
                  className={`px-3 py-3 ${columnItem.width}`}
                  key={Math.random()}
                >
                  {" "}
                  {/* {item[`${columnItem?.value}`]?.value} */}
                  {columnItem.value !== undefined &&
                  columnItem.value === "localProgramName" ? (
                    <button
                      className={`text-start  p-0 ${
                        item.programStatus.value === "Published"
                          ? "cursor-pointer"
                          : "cursor-default	"
                      }`}
                      onClick={() =>
                        item.programStatus.value === "Published" &&
                        props.handleClickView(item)
                      }
                    >
                      <span className="text-[#1D428A] font-medium">
                        {" "}
                        {item[`${columnItem?.value}`]?.value.split("^")[0]}
                      </span>{" "}
                      <br />
                      <span style={{fontSize: "12px",}}>
                      {getStartEndDates(
                        item[`${columnItem?.value}`]?.value.split("^")[1]
                      )}{" "}
                      -{" "}
                      {getStartEndDates(
                        item[`${columnItem?.value}`]?.value.split("^")[2]
                      )}</span>
                    </button>
                  ) : (
                    <>{item[`${columnItem?.value}`]?.value}</>
                  )}
                </td>
              )}
            </>
          );
      })}
    </tr>
  );
};

export default TableView;
