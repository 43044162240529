import { useEffect, useState, useMemo } from "react";
import APIHelper from "../../../utils/config/apiHelper";
import APIURLConstant from "../../../utils/config/apiURLConstant";
import Toaster from "../../../sharedComponent/Toaster/Toaster";
import Loader from "../../../sharedComponent/Loader/Loader";
import { useContext } from "react";
import { AppContext } from "../../../store/context";
import moment from "moment";

const ProgramCategoryView = (props) => {
  const [programCategory, setProgramCategory] = useState([]);
  const [programType, setProgramType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [allProgramList, setAllprogramList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const {
    userDetails,
    userCategories,
    dispatchCategories,
    dispatchProgramTypes,
    userProgramTypesAll,
  } = useContext(AppContext);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    if (userDetails.email !== "") {
      getProgramCategory();
      getProgramType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: userDetails.roleIDList,
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res && res.length > 0) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Categories`,
          });
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const getProgramType = () => {
    console.log(userProgramTypesAll.length === 0);
    if (userProgramTypesAll === undefined || userProgramTypesAll.length === 0) {
      setProgramType([]);
      setLoading(true);
      APIHelper.get(APIURLConstant.GET_PROGRAMTYPESALL)
        .then((res) => {
          if (res) {
            const programType = res;
            if (programType && programType.length > 0) {
              setProgramType([...programType]);
              dispatchProgramTypes("ADD_PROGRAM_TYPES", [...programType]);
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Types`,
          });
          setLoading(false);
        });
    } else {
      setProgramType([...userProgramTypesAll]);
    }
  };

  useEffect(() => {
    if (isFirstTime && programType?.length > 0 && props?.selectedProgram) {
      programType.forEach((item, index) => {
        if (item.programTypeID === props?.selectedProgram[0]?.programTypeID) {
          handleProgramLoad(item, index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programType, props, isFirstTime]);

  const handleProgramLoad = (programType, item) => {
    setIsFirstTime(false);
    if (
      selectedType !== null &&
      selectedType !== undefined &&
      selectedType?.programTypeID === programType.programTypeID
    ) {
      setSelectedType(null);
    } else {
      setSelectedType(programType);
      setLoading(true);
      const payLoad = {
        programCategoryID: programType.programCategoryID,
        programTypeID: programType.programTypeID,
        userEmail: userDetails?.email,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAM_BY_CATEGORY_TYPE, payLoad)
        .then((res) => {
          if (res) {
            let dataUpdated = [];
            // setAllprogramList(res);
            res &&
              res.length > 0 &&
              res?.map((item) => {
                if (props?.selectedProgram[0]?.programID !== item?.programID) {
                  dataUpdated.push(item);
                }
              });
            const selectedData =
              res && res.length > 0
                ? res.filter(
                    (item) =>
                      props?.selectedProgram[0]?.programID === item?.programID
                  )
                : "";

            const newArray = [selectedData[0]].concat(dataUpdated);

            setAllprogramList(newArray);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };
  const ProgramListRender = () => {
    return (
      <div className=" ">
        {loading ? (
          <div className="flex justify-center w-full p-2 border-t">
            <span class="loader"></span>
          </div>
        ) : (
          <>
            {allProgramList &&
              allProgramList.length > 0 &&
              allProgramList.map((program) => {
                if (program?.programID) {
                  return (
                    <div className="border-b ">
                      <button
                        data-te-sidenav-toggle-ref
                        data-te-target="#category-sidenav"
                        aria-controls="#category-sidenav"
                        aria-haspopup="true"
                        onClick={() => {
                          props.setSelectedProgram([program]);
                          setSelectedType(null);
                          setAllprogramList([]);
                          setIsFirstTime(true);
                        }}
                        className={`py-1 pl-9 pr-1 text-left w-full flex gap-2 items-center ${
                          props?.selectedProgram &&
                          props?.selectedProgram[0]?.programID ===
                            program?.programID
                            ? "text-[#C8102E]"
                            : "text-blue-900"
                        }`}
                      >
                        {props?.selectedProgram &&
                        props?.selectedProgram[0]?.programID ===
                          program?.programID ? (
                          <div
                            className={`h-1 w-1 rounded-full bg-[#C8102E]`}
                          />
                        ) : props?.selectedProgram[0]?.programID !==
                          program?.programID ? (
                          <div
                            className={`h-1 w-1 rounded-full bg-[#1D428A]`}
                          />
                        ) : null}

                        <span>{program?.localProgramName}{
                            <div><span
                            style={{
                              color: "#394150",
                              fontSize: "14px",
                            }}
                              >{moment(program?.overallStartDate).format("MMM DD, YYYY")} - {moment(program?.overallEndDate).format("MMM DD, YYYY")}</span></div>
                        }</span>
                      </button>
                    </div>
                  );
                }
              })}
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <div id="accordionExample">
        {programCategory &&
          programCategory.length > 0 &&
          programCategory.map((item) => {
            return item.value ===
              props?.selectedProgram[0]?.programCategoryID ? (
              <div className="bg-white border-b border-blue-800 rounded dark:border-neutral-600 dark:bg-neutral-800">
                <h2 className="mb-0" id={`headingTwo${item.value}`}>
                  <button
                    className="text-blue-900 rounded text-md font-bold group relative flex gap-2 w-full items-center bg-white py-2 px-2 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-blue-900 [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                    type="button"
                    data-te-collapse-init
                    data-te-target={`#collapseTwo${item.value}`}
                    aria-expanded="true"
                    aria-controls={`collapseTwo${item.value}`}
                  >
                    <span
                      className=" h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white"
                      data-te-value={item.value}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                    {item.label}
                  </button>
                </h2>
                <div
                  id={`collapseTwo${item.value}`}
                  className="!visible"
                  data-te-collapse-item
                  data-te-collapse-show
                  aria-labelledby={`headingTwo${item.value}`}
                  data-te-parent="#accordionExample"
                >
                  <div className="py-2">
                    <ul>
                      {programType.map((prgType) => {
                        if (prgType.programCategoryID === item.value) {
                          if (
                            prgType.programTypeID ===
                            props.selectedProgram[0].programTypeID
                          ) {
                            return (
                              <li className=" ">
                                <button
                                  className="flex gap-2 text-blue-900 font-semibold text-left w-full bg-slate-300 px-4 py-1  border rounded"
                                  onClick={() => {
                                    handleProgramLoad(prgType, item);
                                  }}
                                >
                                  <span
                                    className={`text-blue-900 ${
                                      selectedType &&
                                      selectedType?.programTypeID ===
                                        prgType.programTypeID
                                        ? "rotate-180"
                                        : ""
                                    }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="h-6 w-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                      />
                                    </svg>
                                  </span>
                                  {prgType.type}
                                </button>
                                {selectedType !== null &&
                                selectedType !== undefined &&
                                selectedType?.programTypeID ===
                                  prgType.programTypeID ? (
                                  <ProgramListRender />
                                ) : null}
                              </li>
                            );
                          } else {
                            return (
                              <li className="border  rounded ">
                                <button
                                  onClick={() => {
                                    handleProgramLoad(prgType, item);
                                  }}
                                  className="flex  text-blue-900 gap-2 text-left w-full py-1 px-4"
                                >
                                  <span
                                    className={`text-blue-900 ${
                                      selectedType &&
                                      selectedType?.programTypeID ===
                                        prgType.programTypeID
                                        ? "rotate-180"
                                        : ""
                                    }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="h-6 w-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                      />
                                    </svg>
                                  </span>
                                  <span className=" text-blue-900 text-base">
                                    {prgType.type}
                                  </span>
                                </button>
                                {selectedType !== null &&
                                selectedType !== undefined &&
                                selectedType?.programTypeID ===
                                  prgType.programTypeID ? (
                                  <ProgramListRender />
                                ) : null}
                              </li>
                            );
                          }
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="rounded border-b border-blue-800 bg-white ">
                <h2 className="mb-0" id={`headingTwo${item.value}`}>
                  <button
                    className="text-blue-900 rounded text-md font-bold group relative flex gap-2 w-full items-center  border-0 bg-white py-2 px-2 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                    type="button"
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target={`#collapseTwo${item.value}`}
                    aria-expanded="false"
                    aria-controls={`collapseTwo${item.value}`}
                  >
                    {" "}
                    <span
                      className="  h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white"
                      data-te-value={item.value}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                    {item.label}
                  </button>
                </h2>
                <div
                  id={`collapseTwo${item.value}`}
                  className="!visible hidden"
                  data-te-collapse-item
                  aria-labelledby={`headingTwo${item.value}`}
                  data-te-parent="#accordionExample"
                >
                  <div className="py-2">
                    <ul>
                      {programType.map((prgType) => {
                        if (prgType.programCategoryID === item.value) {
                          return (
                            <li className="border border-slate-300 w-full text-blue-900 text-base">
                              <button
                                onClick={() => {
                                  handleProgramLoad(prgType, item);
                                }}
                                className="flex gap-2 text-left w-full  pl-4"
                              >
                                <span
                                  className={`text-slate-400 ${
                                    selectedType &&
                                    selectedType?.programTypeID ===
                                      prgType.programTypeID
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </span>
                                <span className=" text-blue-900 text-base">
                                  {prgType.type}
                                </span>
                              </button>
                              {selectedType !== null &&
                              selectedType !== undefined &&
                              selectedType?.programTypeID ===
                                prgType.programTypeID ? (
                                <ProgramListRender />
                              ) : null}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};

export default ProgramCategoryView;
