//import { useTranslation } from "react-i18next";
import * as utils from "../../utils";

const utilsString = {
  t: (label, options) => {
    return utils.i18n.t(label, options);
  },
};

export default utilsString;
