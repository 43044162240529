import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import * as te from "tw-elements";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter as Router } from "react-router-dom";
import { msalConfig } from "./config/authConfig";
// i18n
import { initialiseI18n } from "./utils/i18n/i18n";
initialiseI18n();

const pca = new PublicClientApplication(msalConfig);

// ReactDOM.render(
//   // <BrowserRouter>
//   <React.StrictMode>
//     <MsalProvider instance={pca}>
//       <Router getUserConfirmation={() => {}}>
//         <App />
//       </Router>
//     </MsalProvider>
//   </React.StrictMode>,
//   // </BrowserRouter>
//   document.getElementById("root")
// );

let root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <MsalProvider instance={pca}>
       <Router getUserConfirmation={() => {}}>
         <App />
       </Router>
     </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
