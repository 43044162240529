import React, { useEffect, useState, useContext } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "./programViewSurveyForm.css";
import { useLocation } from "react-router-dom";

const LoadSurveyForm = (props) => {
  const survey = new Model(props.modalJson);
  const location = useLocation();
  const [answerJSON, setAnswerJSON] = useState(props.surveyDataElements);
  let isMarkAsCompletedClicked = false;
  //survey.showNavigationButtons = false;
  //survey.goNextPageAutomatic = true;
  survey.showPrevButton = true;
  survey.showCompletedPage = false;
  survey.data =
    answerJSON && Object.keys(answerJSON).length !== 0
      ? answerJSON
      : props.surveyDataElements;
  useEffect(() => {
    if (answerJSON && Object.keys(answerJSON).length !== 0) {
      survey.data = answerJSON;
    }
  }, [answerJSON]);

  if (!props.isAdmin) {
    survey.mode = "display";
  }

  survey.completeText = "Save";

  if (props?.isModuleData && props?.isAdmin && props?.moduleDetails) {
    const moduleType = props?.moduleDetails;
    let buttonTitle = "";
    if (
      moduleType?.programModuleID == 4 ||
      moduleType?.programModuleID == 6 ||
      moduleType?.programModuleID == 7 ||
      moduleType?.programModuleID == 3 ||
      moduleType?.programModuleID == 5
    ) {
      buttonTitle = "Save and Submit";
    } else {
      buttonTitle = "Save and Mark as Complete";
    }
    survey.addNavigationItem({
      id: "sv-nav-clear-page",
      title: buttonTitle,
      action: () => {
        isMarkAsCompletedClicked = true;
        //survey.isCompleted = true
        //options = {...options}
        survey.doComplete();
      },
      css: "nav-button",
      innerCss: "sd-btn nav-input",
    });
  }

  survey.onComplete.add((sender, options) => {
    //sender.clear(false, true);
    let objPDF = {};
    let modulePDF = {};
    const isAllQuestionsAnswered = CheckAllQuestionsAnswered(sender);
    // if (
    //   props.modalJson &&
    //   props.modalJson.pages?.length > 0 &&
    //   props.modalJson.pages[0].elements?.length > 0
    // ) {
    //   if (sender.data) {
    //     //props.modalJson.pages[0].elements.forEach((item) => {console.log(item.name)})
    //     const selectedAnswers = Object.entries(sender.data);
    //     const selectedQuestions = props.modalJson.pages[0].elements;
    //     let updatedAnswers = {};
    //     selectedQuestions.forEach((item) => {
    //       if (item.choices) {
    //         //console.log(sender.data[item.name])
    //       } else if (item.items) {
    //       } else {
    //       }
    //     });

    //     //

    //     selectedQuestions.forEach((question) => {
    //       //questionItem.elements
    //       //?.filter((item) => item.type !== "file")
    //       //.forEach((question) => {
    //       let answersPDF;
    //       if (
    //         question.type !== "multipletext" &&
    //         question.type !== "text" &&
    //         question.type !== "file" &&
    //         question.type !== "paneldynamic"
    //       ) {
    //         if (
    //           typeof sender.data[question.name] == "object" &&
    //           sender.data[question.name].length > 1
    //         ) {
    //           // answersPDF = question.choices.filter((choice) =>
    //           // {
    //           //   return sender.data[question.name].forEach((ans) => {
    //           //     return choice.value == ans})})[0].text
    //           let ansArr = [];
    //           sender.data[question.name].forEach((ans) => {
    //             ansArr.push(
    //               question.choices.filter((choice) => {
    //                 return choice.value == ans;
    //               })[0].text
    //             );
    //           });
    //           answersPDF = ansArr.join(",");
    //         } else {
    //           answersPDF =
    //             sender.data[question.name] !== undefined
    //               ? question.choices.filter((choice) => {
    //                   return choice.value == sender.data[question.name];
    //                 })[0].text
    //               : "";
    //         }
    //       } else if (question.type === "multipletext") {
    //         let myArr = [];
    //         question.items.forEach((que) => {
    //           myArr.push(
    //             que.title + ": ",
    //             sender.data[question.name] !== undefined &&
    //               sender.data[question.name][que.name]
    //               ? sender.data[question.name][que.name] + ".  "
    //               : ""
    //           );
    //         });
    //         answersPDF = myArr.join(" ");
    //       } else if (question.type === "paneldynamic") {
    //         let myArr = [];
    //         question.templateElements.forEach((que) => {
    //           myArr.push(
    //             que.title + ": ",
    //             sender.data[question.name] !== undefined &&
    //               sender.data[question.name][0][que.name]
    //               ? sender.data[question.name][0][que.name] + ".  "
    //               : ""
    //           );
    //         });
    //         answersPDF = myArr.join(" ");
    //       } else {
    //         answersPDF = question.title;
    //         answersPDF =
    //           answersPDF + ": " + sender.data[question.name] !== undefined &&
    //           sender.data[question.name]
    //             ? sender.data[question.name]
    //             : "";
    //       }

    //       //  objPDF = {
    //       //   [question.name]: answersPDF,
    //       //   // questionText: question.name,//question.title,
    //       //   // answerText: answersPDF,
    //       //   // answerText:
    //       //   //   question.type === "file" &&
    //       //   //   sender.data[question.name] &&
    //       //   //   sender.data[question.name].length > 0
    //       //   //     ? sender.data[question.name][0].name //sender.data[question.name][0].name
    //       //   //     : sender.data[question.name] && sender.data[question.name].length > 0 && typeof(sender.data[question.name]) == 'object' ?
    //       //   //     sender.data[question.name].join(',') :
    //       //   //     JSON.stringify(sender.data[question.name]), //sender.data[question.name],
    //       // };
    //       //const questionName = question.name
    //       objPDF[question.name] = answersPDF;
    //       //modulePDF.push(objPDF);
    //       //});
    //     });

    //     //
    //   }
    // }
    props.storeResult(
      //JSON.stringify(sender.data, null, 3),
      //JSON.stringify(Object.assign({}, modulePDF), null, 3),
      JSON.stringify(sender.data, null, 3),
      JSON.stringify(sender.data, null, 3),
      //JSON.stringify(objPDF, null, 3),
      isAllQuestionsAnswered,
      isMarkAsCompletedClicked
    );
    sender.clear(false, true);
    survey.data = sender.data;
    setAnswerJSON(sender.data);
  });

  const CheckAllQuestionsAnswered = (sender) => {
    if (isMarkAsCompletedClicked) {
      return true;
    }
    let answered = false;
    answered =
      sender.getAllQuestions().filter((item) => {
        return !item.isAnswered;
      }).length > 0
        ? false
        : true;
    return answered;
  };

  return (
    <div className="h-100 overflow-hidden overflow-y-auto">
      <Survey model={survey} />
    </div>
  );
};

export default LoadSurveyForm;
