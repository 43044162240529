import React, { useState } from "react";
// import SignupView from "./Signup.view";
import { Link } from "react-router-dom";
import Logo from "../../assets/ypp-logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import * as utils from "../../utils";
import Validator from "validator";

export default function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFirstNameChange = (value) => {
    setFirstName(value);
    setErrorMessage(null);
  };

  const handleLastNameChange = (value) => {
    setLastName(value);
    setErrorMessage(null);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    setErrorMessage(null);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setErrorMessage(null);
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setErrorMessage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (firstName.length === 0) {
      setErrorMessage("firstNameError");
      return false;
    } else if (lastName.length === 0) {
      setErrorMessage("lastNameError");
      return false;
    } else if (email.length === 0) {
      setErrorMessage("emailError");
      return false;
    } else if (!Validator.isEmail(email)) {
      setErrorMessage("emailFormatError");
      return false;
    } else if (password.length === 0) {
      setErrorMessage("passwordError");
      return false;
    } else if (confirmPassword.length === 0) {
      setErrorMessage("confirmPasswordError");
      return false;
    } else if (password !== confirmPassword) {
      setErrorMessage("passwordMatchError");
      return false;
    }
  };

  return (
    <div className="grid h-screen place-items-center">
      <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg w-full pb-4">
        <div className="flex justify-center text-center py-1 bg-blue-900 ">
          <img src={Logo} className="h-15 w-5" alt="logo" />
          <h3 className="text-2xl text-white font-semibold mt-1 px-2">
            Huddle
          </h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="px-6 py-2 mt-4">
            <input
              type="text"
              value={firstName}
              onChange={(e) => handleFirstNameChange(e.target.value)}
              id="firstName"
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                errorMessage && errorMessage === "firstNameError"
                  ? "border-red-400"
                  : "border-grey-300"
              }`}
              placeholder="FirstName *"
            />
            {errorMessage === "firstNameError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the First Name
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2 mt-4">
            <input
              type="text"
              value={lastName}
              onChange={(e) => handleLastNameChange(e.target.value)}
              id="lastName"
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                errorMessage && errorMessage === "lastNameError"
                  ? "border-red-400"
                  : "border-grey-300"
              }`}
              placeholder="LastName *"
            />
            {errorMessage === "lastNameError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the Last Name
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2 mt-4">
            <input
              type="text"
              id="Email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                errorMessage && errorMessage === "emailError"
                  ? "border-red-400"
                  : errorMessage === "emailFormatError"
                  ? "border-red-400"
                  : "border-grey-300"
              }`}
              placeholder="Email *"
            />
            {errorMessage === "emailFormatError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the valid Mail
                </span>
              </div>
            )}
            {errorMessage === "emailError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the Mail
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                errorMessage && errorMessage === "passwordError"
                  ? "border-red-400"
                  : "border-grey-300"
              }`}
              onBlur={() => {
                if (password.length < 5) {
                  setErrorMessage("passwordLengthError");
                } else {
                  setErrorMessage(null);
                }
              }}
              placeholder="Create Password *"
            />
            {errorMessage === "passwordError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the Password
                </span>
              </div>
            )}
            {errorMessage === "passwordLengthError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Use 5 characters or more for your password
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2">
            <div
              className={`border h-3 rounded-lg ${
                password.length <= 6
                  ? "bg-red-700 "
                  : password.length <= 8
                  ? "bg-orange-300"
                  : "bg-green-600"
              } w-full`}
            >
              {" "}
            </div>
          </div>
          <div className="px-6 py-2">
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => handleConfirmPasswordChange(e.target.value)}
              className={`h-10 w-full border focus:outline-none p-2 text-gray-900 rounded ${
                errorMessage && errorMessage === "confirmPasswordError"
                  ? "border-red-400"
                  : "border-grey-300"
              }w-`}
              onBlur={() => {
                if (
                  confirmPassword.length !== 0 &&
                  password !== confirmPassword
                ) {
                  setErrorMessage("passwordMatchError");
                } else {
                  setErrorMessage(null);
                }
              }}
              placeholder="Confirm Password *"
            />
            {errorMessage === "confirmPasswordError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Please enter the Confirm Password
                </span>
              </div>
            )}
            {errorMessage === "passwordMatchError" && (
              <div className="text-start pl-1">
                <span className="text-red-500 text-sm">
                  Password didn't match. Try again
                </span>
              </div>
            )}
          </div>
          <div className="px-6 py-2">
            <ReCAPTCHA
              // ref={recaptchaRef}
              sitekey={"6LcayJ4fAAAAAAlvtLLmlWXu45HmxnAUcEQzqWfB"}
              // onChange={onChange}
              size="normal"
            />
          </div>
          <div className="px-6 py-2">
            <button
              type="submit"
              className="focus:outline-none w-full text-white bg-red-700 hover:bg-red-800 font-medium rounded px-5 py-2.5 mr-2 mb-2 "
            >
              {utils.string.t("signup.signupLabel")}
            </button>
          </div>
        </form>
        <div className="text-center mb-4 px-6">
          <p className="text-base text-blue-900 dark:text-white ">
            {utils.string.t("signup.accountLabel")}{" "}
            <Link to="/login">{utils.string.t("signup.signinText")}</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
