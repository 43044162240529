import React, { useEffect, useState, useContext } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { AutoComplete, Table, Modal } from "../../sharedComponent";
import * as utils from "../../utils";
import Loader from "../../sharedComponent/Loader/Loader";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import MethodAndVariable from "../../utils/config/variableConstant";
import { Multiselect } from "multiselect-react-dropdown";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination/Pagination";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import Select from "react-select";
import { AppContext } from "../../store/context";
import Helper from "../../utils/config/helper";

const CreateComponent = () => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#0D47A1",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFFFFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#0D47A1",
        color: "white",
      },
    }),
  };
  const { userDetails, userCategories, dispatchCategories } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [componentList, setComponentList] = useState([]);
  const [componentListValue, setComponentListValue] = useState([]);
  const [componentInputValue, setComponentInputValue] = useState("");
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [categoryTagsList, setCategoryTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [categoryTagsValue, setCategoryTagsValue] = useState([]);
  const [qaSelectorsList, setQaSelectorsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedQaSelectors, setSelectedQaSelectors] = useState([]);
  //const [qaSelectorInputValue, setQaSelectorInputValue] = useState("");
  const [qaSelectorsvalue, setQaSelectorValue] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    componentNameError: false,
    componenetTagError: false,
    categoryTagError: false,
    qaSelectorError: false,
    programTypeError: false,
  });
  const [isCloned, setIsCloned] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [searchText, setSearchText] = useState("");
  const [statusCheck, setStatusCheck] = useState(true);
  const [isActive, setIsActive] = useState(true);
  // const [tableData, setTableData] = useState([]);
  const [componentDataList, setComponentDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(Helper.getStorage("pageSize"));
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [showTagModal, setShowTagModal] = useState(false);
  const [selectedListOfTags, setSelectedListOfTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [componentObject, setComponentObject] = useState({});
  const [componentSelectedForEdit, setComponentSelectedForEdit] =
    useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isCreateComponent, setIsCreateComponent] = useState(false);
  const [sortStatus, setSortStatus] = useState("normal");
  const [sortStatusText, setSortStatusText] = useState("");
  const [programCategory, setProgramCategory] = useState([]);
  const [programType, setProgramType] = useState([]);
  const [selectedProgramCategory, setSelectedProgramCategory] = useState(null);
  const [selectedProgramType, setSelectedProgramType] = useState(null);
  const [value, setValue] = useState([]);
  const [disableProgramType, setDisableProgramType] = useState(false);
  const [disableCategory, setDisableCategory] = useState(false);
  const [showComponentTag, setShowComponentTag] = useState(false);

  const column = [
    { heading: "SR.NO", value: "checkbox", width: "w-11" },
    { heading: "COMPONENT NAME", value: "componentName", width: "w-60" },
    //{ heading: "COMPONENT TAGS", value: "tagList", width: "w-60" },
    { heading: "PROGRAM CATEGORY", value: "programCategory", width: "w-60" },
    { heading: "PROGRAM TYPE", value: "programType", width: "w-60" },
    { heading: "ACTIONS", value: "action", width: "w-20", noSorting: true },
  ];

  useEffect(() => {
    if (userDetails?.email !== "") {
      getProgramCategory();
      getTagsList();
    }
  }, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getComponentList(
      searchText.length > 0 ? searchText : null,
      statusCheck,
      currentPage * 10,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
  }, [currentPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (componentDataList.length > 0) {
      setTotalCount(componentDataList[0].totalCount.value);
    }
  }, [componentDataList]);

  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const navigate = useNavigate();

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleOpenTagList = (tags) => {
    setShowTagModal(true);
    setSelectedListOfTags(tags);
  };

  const handleStatusCheckChange = (value) => {
    setStatusCheck(value);
    getComponentList(
      searchText.length > 0 ? searchText : null,
      value,
      0,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
  };

  const onClickSelectAll = (e, isAllSelected) => {
    e.stopPropagation();
    const rowData = componentDataList?.map((ele) => {
      if (isAllSelected) {
        return { ...ele, ...{ isSelected: { value: false } } };
      } else {
        return { ...ele, ...{ isSelected: { value: true } } };
      }
    });
    setComponentDataList(rowData);
  };

  const onSelectTableRow = (e, object) => {
    e.stopPropagation();
    const SelectedData = componentDataList?.map((ele) => {
      if (ele.componentID === object.componentID) {
        return { ...ele, ...{ isSelected: { value: !ele?.isSelected.value } } };
      } else {
        return ele;
      }
    });
    setComponentDataList(SelectedData);
  };

  const handleSearchComponentList = () => {
    if (searchText.length > 0) {
      setCurrentPage(0);
      getComponentList(
        searchText,
        statusCheck,
        0,
        pageSize,
        sortStatusText,
        sortStatus === "ascending" ? "ASC" : "DESC"
      );
    }
  };

  const handleClickEdit = (item) => {
    setShowModal(true);
    setModalMessage({
      title: "Edit",
      message: "Do you want to Edit this Component ?",
    });
    setComponentSelectedForEdit(item);
  };

  const handleEditConfirm = () => {
    setEditMode(true);
    setIsCreateComponent(true);
    setShowModal(false);
    if (componentSelectedForEdit.hasOwnProperty("componentID")) {
      setComponentName(
        componentSelectedForEdit.hasOwnProperty("componentName")
          ? componentSelectedForEdit["componentName"]?.value
          : ""
      );
      let updatedComponentTags = [];
      if (componentSelectedForEdit.tagList.value) {
        const tagList = componentSelectedForEdit.tagList.value.split(",");
        for (let i = 0; i < tagList.length; i++) {
          updatedComponentTags.push(
            componentList.filter(
              (item) => item.label === tagList[i].trimLeft()
            )[0]
          );
        }
        setSelectedTags(updatedComponentTags);
      }
      setSelectedComponent(updatedComponentTags);
      setComponentListValue(updatedComponentTags);
      setIsActive(componentSelectedForEdit?.isActive?.value);
      let updatedCategoryTags = [];

      if (componentSelectedForEdit.categoryTagList.value) {
        const tagList =
          componentSelectedForEdit.categoryTagList.value.split(",");
        for (let i = 0; i < tagList.length; i++) {
          updatedCategoryTags.push(
            categoryTagsList.filter(
              (item) => item.label === tagList[i].trimLeft()
            )[0]
          );
        }
      }

      GetQuestionsByTagIds(updatedCategoryTags);
      setCategoryTagsValue(updatedCategoryTags);
      setSelectedTags([...updatedCategoryTags, ...updatedComponentTags]);

      //Program Category and Type
      if (
        componentSelectedForEdit.programCategory.value !== null &&
        componentSelectedForEdit.programCategory.value !== ""
      ) {
        programCategory.forEach((item) => {
          if (item.label === componentSelectedForEdit.programCategory.value) {
            setSelectedProgramCategory({
              value: item.value,
              label: item.label,
            });
            getProgramType();
          }
        });
      } else {
        setSelectedProgramCategory(null);
        setSelectedProgramType(null);
      }
    }
  };

  const handleChangeStatus = () => {
    let componentIds = [];
    let status;
    componentDataList.forEach((row) => {
      if (row.isSelected.value === true) {
        status = row.isActive.value;
        componentIds.push(row.componentID.value);
      }
    });

    let messageString = "";
    if (componentIds.length === 1) {
      messageString = `Do you want to change status of selected Component to ${
        status ? "Inactive" : "Active"
      } ?`;
    } else {
      messageString = `Do you want to change status of  All Components to ${
        status ? "Inactive" : "Active"
      }?`;
    }
    if (componentIds.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Change Status",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one Component",
      });
    }
  };

  const handleSort = (name) => {
    if (sortStatus === "normal" || sortStatusText !== name) {
      setSortStatus("ascending");
      setSortStatusText(name);
    } else if (sortStatus === "ascending") {
      setSortStatus("descending");
      setSortStatusText(name);
    } else if (sortStatus === "descending") {
      setSortStatus("ascending");
      setSortStatusText(name);
    }
  };
  useEffect(() => {
    if (sortStatus && sortStatusText) {
      setSortConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus, sortStatusText]);

  const setSortConfig = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    getComponentList(
      searchText.length > 0 ? searchText : null,
      statusCheck,
      0,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    // let sortableItems = [...componentDataList];

    // if (sortStatus === "ascending" || sortStatus === "descending") {
    //   if (sortStatusText === "COMPONENT NAME") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.componentName.value.toLowerCase() <
    //         b.componentName.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.componentName.value.toLowerCase() >
    //         b.componentName.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setComponentDataList(dataSorted);
    //   }

    //   if (sortStatusText === "COMPONENT TAGS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.tagList.value.toLowerCase() < b.tagList.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.tagList.value.toLowerCase() > b.tagList.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setComponentDataList(dataSorted);
    //   }
    // }
  };

  const handleChangeStatusConfirm = () => {
    setShowModal(false);
    let componentIds = [];
    let status;
    componentDataList.forEach((row) => {
      if (row.isSelected.value === true) {
        status = row.isActive.value;
        componentIds.push(row.componentID.value);
      }
    });
    if (componentIds.length > 0) {
      changeStatus(componentIds.join(","), !status);
    }
  };

  const handleMultipleDelete = () => {
    let componentIds = [];
    componentDataList.forEach((row) => {
      if (row.isSelected.value === true) {
        componentIds.push(row.componentID.value);
      }
    });

    let messageString = "";
    if (componentIds.length === 1) {
      messageString = "Do you want to Delete selected Component ?";
    } else {
      messageString = "Do you want to Delete All Components ?";
    }
    if (componentIds.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Multiple Delete",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select the component",
      });
    }
  };

  const handleMultipleDeleteConfirm = () => {
    setShowModal(false);
    let componentIds = [];
    componentDataList.forEach((row) => {
      if (row.isSelected.value === true) {
        componentIds.push(row.componentID.value);
      }
    });
    if (componentIds?.length > 0) {
      deleteComponent(componentIds.join(","));
    }
  };

  const handleDeletConfirm = () => {
    setShowModal(false);
    if (componentObject?.componentID?.value) {
      deleteComponent(JSON.stringify(componentObject?.componentID.value));
    }
  };

  const handleDeleteComponent = (item) => {
    setShowModal(true);
    setComponentObject(item);
    setModalMessage({
      title: "Delete",
      message: "Do you want to Delete this Component ?",
    });
  };

  const deleteComponent = (componentIds) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.DELETE_Component_LIST, {
      ComponentIDList: componentIds,
      IsDeleted: true,
      ModifiedBy: 0,
      recordCount: 0,
      rowCount: 0,
      searchText: "",
      status: true,
    })
      .then((res) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Component Deleted successfully",
        });
        getComponentList(
          null,
          statusCheck,
          0,
          pageSize,
          sortStatusText,
          sortStatus === "ascending" ? "ASC" : "DESC"
        );
      })
      .catch((err) => {
        console.log(err);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error While Deleting a Component!",
        });
        setLoading(false);
      });
  };

  const changeStatus = (componentIds, status) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.CHANGE_COMPONENT_STATUS, {
      ComponentIDList: componentIds,
      Status: status,
      ModifiedBy: 0,
    })
      .then((res) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Component Status changed successfully",
        });
        getComponentList(
          null,
          statusCheck,
          0,
          pageSize,
          sortStatusText,
          sortStatus === "ascending" ? "ASC" : "DESC"
        );
      })
      .catch((err) => {
        console.log(err);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error While changing the status of  Component!",
        });
        setLoading(false);
      });
  };

  const resetFilter = () => {
    setSearchText("");
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    getComponentList(
      null,
      statusCheck,
      0,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );

    setStatusCheck(true);
  };

  useEffect(() => {
    getProgramType();
    if (selectedProgramCategory !== null) {
      setDisableCategory(true);
      // let updatedCategory = [];
      // categoryTags &&
      //   categoryTags.map((item) =>
      //     item.tagID === 2 ? updatedCategory.push(item) : null
      //   );
      // setSelectedTags(updatedCategory);
    } else {
      setDisableCategory(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgramCategory]);

  const getCompoentList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_COMPONENTS, { Type: "Component" })
      .then((res) => {
        if (res && res.length > 0) {
          const updatedTags = res.map((item) => {
            return {
              label: item.tagName,
              value: item.tagName,
              ...item,
            };
          });
          if (updatedTags && updatedTags.length > 0) {
            //setTags([...tags, ...data])
            setComponentList([...updatedTags]);
          }
          setCanPreviousPage(false);
          setCanNextPage(true);
        } else {
          setCurrentPage(0);
          setCanPreviousPage(false);
          setCanNextPage(false);
          setTotalCount(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getTagsList = () => {
    //TODO: Need to remove Hard- code Type, instead we can use Enum.
    APIHelper.post(APIURLConstant.GET_TAGS_BY_TYPE_NAME, {
      Type: MethodAndVariable.VariableConstant.category,
    })
      .then((res) => {
        if (res) {
          const updatedTags = res.map((item) => {
            return {
              label: item.tagName,
              value: item.tagName,
              ...item,
            };
          });
          if (updatedTags && updatedTags.length > 0) {
            setCategoryTagsList([...updatedTags]);
          }
        }
      })
      .catch((err) => {});
  };

  const getComponentList = (
    searchTextValue,
    statusValue,
    recordCount,
    rowCount = pageSize,
    sortBy,
    sortDirection
  ) => {
    setLoading(true);
    if (!loading)
      APIHelper.post(APIURLConstant.GET_COMPONENT_LIST, {
        SearchText: searchTextValue,
        RecordCount: recordCount,
        rowCount: rowCount,
        Status: statusValue,
        sortBy: sortBy === null ? "null" : sortBy,
        sortDirection: sortDirection === null ? "null" : sortDirection,
      })
        .then((res) => {
          let dataList = [];
          if (res?.length > 0) {
            res.forEach((item) => {
              dataList.push({
                componentID: {
                  value: item.componentID,
                },
                componentName: {
                  value: item.componentName,
                },
                tagList: {
                  value: item.componentTagList,
                },
                isSelected: {
                  value: false,
                },
                totalCount: {
                  value: item.totalCount,
                },
                isActive: {
                  value: item.isActive,
                },
                questionList: {
                  value: item.questionList,
                },
                categoryTagList: {
                  value: item.categoryTagList,
                },
                createdDate: {
                  value: item.createdDate,
                },
                programCategory: {
                  value: item.programCategory,
                },
                programType: {
                  value: item.programType,
                },
              });
            });
          }
          setComponentDataList(dataList);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
  };

  const handleChangeComponentName = (value) => {
    if (value.length === 0) {
      errorMessage.componentNameError = true;
      setComponentName(value);
    } else {
      errorMessage.componentNameError = false;
      setComponentName(value);
    }
    setComponentName(value);
  };
  const handleComponentOptions = (value) => {
    setSelectedComponent(value);
    errorMessage.componenetTagError = false;
  };

  const handleChangeCategoryTag = (value) => {
    //setSelectedTags(value);
    setSelectedTags(value);
    GetQuestionsByTagIds(value);
    setSelectedQaSelectors([]);
    setQaSelectorValue([]);
    errorMessage.categoryTagError = false;
  };
  const handleChangeQaSelector = (value) => {
    setSelectedQaSelectors(value);
    setQaSelectorValue(value);
    errorMessage.qaSelectorError = false;
  };

  const GetQuestionsByTagIds = (tags) => {
    setLoading(true);
    let ids = [];
    if (tags != null && tags !== undefined) {
      tags.forEach((item) => {
        ids.push(item.tagID);
      });
      APIHelper.post(APIURLConstant.GET_QUESTIONS_BY_TAG_IDS, {
        TagIds: ids.join(","),
      })
        .then((res) => {
          const updatedQaSelectors = res.map((item) => {
            return {
              label: item.questionText,
              value: item.questionText,
              ...item,
            };
          });
          if (updatedQaSelectors && updatedQaSelectors.length > 0) {
            let updatedQuestions = [];
            console.log(updatedQaSelectors);
            setQaSelectorsList(updatedQaSelectors);
            if (
              componentSelectedForEdit.hasOwnProperty("questionList") &&
              componentSelectedForEdit.questionList.value
            ) {
              const tagList =
                componentSelectedForEdit.questionList.value.split(",");
              for (let i = 0; i < tagList.length; i++) {
                updatedQuestions.push(
                  updatedQaSelectors.filter(
                    (item) => item.label === tagList[i].trimLeft()
                  )[0]
                );
              }
              updatedQuestions = updatedQuestions.filter((element) => {
                return element !== undefined;
              });
              console.log(updatedQuestions);
              setQaSelectorValue(updatedQuestions);
            }
          } else {
            setQaSelectorsList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const isValidateForm = () => {
    let isFormValid = true;
    if (componentName?.trim()?.length === 0) {
      errorMessage.componentNameError = true;
      isFormValid = false;
    }
    // if (componentListValue.length === 0) {
    //   errorMessage.componenetTagError = true;
    //   isFormValid = false;
    // }
    if (categoryTagsValue.length === 0) {
      errorMessage.categoryTagError = true;
      isFormValid = false;
    }
    if (qaSelectorsvalue.length === 0) {
      errorMessage.qaSelectorError = true;
      isFormValid = false;
    }
    if (selectedProgramCategory !== null && selectedProgramType === null) {
      errorMessage.programTypeError = true;
      isFormValid = false;
    }
    return isFormValid;
  };
  const handleClone = () => {
    setShowModal(true);
    setModalMessage({
      title: "Clone",
      message: "Do you want to Clone this Component ?",
    });
  };

  const handleCloneConfirm = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    if (componentSelectedForEdit !== null) {
      componentSelectedForEdit.componentID.value = 0;
    }
    setIsCloned(true);
    //
    setToasterMessage({
      showToaster: true,
      errorType: "success",
      message: "Question cloned successfully",
    });
  };

  const handleSubmit = () => {
    if (isValidateForm()) {
      setLoading(true);
      saveComponent();
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter the required fields",
      });
    }
  };
  const saveComponent = () => {
    setLoading(true);
    const componentObj = {
      componentID: editMode ? componentSelectedForEdit?.componentID?.value : 0,
      componentName: componentName,
      createdBy: 0,
      createdDate: editMode
        ? componentSelectedForEdit?.createdDate?.value
        : new Date(),
      modifiedBy: 0,
      modifiedDate: new Date(),
      isActive: isActive,
      programTypeID:
        selectedProgramType !== null ? selectedProgramType.value : 0,
      programCategoryID:
        selectedProgramCategory !== null ? selectedProgramCategory.value : 0,
    };

    APIHelper.post(APIURLConstant.CREATE_COMPONENT, componentObj)
      .then((data) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: isCloned
            ? "Component created Successfully"
            : editMode
            ? `Component updated Successfully`
            : `Component created Successfully`,
        });
        //setLoading(false);
        setIsCreateComponent(false);

        //call API to store tags and questions details
        //first we need to update ComponentID
        let componentsWithTags = {
          components: [],
        };
        let allSelectedTags = [...selectedTags];
        allSelectedTags.forEach((tag) => {
          if (tag && tag.tagID) {
            let config = {};
            config["componentId"] = data;
            config["tagId"] = tag.tagID;
            componentsWithTags.components.push({ ...config });
          }
        });
        if (
          componentsWithTags.components &&
          componentsWithTags.components.length > 0
        ) {
          //call API here...
          setLoading(true);
          APIHelper.post(
            APIURLConstant.CREATE_COMPONENT_WITH_TAGS,
            componentsWithTags
          )
            .then((data) => {
              setToasterMessage({
                showToaster: true,
                errorType: "success",
                message: `Component Updated Successfully With Tags details`,
              });
              setLoading(false);
            })
            .catch((err) => {
              setToasterMessage({
                showToaster: true,
                errorType: "error",
                message: `Failed to Update Component With Tags details`,
              });
              setLoading(false);
            });
        }
        //Update components with Questions
        let componentsWithQuestions = {
          components: [],
        };
        qaSelectorsvalue.forEach((questio) => {
          let config = {};
          config["componentId"] = data;
          config["questionId"] = questio.questionID;
          componentsWithQuestions.components.push({ ...config });
        });
        if (
          componentsWithQuestions.components &&
          componentsWithQuestions.components.length > 0
        ) {
          //call API here...
          setLoading(true);
          APIHelper.post(
            APIURLConstant.CREATE_COMPONENT_WITH_QUESTIONS,
            componentsWithQuestions
          )
            .then((data) => {
              setToasterMessage({
                showToaster: true,
                errorType: "success",
                message: `Component Updated Successfully With Question details`,
              });
              //setLoading(false);
              getComponentList(
                null,
                statusCheck,
                0,
                pageSize,
                sortStatusText,
                sortStatus === "ascending" ? "ASC" : "DESC"
              );
            })
            .catch((err) => {
              setToasterMessage({
                showToaster: true,
                errorType: "error",
                message: `Failed to Update Component With Question details`,
              });
              setLoading(false);
            });
        }
        getCompoentList();
        resetForm();
      })
      .catch((err) => {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to Create Program`,
        });
        setLoading(false);
      });
  };

  const resetForm = () => {
    setEditMode(false);
    setIsCloned(false);
    setComponentName("");
    setComponentListValue([]);
    setCategoryTagsValue([]);
    setQaSelectorValue([]);
    setErrorMessage({
      componentNameError: false,
      componenetTagError: false,
      categoryTagError: false,
      qaSelectorError: false,
      programTypeError: false,
    });
    setComponentSelectedForEdit(null);
    setSelectedTags([]);
    setTagInputValue("");
    setCategoryTagsValue([]);
    setQaSelectorsList([]);
    setSelectedQaSelectors([]);
    setComponentObject({});
    setSelectedProgramCategory(null);
    setSelectedProgramType(null);
  };

  const handleChangeProgramCategory = (value) => {
    setSelectedProgramCategory(value);
    // setSelectedTags([]);
    setValue([]);
  };

  const handleChangeProgramType = (value) => {
    errorMessage.programTypeError = false;
    errorMessage.categoryTagError = false;

    setSelectedProgramType(value);
  };

  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: parseInt(userDetails?.roleIDList),
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Categories`,
          });
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const getProgramType = () => {
    setProgramType(null);
    setSelectedProgramType(null);
    if (selectedProgramCategory && selectedProgramCategory.value) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAMTYPE, {
        ProgramCategoryID: selectedProgramCategory.value,
      })
        .then((res) => {
          if (res) {
            const programType = res.map((item) => {
              return {
                value: item.programTypeID,
                label: item.type,
              };
            });
            if (programType && programType.length > 0) {
              setProgramType([...programType]);
              setDisableProgramType(false);

              if (componentSelectedForEdit !== null) {
                const typeValue = programType.filter((type) => {
                  return (
                    type.label === componentSelectedForEdit.programType.value
                  );
                });
                setSelectedProgramType(
                  typeValue && typeValue.length > 0 ? typeValue[0] : null
                );
              }

              setLoading(false);
            } else {
              setLoading(false);
              setDisableProgramType(true);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableProgramType(false);

          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Types`,
          });
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Loader showLoading={loading} />
      <div className="mt-9">
        <div className="mx-auto md:p-7 p-2">
          {isCreateComponent ? (
            <div>
              <div className="flex justify-between py-3">
                <p className="text-[#c8102e] font-semibold text-[19px] ">
                  {componentSelectedForEdit === null
                    ? "Create Component"
                    : "Edit Component"}
                </p>
              </div>
              <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4 ">
                <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                  <p className="text-sm mt-2 required pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-3/12 lg:w-2/12 w-full font-bold">
                    Component Name :
                  </p>
                  <div className="w-full lg:w-10/12 md:w-9/12">
                    <TextareaAutosize
                      type="text"
                      id="componentName"
                      value={componentName}
                      maxRows={2}
                      onChange={(e) =>
                        handleChangeComponentName(e.target.value)
                      }
                      placeholder="Enter the component name here"
                      className={`component-input resize-none textAreaHeight pt-1 w-full border focus:outline-none focus:shadow-outline
                ${
                  errorMessage && errorMessage.componentNameError === true
                    ? "border-red-400"
                    : "border-grey-400"
                } text-gray-900 rounded pl-2`}
                    />
                    {errorMessage &&
                      errorMessage.componentNameError === true && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please enter the Component Name
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="flex-none lg:flex  w-full">
                  <div className="flex-none md:flex w-full lg:w-3/6 py-4 ">
                    <p className="text-sm mt-2 pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-3/12 lg:w-4/12 w-full font-bold">
                      Program Category:
                    </p>
                    <div className="w-full lg:w-6/12 md:w-9/12 ">
                      <Select
                        isClearable
                        value={selectedProgramCategory}
                        options={programCategory}
                        className="w-full ml-0 lg:ml-4 focus:outline-none focus:shadow-outline text-gray-900 rounded"
                        placeholder="Select Program Category"
                        onChange={(e) => handleChangeProgramCategory(e)}
                      />
                    </div>
                  </div>
                  {selectedProgramCategory && selectedProgramCategory.value && (
                    <div className="flex-none md:flex w-full lg:w-3/6 py-4">
                      <p className="text-sm mt-2 pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-3/12 lg:w-4/12 w-full font-bold">
                        Program Type:
                      </p>
                      <div className="w-full lg:w-6/12 md:w-9/12 ">
                        <Select
                          value={selectedProgramType}
                          options={programType}
                          isDisabled={disableProgramType}
                          className={` w-full ml-0 lg:ml-4 focus:outline-none focus:shadow-outline${
                            errorMessage.programTypeError === true
                              ? "border border-red-400"
                              : ""
                          } text-gray-900 rounded`}
                          placeholder="Select Program Type"
                          onChange={(e) => {
                            handleChangeProgramType(e);
                          }}
                        />
                        {errorMessage.programTypeError && (
                          <span className="text-red-500 text-sm">
                            Please select the program type
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {showComponentTag && (
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <p className="text-sm required mt-2 pb-4 lg:p-0 md:p-0 lg:text-end text-center text-[#394150] md:w-4/12 lg:w-2/12  w-full font-bold">
                      Component Tags :
                    </p>
                    <div className="w-full lg:w-3/12 ">
                      <AutoComplete
                        className={` ${
                          errorMessage.componenetTagError === true
                            ? "border border-red-400"
                            : ""
                        } rounded`}
                        isCloseOutsideClick={true}
                        tagSuggestion={componentList}
                        createOption={createOption}
                        inputValue={componentInputValue}
                        setInputValue={setComponentInputValue}
                        value={componentListValue}
                        setValue={setComponentListValue}
                        onChange={handleComponentOptions}
                        placeholder={"Please enter component tag name."}
                        styles={colourStyles}
                      />
                      {errorMessage.componenetTagError === true && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please select component tag
                          </span>
                        </div>
                      )}
                    </div>
                    {/* {editMode && (
                    <div className="inline-flex items-center">
                      <span className=" text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 mx-2 font-bold">
                        {isActive ? "Active" : "InActive"}
                      </span>
                      <label className="inline-flex relative items-center  w-11">
                        <input
                          type="checkbox"
                          checked={isActive}
                          value={isActive}
                          className="sr-only peer cursor-pointer"
                          onChange={(e) => setIsActive(e.target.checked)}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  )} */}
                  </div>
                )}
                <div className="flex-none lg:flex md:flex-none w-full ">
                  <div className="w-full lg:w-6/12 flex-none md:flex lg:space-x-4 py-4 ">
                    <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                      Category Tags :
                    </p>
                    <div className="w-full lg:w-3/6 ">
                      <AutoComplete
                        className={` ${
                          errorMessage.categoryTagError === true
                            ? "border border-red-400"
                            : ""
                        } rounded`}
                        tagSuggestion={categoryTagsList}
                        createOption={createOption}
                        inputValue={tagInputValue}
                        isCratable={false}
                        setInputValue={setTagInputValue}
                        value={categoryTagsValue}
                        setValue={setCategoryTagsValue}
                        onChange={handleChangeCategoryTag}
                        styles={colourStyles}
                        placeholder={"Please Type category tag name."}
                      />
                      {errorMessage.categoryTagError === true && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please select category tag
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 flex-none md:flex lg:space-x-4 py-4 ">
                    <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                      Q&A Selector :
                    </p>
                    <div className="w-full lg:w-3/6">
                      <Multiselect
                        displayValue="questionText"
                        showCheckbox={true}
                        onSelect={handleChangeQaSelector}
                        options={qaSelectorsList}
                        selectedValues={qaSelectorsvalue}
                        placeholder={"Select Q&A..."}
                        className={` ${
                          errorMessage.qaSelectorError === true
                            ? "border border-red-400"
                            : ""
                        } rounded`}
                        onRemove={(event) => {
                          setQaSelectorValue(event);
                        }}
                      />
                      {errorMessage.qaSelectorError === true && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please select Question and answer
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-none lg:flex md:flex lg:space-x-4">
                  <div className="mt-2 pb-4 md:w-4/12 lg:w-2/12  w-full font-bold" />
                  <div className="w-full lg:w-3/6">
                    {editMode && (
                      <div className="inline-flex items-start lg:items-center">
                        <span className=" text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 mx-2 font-bold">
                          {isActive ? "Active" : "InActive"}
                        </span>
                        <label className="inline-flex relative items-center  w-11">
                          <input
                            type="checkbox"
                            checked={isActive}
                            value={isActive}
                            className="sr-only peer cursor-pointer"
                            onChange={(e) => setIsActive(e.target.checked)}
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none md:flex lg:space-x-4 py-4">
                  <div className="mt-2 pb-4 md:w-3/12 lg:w-2/12  w-full font-bold" />
                  <ButtonContainer
                    handleSubmit={() => handleSubmit()}
                    ShowClone={editMode}
                    disableCloneBtn={isCloned}
                    handleClone={() => handleClone()}
                    handleCancel={() => {
                      resetForm();
                      setIsCreateComponent(false);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="flex justify-between ">
                <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                  Component List
                </p>
              </div>
              <div className="grid grid-cols-1  lg:grid-cols-2 gap-2 pb-4">
                <div className="flex gap-2">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full h-10 focus:outline-none focus:shadow-outline border px-2 border-grey-300 rounded "
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && searchText?.length > 0) {
                        handleSearchComponentList();
                      }
                    }}
                  />
                  <div className="flex flex-wrap gap-3 ">
                    <button
                      disabled={!searchText}
                      className={`p-1 px-[1rem] h-[2.2rem] mt-[0.15rem] rounded-[3px] text-white text-sm
                      ${searchText ? "bg-blue-900" : "bg-gray-400"}
                    `}
                      onClick={() => {
                        handleSearchComponentList();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="justify-between items-center flex flex-wrap ">
                  <div className="flex flex-wrap gap-4">
                    <button
                      disabled={searchText.length === 0}
                      className={
                        searchText.length > 0
                          ? "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                          : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      }
                      onClick={() => {
                        if (searchText.length > 0) {
                          resetFilter();
                        }
                      }}
                    >
                      Reset
                    </button>

                    <div className="inline-flex items-center">
                      <label className="inline-flex relative items-center  w-11">
                        <input
                          type="checkbox"
                          checked={statusCheck}
                          value={statusCheck}
                          className="sr-only peer cursor-pointer"
                          onChange={(e) => {
                            handleStatusCheckChange(e.target.checked);
                          }}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                      <span className="mx-2">
                        {statusCheck ? "Active" : "InActive"}
                      </span>
                    </div>
                  </div>

                  <div className="justify-between flex flex-wrap gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        handleChangeStatus();
                      }}
                      className="bg-blue-900 p-1 px-[1rem] lg:h-[2.2rem] text-sm rounded-[3px] text-white"
                    >
                      Change Status
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleMultipleDelete();
                      }}
                      className="bg-blue-900 p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-white"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsCreateComponent(true)}
                      className="bg-blue-900 p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-white"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <Table
                data={componentDataList}
                column={column}
                isDeleteButton={true}
                handleClickEdit={handleClickEdit}
                handleDeleteUser={handleDeleteComponent}
                sortStatus={sortStatus}
                sortStatusText={sortStatusText}
                handleSort={handleSort}
                handleOpenTagList={handleOpenTagList}
                onClickSelectAll={onClickSelectAll}
                onSelectTableRow={onSelectTableRow}
                sourceName={"Component"}
              />
              <Pagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                totalCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                setCanNextPage={setCanNextPage}
                setCanPreviousPage={setCanPreviousPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
      {/* Modal Popup */}
      <Modal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Change Status"
            ? handleChangeStatusConfirm
            : modalMessage.title === "Edit"
            ? handleEditConfirm
            : modalMessage.title === "Multiple Delete"
            ? handleMultipleDeleteConfirm
            : modalMessage.title === "Clone"
            ? handleCloneConfirm
            : handleDeletConfirm
        }
      />

      {/* TagList Popup */}
      <Modal
        showModal={showTagModal}
        handleClose={() => setShowTagModal(false)}
        isTagList={true}
        data={selectedListOfTags}
        title={"Tag List"}
      />
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};

export default CreateComponent;
