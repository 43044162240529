import { useEffect, useState, useContext } from "react";
import Calender from "../../modules/calender/Calender";
import { AppContext } from "../../store/context";
import AccountExpired from "../../sharedComponent/AccessComponents/AccountExpired"

const Dashboard = () => {
  const { userDetails } = useContext(AppContext);

  let userName = userDetails.firstName ? userDetails.firstName : "";
  userName = userDetails.lastName
    ? userName + " " + userDetails.lastName
    : userName;
  userName = userName ? userName : userDetails.userName;

  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("accessToken");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  if (!authenticated) {
    return (
      <div>
        <p>You are not authenticated</p>
      </div>
    );
    // Redirect
  } else {
    return (
      <div>
        {userDetails?.isUserExpired == true && userDetails?.email != "" ? (
          <div className="mt-16">
            <AccountExpired />
          </div>
        ) : userDetails?.email != "" ? (
          <Calender />
        ) : null}
      </div>
    );
  }
};

export default Dashboard;
