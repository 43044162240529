import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./assets/ypp-logo-header.png";
import { AppContext } from "./store/context";
import MethodAndVariable from "./utils/config/variableConstant";
import Helper from "./utils/config/helper";
import "../src/utils/Common.css";
import ProgramsIcon from "./assets/prog_list.svg";
import CreateIcon from "./assets/edit.svg";
import ProgramTypeIcon from "./assets/prog_type.svg";
import ComponentsIcon from "./assets/component.svg";
import AddQAIcon from "./assets/add_QA.svg";
import ChangeUserIcon from "./assets/change_user.svg";
import UserIcon from "./assets/user_list.svg";
import Modules from "./assets/module.svg";
import HomeIcon from "./assets/header_home.svg";
import Notification from "./assets/notification-white.svg";
import Toaster from "./sharedComponent/Toaster/Toaster";
import { useMsal } from "@azure/msal-react";

import { useEffect } from "react";
import { useRef } from "react";
import useOutsideClick from "./sharedComponent/outSideClick/outSideClick";
import moment from "moment/moment";
import NotificationBell from "./pages/Notifications/NotificationBell";
import APIHelper from "./utils/config/apiHelper";
import APIURLConstant from "./utils/config/apiURLConstant";
import axios from "axios";

const HeaderComponent = (props) => {
  const {
    userDetails,
    dispatchUserEvent,
    dispatchRegions,
    dispatchCategories,
    dispatchUserRoles,
    dispatchProgramTypes,
  } = useContext(AppContext);
  const navigation = useNavigate();
  const notificationDiv = useRef();
  const mobileMenuDiv = useRef();
  const [showLogout, setShowLogout] = useState(false);
  const [showCreateProgram, setShowCreateProgram] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const { instance } = useMsal();

  // let logoutURL = `https://login.microsoftonline.com/e898ff4a-4b69-45ee-a3ae-1cd6f239feb2/oauth2/logout?post_logout_redirect_uri=`;
  // logoutURL = logoutURL + MethodAndVariable.getENVDetails().RETURN_URL;

  const handleImpersonateChange = (isReload) => {
    const user = { isImpersonate: false };
    Helper.setStorage("impersonateEmail", null);
    dispatchUserEvent("ADD_USER", user);
    dispatchRegions("ADD_REGIONS", []);
    dispatchCategories("ADD_CATEGORY", []);
    dispatchUserRoles("ADD_ROLES", []);
    dispatchProgramTypes("ADD_PROGRAM_TYPES", []);
    if (isReload) navigation("/");
  };

  useEffect(() => {
    if (
      (userDetails.roleIDList !== "" && userDetails.roleIDList === "7") ||
      userDetails.roleIDList === "8" ||
      userDetails.roleIDList === "9" ||
      userDetails.roleIDList === "10"
    ) {
      setShowCreateProgram(false);
    } else {
      setShowCreateProgram(true);
    }
  }, [userDetails]);

  const handleLogout = async () => {
    await axios
      .get(APIURLConstant.SIGN_OUT)
      .then(async (res) => {
        if (res.data) {
          // if ("serviceWorker" in navigator) {
          //   navigator.serviceWorker.ready
          //     .then((registration) => {
          //       registration.unregister();
          //     })
          //     .catch((error) => {
          //       console.error(error.message);
          //     });
          // }
          localStorage.clear();
          await instance.logoutRedirect();
          // localStorage.removeItem("accessToken");
          // props.setIsLoggedIn(false);
          // props.setShowLogin(true);
          // window.location.href = "/";
          // handleImpersonateChange();
          // authState.appDetails.signoutURL.value != undefined && authState.appDetails.signoutURL.value != ''
          //         ? authState.appDetails.signoutURL.value
          //         : '/';
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useOutsideClick(notificationDiv, () => {
    if (showNotification === true) {
      setShowNotification(false);
    }
  });
  useOutsideClick(mobileMenuDiv, () => {
    if (showMobileMenu === true) {
      setShowMobileMenu(false);
    }
  });
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const RenderMobileMenu = () => {
    return (
      <div ref={mobileMenuDiv} className=" w-full h-full bg-white">
        <div className="flex p-4 bg-blue-900">
          <div className="my-3 text-center w-11/12">
            <div
              className="relative inline-flex items-center justify-center w-8 h-8  
        bg-gray-100 rounded-full dark:bg-gray-600"
            >
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {MethodAndVariable.getInitials(
                  userDetails.firstName,
                  userDetails.lastName,
                  userDetails.userName
                )}
              </span>
              {userDetails.impersonateEmail ? (
                <span
                  data-te-offcanvas-dismiss
                  className="text-red-600 font-medium text-lg absolute z-[100] bottom-4 right-0 "
                  onClick={() => {
                    handleImpersonateChange(true);
                  }}
                >
                  X
                </span>
              ) : null}
            </div>

            <p className="text-xl text-[#FFFFFF] font-semibold mt-2">
              Hello!
              <br />
              {userDetails?.firstName !== ""
                ? userDetails.lastName + " " + userDetails.firstName
                : "User"}
            </p>
          </div>
          <div className="w-1/12">
            <button
              type="button"
              className="box-content rounded-none border-none"
              data-te-offcanvas-dismiss
            >
              <span className="w-[1em] disabled:pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6 text-[#FFFFFF]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="flex-grow overflow-y-auto">
          {userDetails && userDetails.roleIDList === "1" && (
            <div id="mobileProgramDropdown">
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                <h2 className="mb-0" id="program-heading">
                  <button
                    className="group relative flex px-5 py-4 w-full justify-between items-center border-0 transition [overflow-anchor:none] [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                    type="button"
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseProgram"
                    aria-expanded="false"
                    aria-controls="flush-collapseProgram"
                  >
                    <span className="text-blue-900 font-semibold">
                      Program Management
                    </span>
                    <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-400 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6 text-blue-900"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseProgram"
                  className="!visible hidden border-0"
                  data-te-collapse-item
                  aria-labelledby="program-heading"
                  data-te-parent="#mobileProgramDropdown"
                >
                  <div className="py-4 px-5">
                    {/* <Link
                    to={"/"}
                    data-te-offcanvas-dismiss
                    className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                  >
                    <img
                      src={CreateIcon}
                      alt="program_icon"
                      className="h-5 w-4"
                    />
                    <div className="text-md text-black font-semibold block text-sm">
                      Manage Program Creation
                    </div>
                  </Link> */}
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/create-component"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={ComponentsIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Manage Components
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/program-type"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={ProgramTypeIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Manage Program Types
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/add-qa"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={AddQAIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Manage Questions
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/modules"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={Modules}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Manage Modules
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/participant-formula"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-5 w-4 text-blue-900 "
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
                            clipRule="evenodd"
                          />
                        </svg>

                        <div className="text-md text-black font-semibold block text-sm">
                          Participant Formula
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {userDetails && userDetails.roleIDList === "1" && (
            <div id="mobileUserDropdown">
              <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                <h2 className="mb-0" id="user-heading">
                  <button
                    className="group relative flex px-5 py-4 w-full justify-between items-center border-0 transition [overflow-anchor:none] [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                    type="button"
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target="#flush-collapseUsers"
                    aria-expanded="false"
                    aria-controls="flush-collapseUsers"
                  >
                    <span className="text-blue-900 font-semibold">
                      User Management
                    </span>
                    <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-400 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6 text-blue-900"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseUsers"
                  className="!visible hidden border-0"
                  data-te-collapse-item
                  aria-labelledby="user-heading"
                  data-te-parent="#mobileUserDropdown"
                >
                  <div className="py-4 px-5">
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/user-list"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={UserIcon}
                          alt="program_icon"
                          className="h-4 w-5 mt-1"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          All Users
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/change-user"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={ChangeUserIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Impersonate User
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/user-directory"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={UserIcon}
                          alt="program_icon"
                          className="h-4 w-5 mt-1"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          User Directory
                        </div>
                      </Link>
                    </div>
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/notification-management"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-blue-900"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                          />
                        </svg>
                        <div className="text-md text-black font-semibold block text-sm">
                          Notification Management
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div id="mobileMyProgramDropdown">
            <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
              <h2 className="mb-0" id="user-heading">
                <button
                  className="group relative flex px-5 py-4 w-full justify-between items-center border-0 transition [overflow-anchor:none] [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                  type="button"
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target="#flush-collapseMyProgram"
                  aria-expanded="false"
                  aria-controls="flush-collapseMyProgram"
                >
                  <span className="text-blue-900 font-semibold">Programs</span>
                  <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-400 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-blue-900"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseMyProgram"
                className="!visible hidden border-0"
                data-te-collapse-item
                aria-labelledby="user-heading"
                data-te-parent="#mobileMyProgramDropdown"
              >
                <div className="py-4 px-5">
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to={"/program-list"}
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <img
                        src={ProgramsIcon}
                        alt="program_icon"
                        className="h-5 w-4"
                      />
                      <div className="text-md text-black font-semibold block text-sm">
                        All Programs
                      </div>
                    </Link>
                  </div>
                  {showCreateProgram === true ? (
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/my-programs"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={CreateIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          My Programs
                        </div>
                      </Link>
                    </div>
                  ) : null}
                  {showCreateProgram === true ? (
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/create-program"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={CreateIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Create a Program
                        </div>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div id="mobileMyProgramDropdown">
            <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
              <h2 className="mb-0" id="user-heading">
                <button
                  className="group relative flex px-5 py-4 w-full justify-between items-center border-0 transition [overflow-anchor:none] [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                  type="button"
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target="#flush-collapseResource"
                  aria-expanded="false"
                  aria-controls="flush-collapseResource"
                >
                  <span className="text-blue-900 font-semibold">Resources</span>
                  <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-400 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-blue-900"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseResource"
                className="!visible hidden border-0"
                data-te-collapse-item
                aria-labelledby="user-heading"
                data-te-parent="#mobileResourceDropdown"
              >
                <div className="py-4 px-5">
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to={"/resource-list"}
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <img
                        src={CreateIcon}
                        alt="program_icon"
                        className="h-5 w-4"
                      />
                      <div className="text-md text-black font-semibold block text-sm">
                        All Resources
                      </div>
                    </Link>
                  </div>
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to={"/create-resource"}
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <img
                        src={CreateIcon}
                        alt="program_icon"
                        className="h-5 w-4"
                      />
                      <div className="text-md text-black font-semibold block text-sm">
                        Create a Resource
                      </div>
                    </Link>
                  </div>
                  {userDetails.roleIDList === "1" && (
                    <div data-te-offcanvas-dismiss>
                      <Link
                        to={"/create-category"}
                        className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                      >
                        <img
                          src={CreateIcon}
                          alt="program_icon"
                          className="h-5 w-4"
                        />
                        <div className="text-md text-black font-semibold block text-sm">
                          Manage Category
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="v">
            <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
              <h2 className="mb-0" id="user-heading">
                <button
                  className="group relative flex px-5 py-4 w-full justify-between items-center border-0 transition [overflow-anchor:none] [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                  type="button"
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target="#flush-collapseReport"
                  aria-expanded="false"
                  aria-controls="flush-collapseReport"
                >
                  <span className="text-blue-900 font-semibold"> Reports</span>
                  <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-400 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-blue-900"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              </h2>
              <div
                id="flush-collapseReport"
                className="!visible hidden border-0"
                data-te-collapse-item
                aria-labelledby="user-heading"
                data-te-parent="#mobileReportsDropdown"
              >
                <div className="py-4 px-5">
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to="/programs/reports"
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <div className="text-md text-black font-semibold block text-sm">
                        Program Details
                      </div>
                    </Link>
                  </div>
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to="/medical-module/reports"
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <div className="text-md text-black font-semibold block text-sm">
                        Medical Module Details
                      </div>
                    </Link>
                  </div>
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to="/security-module/reports"
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <div className="text-md text-black font-semibold block text-sm">
                        Security Module Details
                      </div>
                    </Link>
                  </div>
                  <div data-te-offcanvas-dismiss>
                    <Link
                      to="/overall-program-details/reports"
                      className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                    >
                      <div className="text-md text-black font-semibold block text-sm">
                        Overall Program Details
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!userDetails.impersonateEmail ? (
            <div className="py-4 px-5">
              <a
                className="text-md text-blue-900 font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                onClick={() => {
                  handleLogout();
                }}
                data-te-offcanvas-dismiss
                // href={logoutURL}
              >
                <h4 className="font-medium text-gray-600 dark:text-gray-300">
                  Logout
                </h4>
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <header>
      <nav className="bg-[#1d428a] px-2 sm:px-4 py-1 dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div className="flex justify-between py-1 ">
          <Link to={"/"} className="flex gap-2 items-center justify-center">
            <img src={Logo} className="h-10 w-5" alt="logo" />
            <p className="hidden md:block text-[3.25rem] leading-[0] text-[#FFFFFF] font-semibold ActionNBA-Medium-Web">
              Huddle
            </p>
            <h4 className="block md:hidden text-2xl text-[#FFFFFF] font-semibold ActionNBA-Medium-Web">
              Huddle
            </h4>
          </Link>
          <div>
            {/* Web view */}

            <div className="hidden lg:block md:w-auto mt-1">
              <ul className="flex flex-wrap w-full md:space-x-5 items-center justify-center">
                {userDetails?.isUserExpired === false && (
                  <>
                    <li>
                      <Link to={"/"}>
                        {" "}
                        <img
                          src={HomeIcon}
                          alt="calendar"
                          className="h-8 w-8"
                        />
                      </Link>
                    </li>

                    {userDetails && userDetails.roleIDList === "1" && (
                      <li className="group dropdown  cursor-pointer tracking-wide">
                        <p className=" text-[#FFFFFF] font-semibold">
                          {" "}
                          Program Management
                        </p>
                        <div className="group-hover:block dropdown-menu absolute hidden h-auto z-[1000]">
                          <div className="top-0 w-full rounded-lg bg-white shadow px-2 py-4">
                            {/* <Link
                          to={"/"}
                          className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                        >
                          <img
                            src={CreateIcon}
                            alt="program_icon"
                            className="h-5 w-4"
                          />
                          <div className="text-md text-black font-semibold block text-sm">
                            Manage Program Creation
                          </div>
                        </Link> */}
                            <Link
                              to={"/create-component"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={ComponentsIcon}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                Manage Components
                              </div>
                            </Link>
                            <Link
                              to={"/program-type"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={ProgramTypeIcon}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                Manage Program Types
                              </div>
                            </Link>
                            <Link
                              to={"/add-qa"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={AddQAIcon}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                Manage Questions
                              </div>
                            </Link>
                            <Link
                              to={"/modules"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={Modules}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                Manage Modules
                              </div>
                            </Link>
                            <Link
                              to={"/participant-formula"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-4 text-blue-900 "
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <div className="text-md text-black font-semibold block text-sm">
                                Participant Formula
                              </div>
                            </Link>
                          </div>
                        </div>
                      </li>
                    )}
                    {userDetails && userDetails.roleIDList === "1" && (
                      <li className="group dropdown cursor-pointer tracking-wide">
                        <p className=" text-[#FFFFFF] font-semibold">
                          {" "}
                          User Management
                        </p>
                        <div className="group-hover:block dropdown-menu absolute hidden h-auto z-[1000]">
                          <div className="top-0 w-full rounded-lg bg-white shadow px-2 py-4">
                            <Link
                              to={"/user-list"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={UserIcon}
                                alt="program_icon"
                                className="h-4 w-5 mt-1"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                All Users
                              </div>
                            </Link>
                            <Link
                              to={"/change-user"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={ChangeUserIcon}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                Impersonate User
                              </div>
                            </Link>
                            <Link
                              to={"/user-directory"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={UserIcon}
                                alt="program_icon"
                                className="h-4 w-5 mt-1"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                User Directory
                              </div>
                            </Link>
                            <Link
                              to={"/notification-management"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 text-blue-900"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                                />
                              </svg>

                              <div className="text-md text-black font-semibold block text-sm">
                                Notification Management
                              </div>
                            </Link>
                          </div>
                        </div>
                      </li>
                    )}
                    {userDetails.roleIDList === "1" ? (
                      <li className="group dropdown cursor-pointer tracking-wide">
                        <p className=" text-[#FFFFFF] font-semibold">
                          {" "}
                          Programs
                        </p>
                        <div className="group-hover:block dropdown-menu absolute hidden h-auto z-[1000]">
                          <div className="top-0 w-full rounded-lg bg-white shadow px-2 py-4 ">
                            <Link
                              to={"/program-list"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={ProgramsIcon}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                All Programs
                              </div>
                            </Link>
                            {showCreateProgram === true ? (
                              <>
                                <Link
                                  to={"/my-programs"}
                                  className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                                >
                                  <img
                                    src={CreateIcon}
                                    alt="program_icon"
                                    className="h-5 w-4"
                                  />
                                  <div className="text-md text-black font-semibold block text-sm">
                                    My Programs
                                  </div>
                                </Link>

                                <Link
                                  to={"/create-program"}
                                  className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                                >
                                  <img
                                    src={CreateIcon}
                                    alt="program_icon"
                                    className="h-5 w-4"
                                  />
                                  <div className="text-md text-black font-semibold block text-sm">
                                    Create a Program
                                  </div>
                                </Link>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </li>
                    ) : (
                      <>
                        <li className="group dropdown cursor-pointer tracking-wide">
                          <Link to={"/program-list"}>
                            <div className="text-[#FFFFFF] font-semibold">
                              All Programs
                            </div>
                          </Link>
                        </li>
                        {showCreateProgram === true ? (
                          <>
                            <li className="group dropdown cursor-pointer tracking-wide">
                              <Link to={"/my-programs"}>
                                <div className="text-[#FFFFFF] font-semibold">
                                  My Programs
                                </div>
                              </Link>
                            </li>{" "}
                            <li className="group dropdown cursor-pointer tracking-wide">
                              <Link to={"/create-program"}>
                                <div className="text-[#FFFFFF] font-semibold">
                                  Create a Program
                                </div>
                              </Link>
                            </li>
                          </>
                        ) : null}
                      </>
                    )}

                    <li className="group dropdown cursor-pointer tracking-wide">
                      <p className=" text-[#FFFFFF] font-semibold">
                        {" "}
                        Resources
                      </p>
                      <div className="group-hover:block dropdown-menu absolute hidden h-auto z-[1000]">
                        <div className="top-0 w-full rounded-lg bg-white shadow px-2 py-4 ">
                          <Link
                            to={"/resource-list"}
                            className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                          >
                            <img
                              src={CreateIcon}
                              alt="program_icon"
                              className="h-5 w-4"
                            />
                            <div className="text-md text-black font-semibold block text-sm">
                              All Resources
                            </div>
                          </Link>
                          <Link
                            to={"/create-resource"}
                            className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                          >
                            <img
                              src={CreateIcon}
                              alt="program_icon"
                              className="h-5 w-4"
                            />
                            <div className="text-md text-black font-semibold block text-sm">
                              Create a Resource
                            </div>
                          </Link>
                          {userDetails.roleIDList === "1" && (
                            <Link
                              to={"/create-category"}
                              className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                            >
                              <img
                                src={CreateIcon}
                                alt="program_icon"
                                className="h-5 w-4"
                              />
                              <div className="text-md text-black font-semibold block text-sm">
                                Manage Category
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    </li>
                    <li className="group dropdown cursor-pointer tracking-wide">
                      <p className=" text-[#FFFFFF] font-semibold"> Reports</p>
                      <div className="group-hover:block dropdown-menu absolute right-[1.5rem] hidden h-auto z-[1000]">
                        <div className="top-0 w-full rounded-lg bg-white shadow px-2 py-4 ">
                          <Link
                            to="/programs/reports"
                            className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                          >
                            <div className="text-md text-black font-semibold block text-sm">
                              Program Details
                            </div>
                          </Link>
                          <Link
                            to="/medical-module/reports"
                            className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                          >
                            <div className="text-md text-black font-semibold block text-sm">
                              Medical Module Details
                            </div>
                          </Link>
                          <Link
                            to="/security-module/reports"
                            className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                          >
                            <div className="text-md text-black font-semibold block text-sm">
                              Security Module Details
                            </div>
                          </Link>
                          <Link
                            to="/overall-program-details/reports"
                            className="flex w-full gap-2 whitespace-nowrap py-2 px-4 hover:bg-neutral-100"
                          >
                            <div className="text-md text-black font-semibold block text-sm">
                              Overall Program Details
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>

                    {/* <li>
                  <div className="mt-2 relative">
                    <button
                      className="relative  inline-flex w-fit"
                      onClick={() => setShowNotification(!showNotification)}
                    >
                      <span className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-red-700 px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-[#FFFFFF]">
                        0
                      </span>

                      <img
                        src={Notification}
                        alt="notification"
                        className="h-6 w-6"
                      />
                    </button>
                  </div>
                </li> */}
                    <NotificationBell
                      isMobileView={false}
                      setToasterMessage={setToasterMessage}
                    />
                  </>
                )}
                <div className="relative" data-te-dropdown-ref>
                  <div
                    id="dropdownMenuButton2"
                    role="button"
                    data-te-dropdown-toggle-ref
                    aria-expanded="false"
                    onClick={() => setShowLogout(!showLogout)}
                    className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
                  >
                    <div>
                      <span className="font-medium text-gray-600 dark:text-gray-300">
                        {MethodAndVariable.getInitials(
                          userDetails.firstName,
                          userDetails.lastName,
                          userDetails.userName
                        )}
                      </span>
                    </div>
                  </div>
                  {userDetails.impersonateEmail ? (
                    <span
                      className="text-red-600 closeImpersonate"
                      onClick={() => {
                        handleImpersonateChange(true);
                      }}
                    >
                      X
                    </span>
                  ) : null}
                  <ul
                    className="absolute left-auto right-0 z-[100] float-left m-0 mt-1 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                    aria-labelledby="dropdownMenuButton2"
                    data-te-dropdown-menu-ref
                  >
                    <li>
                      <button
                        className="block w-full whitespace-nowrap bg-transparent py-4 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                        onClick={() => {
                          handleLogout();
                        }}
                        // href={logoutURL}
                      >
                        <h4 className="font-medium text-gray-600 dark:text-gray-300">
                          Logout
                        </h4>
                      </button>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            {/* Mobile view */}
            <div className="block lg:hidden">
              <div className="flex gap-4">
                <NotificationBell
                  isMobileView={true}
                  setToasterMessage={setToasterMessage}
                />

                {/* <div className="relative">
                  <button
                    className="mt-2"
                    onClick={() => setShowNotification(!showNotification)}
                  >
                    <span className="absolute -mt-2.5 ml-2 rounded-full bg-red-600 py-1 px-2 text-[0.6rem] leading-none text-[#FFFFFF]">
                      0
                    </span>
                    <img
                      src={Notification}
                      alt="notification"
                      className="h-5 w-5"
                    />
                  </button>
                </div> */}
                <div>
                  <button
                    className="mt-1 inline-block shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out"
                    type="button"
                    data-te-offcanvas-toggle
                    data-te-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-[#FFFFFF]"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Mobile menu items */}

              <div
                className="invisible fixed bottom-0 left-0 top-0 z-[1045] flex w-full md:w-3/6 bg-white h-full max-w-full -translate-x-full flex-col border-none  bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"
                tabindex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
                data-te-offcanvas-init
              >
                <RenderMobileMenu />
              </div>
            </div>
          </div>{" "}
        </div>
      </nav>
      {/* Notification Popup */}
      {showNotification && (
        <div
          ref={notificationDiv}
          className="absolute drop-shadow-2xl border rounded bg-white z-[1200] w-[20rem] md:w-[32rem] lg:w-[40rem] h-4/6 overflow-hidden  top-14 right-1 "
        >
          {/* Header */}
          <div className="flex justify-between px-2 py-4">
            <p className="text-lg ">Notification</p>
            <div className="flex gap-2 flex-wrap">
              <button
                onClick={() => navigation("/notifications")}
                className="text-sm text-blue-900 border-r-2 pr-2 border-black"
              >
                View All
              </button>

              <button className="text-sm text-blue-900">
                Mark All as Read
              </button>
            </div>
          </div>
          {/* Body */}
          <div className="overflow-y-auto">
            <div className="px-2 py-2 border-y-2 bg-blue-100 border flex justify-between">
              <div className="">
                <p className="text-sm font-bold truncate">
                  Test program: is published
                </p>
                <span className="text-xs font-thin">
                  {moment(new Date()).format("MM/DD/YY, hh:mm a")}:{" "}
                  {userDetails.firstName + userDetails.lastName}
                </span>
              </div>
              <div>
                <button>
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-blue-900 mt-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </header>
  );
};

export default HeaderComponent;
