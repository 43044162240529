import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import AddQA from "./modules/AddQ&A/AddQ&A";
import UserList from "./modules/Users/UserList";
import CreateProgram from "./modules/CreateProgram/CreateProgram";
import CreateComponent from "./modules/CreateComponent/CreateComponent";
import ProgramList from "./modules/CreateProgram/ProgramList";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest } from "./config/authConfig";
import Helper from "./utils/config/helper";
import Modules from "./modules/AllModules/AllModules";
import HeaderComponent from "./HeaderComponent";
import SurveyComponent from "./sharedComponent/ModuleQuestion/SurveyCreatorComponent";
import UserViewComponent from "./sharedComponent/ModuleQuestion/UserViewComponenet";
import { AppContext } from "./store/context";
import APIHelper from "./utils/config/apiHelper";
import APIURLConstant from "./utils/config/apiURLConstant";
// import Calender from "./modules/calender/Calender";
import Toaster from "./sharedComponent/Toaster/Toaster";
import Loader from "./sharedComponent/Loader/Loader";
import ProgramDetails from "./modules/ProgramDetails/ProgramDetails";
import ProgramType from "./modules/ProgramType/ProgramType";
import ChangeUser from "./modules/Users/ChangeUser";
import MethodAndVariable from "./utils/config/variableConstant";
import CreateResource from "./modules/Resources/CreateResources";
import ResourceList from "./modules/Resources/ResourceList";
import CreateCategory from "./modules/Resources/CreateCategory";
import Notifications from "./pages/Notifications/Notifications";
import MyProgramList from "./modules/CreateProgram/MyProgramList";
import Report from "./pages/Reports/report";
import ResourceListView from "./modules/Resources/ResourceListView";
import UnauthorizedUI from "./sharedComponent/AccessComponents/unauthorized";
import UserDirectory from "./modules/UserDirectory/UserDirectory";
import FormulaPage from "./modules/FormulaPage/FormulaPage";
import SubCategories from "./modules/Resources/SubCategories";
// import RecapReport from "./modules/ProgramDetails/RecapReport";
import "react-phone-input-2/lib/style.css";
import SubCategoriesL2 from "./modules/Resources/SubCategoriesL2";
import NotificationManagement from "./modules/NotificationManagement";
import ProgramListForDate from "./modules/ProgramListForDate/ProgramListForDate";
function App() {
  const isLoggedInByAccessToken = Helper.getStorage("accessToken")
    ? true
    : false;
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInByAccessToken);

  const [userRegions, setUserRegions] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userProgramTypesAll, setUserProgramTypesAll] = useState([]);

  const [userDetails, setUserDetails] = useState({
    email: "",
    userName: "",
    permissionNameList: "",
    roleNameList: "",
    permissionIDList: "",
    roleIDList: "",
    regionID: "",
    userID: "",
    firstName: "",
    lastName: "",
    isImpersonate: false,
    impersonateEmail: Helper.getStorage("impersonateEmail"),
    isUserExpired: true,
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  let envDetails = MethodAndVariable.getENVDetails();

  const { instance, inProgress, accounts } = useMsal();
  const [showLogin, setShowLogin] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(true);
  //const [isUserExpired, setIsUserExpired] = useState(true);

  useEffect(() => {
    if (instance && accounts[0]) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      if (inProgress === InteractionStatus.None) {
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then(async (response) => {
            console.log("token response : ", response);
            APIHelper.post(APIURLConstant.UPDATE_USER_LAST_LOGIN, {
              email: response.account.username,
            }).then((res) => {
              Helper.setStorage("accessToken", response.accessToken);
              Helper.setStorage("chk", "aad");
              setIsLoggedIn(true);
              // window.location.reload();
              // eslint-disable-next-line no-debugger
            });
            // Helper.setStorage("accessToken", response.accessToken);
            // Helper.setStorage("chk", "aad");
            // // eslint-disable-next-line no-debugger
            // // setShowLogin(false);
            // setIsLoggedIn(true);
            // //await getDetails();
            // // return false;
          })
          .catch((error) => {
            console.log("Error : ", error);
            //Last Login
            APIHelper.post(APIURLConstant.UPDATE_USER_LAST_LOGIN, {
              email: accounts[0]["username"],
            }).then((res) => {
              console.log("Updated Last Login ");
            });

            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
          });
      }
    }
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    if (isLoggedIn && !userDetails.email) {
      setLoading(true);
      const accessToken = Helper.getStorage("accessToken");
      if (accessToken) {
        APIHelper.post(APIURLConstant.GET_USER_DETAILS, {
          AccessToken: accessToken, //Helper.getStorage("accessToken"),
        })
          .then((data) => {
            // const user = {
            //   email: data.split("#")[0],
            //   userName: data.split("#")[1],
            // };
            Helper.setStorage("reporturl", data[0].reportURL);
            Helper.setStorage("pageSize", data[0].pageSize);
            getUserRoleandPermission(data[0]);
            setIsAuthorized(true);
            //setIsUserExpired(data[0].isUserExpired);
          })
          .catch((err) => {
            console.log(err);
            if (userDetails.impersonateEmail) {
              getUserRoleandPermission(userDetails.impersonateEmail);
            }
            setIsAuthorized(false);
            setLoading(false);
          });
      }
    }
  }, [isLoggedIn, userDetails]);

  const getUserRoleandPermission = (user) => {
    // user.email = 'spathak3@nba.com'
    setLoading(true);
    // need to check if impersonateEmail email is manually, if yes change we need to recall getpermission api again with user's email
    if (userDetails.impersonateEmail) {
      const payLoad = {
        fullName: "",
        email: userDetails.impersonateEmail
          ? userDetails.impersonateEmail
          : user.email,
      };
      APIHelper.post(
        APIURLConstant.GET_ALL_ROLES_AND_PERMISSIONS_BY_EMAIL,
        payLoad
      )
        .then((data) => {
          if (data && data.length > 0) {
            user.permissionNameList = data[0].permissionNameList;
            user.roleNameList = data[0].roleNameList;
            user.permissionIDList = data[0].permissionIDList;
            user.roleIDList = data[0].roleIDList;
            user.regionID = data[0].regionID;
            user.userID = data[0].userID;
            user.firstName = data[0].firstName;
            user.lastName = data[0].lastName;
            user.isImpersonate = false;
            user.impersonateEmail = userDetails.impersonateEmail;
            user.regionIDs = data[0].regionIDs;
            user.isProgramReviwer = data[0].isProgramReviwer;
            user.isUserExpired = data[0].isUserExpired;
            if (userDetails.impersonateEmail)
              user.email = userDetails.impersonateEmail
                ? userDetails.impersonateEmail
                : userDetails.email;
            dispatchUserEvent("ADD_USER", user);
            setLoading(false);
          } else {
            Helper.setStorage("impersonateEmail", null);
            console.log(
              `Logged in user doesn't have any role / permission added`
            );
            // setToasterMessage({
            //   showToaster: true,
            //   errorType: "error",
            //   message: "Logged in user doesn't have any role/permission added",
            // });
            dispatchUserEvent("ADD_USER", user);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      dispatchUserEvent("ADD_USER", user);
      setLoading(false);
    }
  };

  const dispatchUserEvent = (actionType, user) => {
    // setUserDetails(user)
    switch (actionType) {
      case "ADD_USER":
        setUserDetails(user);
        return;
      default:
        return;
    }
  };

  const dispatchRegions = (actionType, regionsList) => {
    switch (actionType) {
      case "ADD_REGIONS":
        setUserRegions(regionsList);
        return;
      default:
        return;
    }
  };

  const dispatchCategories = (actionType, cateoryList) => {
    switch (actionType) {
      case "ADD_CATEGORY":
        setUserCategories(cateoryList);
        return;
      default:
        return;
    }
  };

  const dispatchUserRoles = (actionType, userRoles) => {
    switch (actionType) {
      case "ADD_ROLES":
        setUserRoles(userRoles);
        return;
      default:
        return;
    }
  };

  const dispatchProgramTypes = (actionType, programTypes) => {
    switch (actionType) {
      case "ADD_PROGRAM_TYPES":
        setUserProgramTypesAll(programTypes);
        return;
      default:
        return;
    }
  };

  return (
    <>
      {isAuthorized ? (
        <div className="">
          <Loader showLoading={loading} />
          <AppContext.Provider
            value={{
              userDetails,
              dispatchUserEvent,
              userRegions,
              dispatchRegions,
              userCategories,
              dispatchCategories,
              userRoles,
              dispatchUserRoles,
              userProgramTypesAll,
              dispatchProgramTypes,
            }}
          >
            <Suspense fallback="Loading...">
              {isLoggedIn ? (
                // <div className="pt-14">
                // <Router getUserConfirmation={() => { }}>
                <>
                  {isLoggedIn ? (
                    <HeaderComponent
                      setIsLoggedIn={setIsLoggedIn}
                      setShowLogin={setShowLogin}
                    />
                  ) : null}
                  <Routes>
                    {showLogin &&
                    Helper.getStorage("accessToken") == null &&
                    Helper.getStorage("chk") == null &&
                    window.location.pathname.indexOf("/signup") === -1 ? (
                      <Route
                        exact
                        path="/"
                        element={
                          <Login
                            setLogin={setShowLogin}
                            showHeader={!isLoggedIn}
                          />
                        }
                      />
                    ) : showLogin &&
                      Helper.getStorage("chk") !== "jr" &&
                      Helper.getStorage("accessToken") != null &&
                      window.location.pathname.indexOf("/signup") === -1 ? (
                      // <UnauthenticatedTemplate>
                      <Route
                        exact
                        path="/"
                        element={
                          <Login
                            setLogin={setShowLogin}
                            showHeader={!isLoggedIn}
                          />
                        }
                      />
                    ) : // </UnauthenticatedTemplate>
                    null}
                    {userDetails?.email != "" && isLoggedIn ? (
                      <React.Fragment>
                        <Route
                          exact
                          path="/login"
                          element={<Login showHeader={!isLoggedIn} />}
                        />
                        <Route exact path="/signup" element={<Signup />} />
                        <Route exact path="/add-qa" element={<AddQA />} />
                        <Route exact path="/user-list" element={<UserList />} />
                        <Route
                          exact
                          path="/change-user"
                          element={<ChangeUser />}
                        />
                        <Route
                          exact
                          path="/notifications"
                          element={<Notifications />}
                        />
                        {/* <Route
                        exact
                        path="/pdfViewer"
                        element={<RecapReport />}
                      /> */}
                        <Route
                          exact
                          path="/create-program"
                          element={<CreateProgram />}
                        />
                        <Route
                          exact
                          path="/user-directory"
                          element={<UserDirectory />}
                        />
                        <Route
                          exact
                          path="/program-details/:programId/:comment"
                          element={<ProgramDetails />}
                        />
                        <Route
                          exact
                          path="/program-details/:programId"
                          element={<ProgramDetails />}
                        />
                        <Route
                          exact
                          path="/program-type"
                          element={<ProgramType />}
                        />
                        <Route
                          exact
                          path="/create-component"
                          element={<CreateComponent />}
                        />
                        <Route
                          exact
                          path="/participant-formula"
                          element={<FormulaPage />}
                        />
                        <Route
                          exact
                          path="/program-list"
                          element={<ProgramList isMyPrograms={false} />}
                        />
                        <Route
                          exact
                          path="/program-list/:startDate"
                          element={<ProgramListForDate />}
                        />
                        <Route exact path="/modules" element={<Modules />} />
                        {/* <Route exact path="/calender" element={<Calender />} /> */}
                        <Route
                          exact
                          path="/modules/:userType/:moduleName/:moduleId"
                          element={<SurveyComponent />}
                        />
                        <Route
                          exact
                          path="/program-details/:programId/:userType/:moduleId"
                          element={<UserViewComponent />}
                        />
                        <Route
                          exact
                          path="/program-details/:programId/:comment/:userType/:moduleId"
                          element={<UserViewComponent />}
                        />
                        <Route
                          exact
                          path="/program-details/:programId/:userType/:moduleId/:feedback"
                          element={<UserViewComponent />}
                        />
                        <Route
                          exact
                          path="/program-details/:programId/:comment/:userType/:moduleId/:feedback"
                          element={<UserViewComponent />}
                        />
                        <Route
                          exact
                          path="/create-resource"
                          element={<CreateResource />}
                        />
                        <Route
                          exact
                          path="/create-category"
                          element={<CreateCategory />}
                        />
                        <Route
                          exact
                          path="/notification-management"
                          element={<NotificationManagement />}
                        />
                        <Route
                          path="/:category/sub-categories"
                          element={<SubCategories />}
                        />
                        <Route
                          path="/:category/:subcategory/sub-categories"
                          element={<SubCategoriesL2 />}
                        />
                        <Route
                          exact
                          path="/resource-list"
                          element={<ResourceList />}
                        />
                        <Route
                          exact
                          path="/my-programs"
                          element={<MyProgramList />}
                        />
                        <Route
                          exact
                          path="/:type/reports"
                          element={<Report />}
                        />
                        <Route
                          exact
                          path="/resource-list/view"
                          element={<ResourceListView />}
                        />
                      </React.Fragment>
                    ) : userDetails?.isUserExpired == true ? (
                      <React.Fragment>
                        <Route
                          path="/"
                          element={
                            <Login
                              showHeader={!isLoggedIn}
                              isUserExpired={true}
                            />
                          }
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Route
                          path="/"
                          element={<Login showHeader={!isLoggedIn} />}
                        />
                        <Route path="/add-qa" element={<AddQA />} />
                        <Route
                          path="*"
                          element={<div>Default Page Content</div>}
                        />
                      </React.Fragment>
                    )}
                  </Routes>
                </>
              ) : (
                // </Router>
                <>
                  {isLoggedIn &&
                  window.location !== envDetails &&
                  envDetails.RETURN_URL
                    ? window.location.replace(
                        envDetails && envDetails.RETURN_URL
                      )
                    : null}
                  <Login showHeader={!isLoggedIn} />
                </>
              )}
            </Suspense>
          </AppContext.Provider>
          <Toaster
            toasterMessage={toasterMessage}
            setToasterMessage={setToasterMessage}
          />
        </div>
      ) : (
        <UnauthorizedUI
          isHmoeIconDisabled={true}
          setIsLoggedIn={setIsLoggedIn}
          setShowLogin={setShowLogin}
          setIsAuthorized={setIsAuthorized}
        />
      )}
    </>
  );
}

export default App;
