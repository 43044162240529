import React, { PureComponent, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
const initalData = [
  {
    name: "Jr. NBA International Programs",
    "Jr. NBA International Programs": 0,
    label: "Jr.NBA Intern",
  },
  {
    name: `Jr. NBA US Programs`,
    "Jr. NBA US Programs": 0,
    label: "Jr.NBA US",
  },
  {
    name: "BWB Programs",
    "BWB Programs": 0,
    label: "BWB",
  },
  {
    name: "NBA Basketball School Programs",
    "NBA Basketball School Programs": 0,
    label: "NBA Bskt sc",
  },
  {
    name: "NBA Academy Programs",
    "NBA Academy Programs": 0,
    label: "NBA Acad",
  },
  {
    name: "Other Programs",
    "Other Programs": 0,
    label: "other",
  },
];

const ProgramCountBar = (props) => {
  const [chartData, setChartData] = useState(initalData);

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setChartData(props.data);
    }
  }, [props]);

  return (
    <div className="w-full overflow-auto program-bar-chart-parent">
      <ResponsiveContainer width={900} height={405}>
        <BarChart
          className="program-bar-chart"
          width="100%"
          height="100%"
          margin={{
            top: 10,
            right: 5,
            left: 5,
            bottom: 20,
          }}
          data={chartData}
          tooltipAxis='y'
          isTooltipActive= 'true'
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <XAxis
            fontSize={10}
            dataKey="name"
            fontWeight={"bold"}
            textAnchor="end"
            height={130}
            angle={-60}
          />
          {/* <Tooltip  /> */}
          <Tooltip
            content={chartData}
            cursor={false}
            style={{ left: "-150px", width: "50px" }}
          />
          <Bar
            barSize={30}
            dataKey="Jr. NBA International Programs"
            fill="#00D18C"
            stackId="a"
          />
          <Bar
            barSize={30}
            dataKey="Jr. NBA US Programs"
            fill="#27A2CB"
            stackId="a"
          />
          <Bar barSize={30} dataKey="BWB Programs" fill="#F0D900" stackId="a" />
          <Bar
            barSize={30}
            dataKey="NBA Basketball School Programs"
            fill="#7000FF"
            stackId="a"
          />
          <Bar
            barSize={30}
            dataKey="NBA Academy Programs"
            fill="#D129BB"
            stackId="a"
          />
          <Bar
            barSize={30}
            dataKey="Other Programs"
            fill="#FE4917"
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ProgramCountBar;
