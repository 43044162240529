import React, { useState } from "react";
import { useEffect } from "react";
import APIURLConstant from "../../utils/config/apiURLConstant";
import APIHelper from "../../utils/config/apiHelper";
import { useContext } from "react";
import { AppContext } from "../../store/context";
import Toaster from "../../sharedComponent/Toaster/Toaster";

const FormulaPage = () => {
  const { userDetails } = useContext(AppContext);
  const [formulaList, setFormulaList] = useState([]);

  const [showInput, setShowInput] = useState(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const HeaderTable = [
    { heading: "Program" },
    { heading: "Formula" },
    { heading: "Africa" },
    { heading: "Asia Pac" },
    { heading: "Brazil" },
    { heading: "Canada" },
    { heading: "China" },
    { heading: "EME" },
    { heading: "India" },
    { heading: "LatAm" },
    { heading: "Mexico" },
  ];
  useEffect(() => {
    if (userDetails && userDetails.roleIDList === "1") {
      getFormulaList();
    }
  }, [userDetails]);

  const getFormulaList = () => {
    APIHelper.post(APIURLConstant.GET_REACH_PARTICIAPNTS_FORMULA)
      .then((res) => {
        if (res && res.length > 0) {
          const newArr = [];
          res.forEach((item) => {
            newArr.push();
          });
          setFormulaList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeInput = (programType, value, region) => {
    const updatedArr = [];
    formulaList.forEach((item) => {
      if (item.programType === programType) {
        item[region] = value;
        updatedArr.push(item);
      } else {
        updatedArr.push(item);
      }
    });

    setFormulaList(updatedArr);
  };

  const handleUpdate = async (programType, value, region) => {
    if (programType !== undefined && value && region !== undefined) {
      setShowInput(null);

      const payLoad = {
        programType: programType,
        formula: region !== "formula" ? value : null,
        primaryFormula: region === "formula" ? value : null,
        region: region !== "formula" ? region : null,
      };
      await APIHelper.post(
        APIURLConstant.UPDATE_REACH_PARTICIPANTS_FORMULA,
        payLoad
      )
        .then((res) => {
          setToasterMessage({
            errorType: "success",
            showToaster: true,
            message: "Particiapnt Reach formula updated successfully.",
          });
          getFormulaList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClickTableCell = (itemType, name) => {
    setShowInput({ programType: itemType, name: name });
  };

  return (
    <div className="mt-16 md:mt-9">
      <div className="mx-auto p-2 md:p-7">
        <p className="text-[#c8102e] font-semibold text-[19px] py-3">
          Reach Participation Formulas by Program And Region
        </p>
        <div>
          <div className="flex flex-col overflow-hidden">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full  text-sm font-light  rounded ">
                    <thead className=" font-medium text-[#FFFFFF]  bg-[#C8102E] dark:border-neutral-500">
                      <tr>
                        {HeaderTable.map((header) => (
                          <th
                            scope="col"
                            className={`${
                              header.heading !== "Program"
                                ? "text-center"
                                : "text-start"
                            } py-3 px-2 w-[5rem] border-[3px] border-blue-900 text-sm`}
                          >
                            {header.heading}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {formulaList &&
                        formulaList.length > 0 &&
                        formulaList.map((tableData) => {
                          return (
                            <tr className="border-b  transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                              <td className=" py-3 px-2 w-[5rem] font-medium text-sm border-[3px] border-blue-900">
                                {tableData?.programType}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "formula" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline resize-none	overflow-hidden "
                                    value={tableData?.formula}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "formula"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.formula,
                                        "formula"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.formula,
                                          "formula"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "formula"
                                      )
                                    }
                                  >
                                    {" "}
                                    {tableData?.formula}
                                  </button>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "africa" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.africa}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "africa"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.africa,
                                        "africa"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.africa,
                                          "africa"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "africa"
                                      )
                                    }
                                  >
                                    {tableData?.africa}
                                  </button>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "asiaPacific" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.asiaPacific}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "asiaPacific"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.asiaPacific,
                                        "asiaPacific"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.asiaPacific,
                                          "asiaPacific"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <buuton
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "asiaPacific"
                                      )
                                    }
                                  >
                                    {tableData?.asiaPacific}
                                  </buuton>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "brazil" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.brazil}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "brazil"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.brazil,
                                        "brazil"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.brazil,
                                          "brazil"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <buuton
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "brazil"
                                      )
                                    }
                                  >
                                    {tableData?.brazil}
                                  </buuton>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "canada" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.canada}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "canada"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.canada,
                                        "canada"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.canada,
                                          "canada"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "canada"
                                      )
                                    }
                                  >
                                    {tableData?.canada}
                                  </button>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "china" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.china}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "china"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.china,
                                        "china"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.china,
                                          "china"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "china"
                                      )
                                    }
                                  >
                                    {tableData?.china}
                                  </button>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "eme" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.eme}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "eme"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.eme,
                                        "eme"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.eme,
                                          "eme"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "eme"
                                      )
                                    }
                                  >
                                    {tableData?.eme}
                                  </button>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "india" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.india}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "india"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.india,
                                        "india"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.india,
                                          "india"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "india"
                                      )
                                    }
                                  >
                                    {tableData?.india}
                                  </button>
                                )}
                              </td>
                              <td
                                onBlur={() => console.log("lciked out")}
                                className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900"
                              >
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "latinAmerica" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.latinAmerica}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "latinAmerica"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.latinAmerica,
                                        "latinAmerica"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.latinAmerica,
                                          "latinAmerica"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "latinAmerica"
                                      )
                                    }
                                  >
                                    {tableData?.latinAmerica}
                                  </button>
                                )}
                              </td>
                              <td className=" py-3 px-2 w-[5rem] font-light text-center text-sm border-[3px] border-blue-900">
                                {showInput !== null &&
                                showInput?.programType ===
                                  tableData?.programType &&
                                "mexico" === showInput?.name ? (
                                  <input
                                    className="w-full focus:outline-none focus:shadow-outline "
                                    value={tableData?.mexico}
                                    onChange={(e) =>
                                      handleChangeInput(
                                        tableData?.programType,
                                        e.target.value,
                                        "mexico"
                                      )
                                    }
                                    onBlur={() => {
                                      handleUpdate(
                                        tableData?.programType,
                                        tableData?.mexico,
                                        "mexico"
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.key === "Enter") {
                                        handleUpdate(
                                          tableData?.programType,
                                          tableData?.mexico,
                                          "mexico"
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleClickTableCell(
                                        tableData?.programType,
                                        "mexico"
                                      )
                                    }
                                  >
                                    {tableData?.mexico}
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};
export default FormulaPage;
