import React, { useEffect, useState } from "react";
import moment from "moment";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
const ProgramHistoryModel = (props) => {
  const [auditHistory, setAuditHistory] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(false);

  useEffect(() => {
    if (
      props.programID !== undefined &&
      props.programID !== null &&
      auditHistory === null
    ) {
      getProgramAuditHistory();
    }
  }, [props]);

  const getProgramAuditHistory = () => {
    setLoadingHistory(true);
    APIHelper.get(APIURLConstant.GET_PROGRAM_AUDIT_HISTORY(props.programID))
      .then((res) => {
        let arr = [];
        res?.map((item, index, originalArr) => {
          let previous = originalArr[index - 1];
          arr.push({
            actualData: item,
            row: [
              {
                localProgramName: item.localProgramName,
                highlight:
                  index > 0
                    ? previous?.localProgramName !== item.localProgramName
                      ? true
                      : false
                    : false,
              },
              {
                programType: item.programType,
                highlight:
                  index > 0
                    ? previous?.programType !== item.programType
                      ? true
                      : false
                    : false,
              },
              {
                programCategory: item.programCategory,
                highlight:
                  index > 0
                    ? previous?.programCategory !== item.programCategory
                      ? true
                      : false
                    : false,
              },
              {
                overallStartDate: item.overallStartDate,
                highlight:
                  index > 0
                    ? previous?.overallStartDate !== item.overallStartDate
                      ? true
                      : false
                    : false,
              },
              {
                overallEndDate: item.overallEndDate,
                highlight:
                  index > 0
                    ? previous?.overallEndDate !== item.overallEndDate
                      ? true
                      : false
                    : false,
              },
              {
                duration: item.duration,
                highlight:
                  index > 0
                    ? previous?.duration !== item.duration
                      ? true
                      : false
                    : false,
              },
              {
                programDescription: item.programDescription,
                highlight:
                  index > 0
                    ? previous?.programDescription !== item.programDescription
                      ? true
                      : false
                    : false,
              },
              {
                programStatus: item.programStatus,
                highlight:
                  index > 0
                    ? previous?.programStatus !== item.programStatus
                      ? true
                      : false
                    : false,
              },
              {
                programStage: item.programStage,
                highlight:
                  index > 0
                    ? previous?.programStage !== item.programStage
                      ? true
                      : false
                    : false,
              },
              {
                regionName: item.regionName,
                highlight:
                  index > 0
                    ? previous?.regionName !== item.regionName
                      ? true
                      : false
                    : false,
              },
              {
                countryName: item.countryName,
                highlight:
                  index > 0
                    ? previous?.countryName !== item.countryName
                      ? true
                      : false
                    : false,
              },
              {
                stateName: item.stateName,
                highlight:
                  index > 0
                    ? previous?.stateName !== item.stateName
                      ? true
                      : false
                    : false,
              },
              {
                city: item.city,
                highlight:
                  index > 0
                    ? previous?.city !== item.city
                      ? true
                      : false
                    : false,
              },
              {
                sponsorsList: item.sponsorsList,
                highlight:
                  index > 0
                    ? previous?.sponsorsList !== item.sponsorsList
                      ? true
                      : false
                    : false,
              },
              {
                agencyList: item.agencyList,
                highlight:
                  index > 0
                    ? previous?.agencyList !== item.agencyList
                      ? true
                      : false
                    : false,
              },
              {
                isConfirmed:
                  item.isConfirmed === true ? "Confirmed" : "Tentative",
                highlight:
                  index > 0
                    ? previous?.isConfirmed !== item.isConfirmed
                      ? true
                      : false
                    : false,
              },
              {
                auditByName: item.auditByName,
                highlight:
                  index > 0
                    ? previous?.auditByName !== item.auditByName
                      ? true
                      : false
                    : false,
              },
              {
                auditDate: item.auditDate,
                highlight: false,
              },
            ],
          });
        });
        setAuditHistory(arr);
        console.log(arr);
        setLoadingHistory(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingHistory(false);
      });
  };

  const tableDataArr = [
    "localProgramName",
    "programType",
    "programCategory",
    "overallStartDate",
    "overallEndDate",
    "duration",
    "programDescription",
    "programStatus",
    "programStage",
    "regionName",
    "countryName",
    "stateName",
    "city",
    "sponsorsList",
    "agencyList",
    "isConfirmed",
    "auditByName",
    "auditDate",
  ];

  return (
    <div className="justify-center backdrop-brightness-75  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000]">
      <div
        data-te-modal-dialog-ref
        className="pointer-events-none relative w-4/5 flex translate-y-[-50px] items-center justify-center h-full transition-all duration-300 ease-in-out "
      >
        <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <h5 className="text-3xl ActionNBA-Bold-Web  text-blue-900">
              Program History
            </h5>

            <button
              type="button"
              className=" rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              onClick={() => props.handleCloseHistoryModal()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="relative p-4 w-full overflow-auto">
            {loadingHistory ? (
              <div className="flex justify-center w-full p-2 ">
                <span className="loader"></span>
              </div>
            ) : (
              <table className="min-w-full text-left text-sm font-light">
                <thead className="text-[#323232] bg-gray-100 border-b font-medium dark:border-neutral-500">
                  <tr>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Program Name
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Program Type
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Program Category
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Start Date
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      End Date
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Duration
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Description
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Stage
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Region
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Country
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      State
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      City
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Sponcer's
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Agency's
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Is Confirmed
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Audit By
                    </th>
                    <th scope="col" className="px-4 py-2 whitespace-nowrap ">
                      Audit Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {auditHistory &&
                    auditHistory?.length > 0 &&
                    auditHistory.map((data, index) => {
                      const tableData = data?.row ?? [];

                      return (
                        <tr
                          className="border-b dark:border-neutral-500"
                          key={index}
                        >
                          {tableData?.map((item, index1) => (
                            <td
                              className={`max-w-[20rem] truncate px-4 py-2 ${
                                item.highlight === true ? "bg-yellow-300" : ""
                              }`}
                              data-te-toggle="tooltip"
                              title={`${
                                tableDataArr[index1] === "programDescription"
                                  ? item[tableDataArr[index1]]
                                  : ""
                              }`}
                            >
                              {item[tableDataArr[index1]]}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramHistoryModel;
