import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en.json";

export const initialiseI18n = () => {
  i18n.use(initReactI18next).init(
    {
      resources: {
        en: {
          translation: translationEN,
        },
        // fr: {
        //   translation: translationFR
        // }
      },
      lng: "en",
      fallbackLng: "en",
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: false,
      },
    },
    (err) => {
      if (err) {
        return console.error("i18n: Something went wrong loading", err);
      }
    }
  );
};

export default i18n;
