import React, { useEffect, useState, useContext } from "react";
import * as utils from "../../utils";
import { AutoComplete, Modal, Table } from "../../sharedComponent";
import Pagination from "../../sharedComponent/Pagination/Pagination";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import MethodAndVariable from "../../utils/config/variableConstant";
import TextareaAutosize from "react-textarea-autosize";
import Loader from "../../sharedComponent/Loader/Loader";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import Select from "react-select";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import { AppContext } from "../../store/context";
import Helper from "../../utils/config/helper";

const AddQA = () => {
  const { userDetails, userCategories, dispatchCategories } =
    useContext(AppContext);
  const [question, setQuestion] = useState("");
  const [answerValue, setAnswerValue] = useState(null);
  const [categoryTags, setCategoryTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [ansOptions, setAnsOptions] = useState([]);
  const [ansOptionsValue, setAnsOptionsValue] = useState([]);
  const [programCategory, setProgramCategory] = useState([]);
  const [programType, setProgramType] = useState([]);
  const [selectedProgramCategory, setSelectedProgramCategory] = useState(null);
  const [selectedProgramType, setSelectedProgramType] = useState(null);
  const [ansOptionsInputValue, setAnsOptionsInputValue] = useState("");
  const [value, setValue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ansTypeText, setAnsTypeText] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    questionError: false,
    ansTypeError: false,
    ansTypeTextError: false,
    categoryTagError: false,
    programTypeError: false,
  });
  const [answerType, setAnswerType] = useState([]);
  const [isAnswerOptions, setIsAnswerOptions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [statusCheck, setStatusCheck] = useState(true);
  const [isActive, setIsActive] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showTagModal, setShowTagModal] = useState(false);
  const [selectedListOfTags, setSelectedListOfTags] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [questionObject, setQuestionObject] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  //const [selectedTable, setSelectedTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddQAFormVisible, setIsAddQAFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(Helper.getStorage("pageSize"));
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [disableCategory, setDisableCategory] = useState(false);
  const [disableProgramType, setDisableProgramType] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [sortStatus, setSortStatus] = useState("normal");
  const [sortStatusText, setSortStatusText] = useState("");
  const [isCloned, setIsCloned] = useState(false);

  useEffect(() => {
    if (userDetails?.email !== "") {
      getTagsList();
      getAnswerTypes();
      getProgramCategory();
    }
  }, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getProgramType();
    if (selectedProgramCategory !== null) {
      setDisableCategory(true);
      // let updatedCategory = [];
      // categoryTags &&
      //   categoryTags.map((item) =>
      //     item.tagID === 2 ? updatedCategory.push(item) : null
      //   );
      // setSelectedTags(updatedCategory);
    } else {
      setDisableCategory(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgramCategory]);

  // reset all of the form state
  const resetState = () => {
    setQuestion("");
    setAnswerValue(null);
    setSelectedTags([]);
    setInputValue("");
    setValue([]);
    setErrorMessage({
      questionError: false,
      ansTypeError: false,
      ansTypeTextError: false,
      categoryTagError: false,
      programTypeError: false,
    });
    setAnsTypeText("");
    setIsAnswerOptions(null);
    setIsEditMode(false);
    setDisableCategory(false);
    setAnsOptionsValue([]);
    setSelectedProgramCategory(null);
    setSelectedProgramType(null);
    setQuestionObject(null);
    setIsCloned(false);
  };

  const column = [
    { heading: "SR.NO", value: "checkbox", width: "w-11" },
    { heading: "QUESTIONS", value: "questionText", width: "w-40" },
    { heading: "ANSWER FIELD", value: "answerType", width: "w-40" },
    { heading: "CATEGORY TAGS", value: "tagList", width: "w-60" },
    { heading: "STATUS", value: "isActive", width: "w-20", noSorting: true },
    { heading: "ACTION", value: "action", width: "w-20", noSorting: true },
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#0D47A1",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFFFFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#0D47A1",
        color: "white",
      },
    }),
  };
  const getProgramCategory = () => {
    if (userCategories === undefined || userCategories?.length === 0) {
      setLoading(true);
      const payload = {
        RoleId: parseInt(userDetails?.roleIDList),
        UserID: 0,
      };
      APIHelper.post(APIURLConstant.GET_PROGRAMCATEGORY_BY_USER_ROLE, payload)
        .then((res) => {
          if (res) {
            const programCategory = res.map((item) => {
              return {
                value: item.programCategoryID,
                label: item.category,
              };
            });
            if (programCategory && programCategory.length > 0) {
              setProgramCategory([...programCategory]);
              const category = [...programCategory];
              dispatchCategories("ADD_CATEGORY", category);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Categories`,
          });
          setLoading(false);
        });
    } else {
      const categoriesUpdated = userCategories ?? [];
      setProgramCategory(categoriesUpdated);
    }
  };

  const getProgramType = () => {
    setProgramType(null);
    setSelectedProgramType(null);
    if (selectedProgramCategory && selectedProgramCategory.value) {
      setLoading(true);
      APIHelper.post(APIURLConstant.GET_PROGRAMTYPE, {
        ProgramCategoryID: selectedProgramCategory.value,
      })
        .then((res) => {
          if (res) {
            const programType = res.map((item) => {
              return {
                value: item.programTypeID,
                label: item.type,
              };
            });
            if (programType && programType.length > 0) {
              setProgramType([...programType]);
              setDisableProgramType(false);

              if (
                isAddQAFormVisible &&
                questionObject &&
                questionObject.programTypeID !== undefined
              ) {
                const typeValue = programType.filter((type) => {
                  return type.value === questionObject.programTypeID.value;
                });
                if (typeValue !== undefined && typeValue !== null) {
                  setSelectedProgramType(
                    typeValue && typeValue.length > 0 ? typeValue[0] : null
                  );
                }
              }
              setLoading(false);
            } else {
              setLoading(false);
              setDisableProgramType(true);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableProgramType(false);

          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: `Failed to load Program Types`,
          });
          setLoading(false);
        });
    }
  };

  const getTagsList = () => {
    //TODO: Need to remove Hard- code Type, instead we can use Enum.
    APIHelper.post(APIURLConstant.GET_TAGS_BY_TYPE_NAME, {
      Type: MethodAndVariable.VariableConstant.category,
    })
      .then((res) => {
        if (res) {
          const updatedTags = res.map((item) => {
            return {
              label: item.tagName,
              value: item.tagName,
              ...item,
            };
          });
          if (updatedTags && updatedTags.length > 0) {
            //setTags([...tags, ...data])
            setCategoryTags([...updatedTags]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const getAnswerTypes = () => {
    APIHelper.get(APIURLConstant.GET_ANSWERTYPE())
      .then((data) => {
        setAnswerType(MethodAndVariable.setAnswerType(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAnswerType = (value) => {
    setAnswerValue(value);
    errorMessage.ansTypeError = false;
    setAnsOptionsValue([]);
    setAnsOptions([]);
    setAnsOptionsInputValue("");
    const answerOption = answerType.filter(
      (item) => item.answerTypeID === Number(value)
    );
    setIsAnswerOptions(answerOption[0].isOptionsRequired);
  };

  const handleChangeQuestion = (value) => {
    if (value.length === 0) {
      errorMessage.questionError = true;
      setQuestion(value);
    } else {
      errorMessage.questionError = false;
      setQuestion(value);
    }
  };

  // const handleChangeAnsTypeText = (value) => {
  //   if (value.length === 0) {
  //     setErrorMessage("ansTypeTextError");
  //     setAnsTypeText(value);
  //   } else {
  //     setAnsTypeText(value);
  //     setErrorMessage(null);
  //   }
  // };

  const handleChangeCategoryTag = (value) => {
    setSelectedTags(value);
    errorMessage.categoryTagError = false;
  };
  const isValidateForm = () => {
    let isFormValid = true;
    if (question?.trim()?.length === 0) {
      errorMessage.questionError = true;
      isFormValid = false;
    }
    if (answerValue == null) {
      errorMessage.ansTypeError = true;
      isFormValid = false;
    }
    if (isAnswerOptions === true && ansOptionsValue.length === 0) {
      errorMessage.ansTypeTextError = true;
      isFormValid = false;
    }
    if (selectedProgramCategory !== null && selectedProgramType === null) {
      errorMessage.programTypeError = true;
      isFormValid = false;
    }
    if (selectedTags.length === 0) {
      errorMessage.categoryTagError = true;
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleClone = () => {
    setShowModal(true);
    setModalMessage({
      title: "Clone",
      message: "Do you want to Clone this Question ?",
    });
  };

  const handleCloneConfirm = () => {
    setShowModal(false);
    setModalMessage({
      title: "",
      message: "",
    });
    questionObject.questionID.value = 0;
    setIsCloned(true);
    setToasterMessage({
      showToaster: true,
      errorType: "success",
      message: "Question cloned successfully",
    });
    setSelectedProgramType(null);
  };

  const handleSubmit = () => {
    // e.preventDefault();
    if (isValidateForm()) {
      setLoading(true);
      let categoryTagIds = "";
      //Comma separated TagIds we need for creation of Question
      const existingTags = selectedTags.filter((item) => {
        return !item.__isNew__;
      });
      existingTags.forEach((item) => {
        categoryTagIds += "," + item.tagID;
      });
      //Insert new tags into DB and fetch Ids.
      const newTags = selectedTags.filter((tag) => {
        return tag.__isNew__;
      });
      if (newTags && newTags.length > 0) {
        let tagNames = "";
        let singleTagName = "";
        newTags.forEach((item) => {
          tagNames += "," + item.value;
        });
        newTags.forEach((item) => {
          singleTagName = item.value;
        });
        //remove first comma
        tagNames = tagNames.slice(1);
        const tagToInsert = {
          tagTypeID: selectedProgramCategory !== null ? 0 : 1,
          tagName: selectedProgramCategory !== null ? singleTagName : tagNames,
          isActive: true,
        };
        APIHelper.post(APIURLConstant.POST_ADD_TAG, tagToInsert)
          .then((res) => {
            //remove first comma
            categoryTagIds = categoryTagIds.slice(1);
            categoryTagIds += "," + res;
            saveQuestion(categoryTagIds);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        saveQuestion(categoryTagIds);
      }
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter the required fields",
      });
    }
  };
  const handleAnswerOptions = (value) => {
    setAnsOptions(value);
    setAnsTypeText(value);
    errorMessage.ansTypeTextError = false;
  };
  //Edit action
  const handleClickEdit = (item) => {
    if (
      item.answerOptions.value != null &&
      item.answerOptions.value.length > 0
    ) {
      const ansOptionsinEdit = item.answerOptions.value
        .split(";")
        .map((item) => {
          return {
            label: item,

            value: item,
          };
        });
      setIsActive(item.isActive);
      setAnsOptions(ansOptionsinEdit);
      setAnsOptionsValue(ansOptionsinEdit);
    }
    setQuestionObject(item);
    setShowModal(true);
    setModalMessage({
      title: "Edit",
      message: "Do you want to Edit this Question ?",
    });
  };

  //Delete user
  const handleDeleteUser = (item) => {
    setShowModal(true);
    setQuestionObject(item);
    setModalMessage({
      title: "Delete",
      message: "Do you want to Delete this Question ?",
    });
  };
  const handleOpenTagList = (tags) => {
    setShowTagModal(true);
    setSelectedListOfTags(tags);
  };

  //Delete selected user
  const handleDelete = () => {
    let deleteRequests = [];
    // eslint-disable-next-line array-callback-return
    questionList.map((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.questionID.value);
      }
    });
    let messageString = "";
    if (deleteRequests.length !== questionList.length) {
      messageString = "Do you want to Delete selected Questions ?";
    } else {
      messageString = "Do you want to Delete All Questions ?";
    }
    if (deleteRequests.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Multiple Delete",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one Question",
      });
    }
  };

  const handleAddQA = () => {
    resetState();
    setIsAddQAFormVisible(true);
  };

  const handleMultiDeteConfirm = () => {
    setShowModal(false);
    let deleteRequests = [];
    // eslint-disable-next-line array-callback-return
    questionList.map((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.questionID.value);
      }
    });
    if (deleteRequests.length > 0) {
      setLoading(true);
      APIHelper.post(APIURLConstant.DELETE_QUESTION_LIST, {
        QuestionIds: deleteRequests.join(","),
        IsDeleted: true,
      })
        .then((res) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: "Question Deleted successfully",
          });
          getQuestionList(
            null,
            true,
            0,
            pageSize,
            sortStatusText,
            sortStatus === "ascending" ? "ASC" : "DESC"
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error While Deleting a Question!",
          });
          setLoading(false);
        });
    }
  };
  const handleEditConfirm = () => {
    setIsEditMode(true);
    setIsAddQAFormVisible(true);
    let updatedTags = [];
    setQuestion(questionObject.questionText.value);
    if (questionObject.tagList.value) {
      const tagList = questionObject.tagList.value.split(",");
      for (let i = 0; i < tagList.length; i++) {
        updatedTags.push(
          categoryTags.filter((item) => item.label === tagList[i].trimLeft())[0]
        );
      }
      setSelectedTags(updatedTags);
    }
    setAnswerValue(questionObject.answerTypeID.value);
    setValue(updatedTags);
    setIsActive(questionObject.isActive.value);
    setIsMandatory(questionObject.isMandatory.value);
    setAnsTypeText(questionObject.answerOptions.value);
    const answerOption = answerType.filter(
      (item) => item.answerTypeID === Number(questionObject.answerTypeID.value)
    );
    if (answerOption.length > 0) {
      setIsAnswerOptions(answerOption[0].isOptionsRequired);
    }

    if (
      questionObject.programCategoryID.value !== null &&
      questionObject.programCategoryID.value !== 0
    ) {
      programCategory.forEach((item) => {
        if (item.value === questionObject.programCategoryID.value) {
          setSelectedProgramCategory({
            value: item.value,
            label: item.label,
          });
          getProgramType();
        }
      });
    } else {
      setSelectedProgramCategory(null);
      setSelectedProgramType(null);
    }

    setShowModal(false);
  };

  const handleDeletConfirm = () => {
    setShowModal(false);
    setLoading(true);

    APIHelper.post(APIURLConstant.DELETE_QUESTION_LIST, {
      QuestionIds: JSON.stringify(questionObject.questionID.value),
      IsDeleted: true,
    })
      .then((res) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Question Deleted successfully",
        });
        getQuestionList(
          null,
          true,
          0,
          pageSize,
          sortStatusText,
          sortStatus === "ascending" ? "ASC" : "DESC"
        );
        //setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error While Deleting a Question!",
        });
        setLoading(false);
      });
  };

  //search button function
  const handleSearchQuestionList = () => {
    if (searchText.length > 0) {
      getQuestionList(
        searchText,
        statusCheck,
        0,
        pageSize,
        sortStatusText,
        sortStatus === "ascending" ? "ASC" : "DESC"
      );
    }
  };
  //status filter function
  const handleStatusCheckChange = (value) => {
    setStatusCheck(value);
    getQuestionList(
      searchText.length > 0 ? searchText : null,
      value,
      0,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
  };

  const onClickSelectAll = (e, isAllSelected) => {
    e.stopPropagation();
    const rowData = questionList?.map((ele) => {
      if (isAllSelected) {
        return { ...ele, ...{ isSelected: { value: false } } };
      } else {
        return { ...ele, ...{ isSelected: { value: true } } };
      }
    });
    setQuestionList(rowData);
  };
  //reset filter
  const resetFilter = () => {
    setSearchText("");
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    getQuestionList(
      null,
      true,
      0,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    setStatusCheck(true);
  };

  const onSelectTableRow = (e, object) => {
    e.stopPropagation();
    const SelectedData = questionList?.map((ele) => {
      if (ele.questionID === object.questionID) {
        return { ...ele, ...{ isSelected: { value: !ele?.isSelected.value } } };
      } else {
        return ele;
      }
    });
    setQuestionList(SelectedData);
  };

  //use this function to Save question
  const saveQuestion = (categoryTagIds) => {
    setLoading(true);
    const questionObj = {
      QuestionText: question,
      IsMandatory: isMandatory,
      IsDeleted: modalMessage.title === "Delete" ? true : false,
      IsActive: isEditMode ? isActive : true,
      AnswerTypeID: answerValue,
      AnswerOptions: ansOptionsValue
        .map((option) => {
          return option.value;
        })
        .join(";"), //ansTypeText,
      TagList:
        categoryTagIds && categoryTagIds.charAt(0) === ","
          ? categoryTagIds.slice(1)
          : categoryTagIds,
      QuestionID:
        questionObject && questionObject.questionID
          ? questionObject.questionID.value
          : 0,
      programTypeID:
        selectedProgramType !== null ? selectedProgramType.value : 0,
      programCategoryID:
        selectedProgramCategory !== null ? selectedProgramCategory.value : 0,
    };

    APIHelper.post(APIURLConstant.SUBMIT_QUESTION, questionObj)
      .then((data) => {
        setToasterMessage({
          showToaster: true,
          errorType:
            data === "Question already exists with selected information."
              ? "error"
              : "success",
          message:
            isCloned &&
            data === "Question already exists with selected information."
              ? "Please select different Category Tags"
              : data,
        });
        if (data !== "Question already exists with selected information.") {
          setLoading(false);
          getAnswerTypes();
          getTagsList();
          getQuestionList(
            null,
            statusCheck,
            0,
            pageSize,
            sortStatusText,
            sortStatus === "ascending" ? "ASC" : "DESC"
          );
          resetState();
          setIsAddQAFormVisible(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while adding a Question",
        });
      });
  };

  //use this function to get question list
  const getQuestionList = (
    searchTextValue,
    statusValue,
    recordCount,
    rowCount = pageSize,
    sortBy,
    sortDirection
  ) => {
    setLoading(true);
    //const recordCount = 0;
    //const rowCount = 10;
    // use searchtext to search question list from question text, tag names or answer types chose while creating question
    // use recordCount to start the list from count user want
    // use rowCount to get those many record set as user want
    // if you pass recordCount = 0, rowCount = 10, it will return first 10 records order by Questionid Desc
    APIHelper.post(APIURLConstant.GET_QUESTIONS_LIST, {
      SearchText: searchTextValue === null ? "" : searchTextValue,
      RecordCount: (recordCount =
        currentPage === 0 ? 0 : currentPage * pageSize),
      RowCount: rowCount,
      Status: statusValue === true ? true : false,
      questionID: 0,
      sortBy: sortBy === null ? "null" : sortBy,
      sortDirection: sortDirection === null ? "null" : sortDirection,
    })
      .then((data) => {
        if (data && data.length > 0) {
          setQuestionList(MethodAndVariable.setQuestion(data));
          setLoading(false);
          setCanNextPage(true);
        } else {
          setQuestionList(data);
          setLoading(false);
          setCurrentPage(0);
          setCanPreviousPage(false);
          setCanNextPage(false);
          setTotalCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const openToaster = () => {
  //   setToasterMessage({
  //     showToaster: true,
  //     errorType: "success",
  //     message: "Create Component button clicked",
  //   });
  // };

  //Pagination Events
  useEffect(() => {
    getQuestionList(
      searchText.length > 0 ? searchText : null,
      statusCheck,
      currentPage * 10,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
  }, [currentPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (questionList.length > 0) {
      setTotalCount(questionList[0].totalCount.value);
    }
  }, [questionList]);

  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleChangeProgramType = (value) => {
    errorMessage.programTypeError = false;
    errorMessage.categoryTagError = false;

    setSelectedProgramType(value);

    const updatedcategory = [
      {
        label: `${selectedProgramCategory.label} | ${value.label}`,
        value: `${selectedProgramCategory.label} | ${value.label}`,
        __isNew__: true,
      },
    ];
    setSelectedTags(updatedcategory);
    setValue(updatedcategory);
  };
  const handleChangeProgramCategory = (value) => {
    setSelectedProgramCategory(value);
    setSelectedTags([]);
    setValue([]);
  };
  const handleSort = (name) => {
    if (sortStatus === "normal" || sortStatusText !== name) {
      setSortStatus("ascending");
      setSortStatusText(name);
    } else if (sortStatus === "ascending") {
      setSortStatus("descending");
      setSortStatusText(name);
    } else if (sortStatus === "descending") {
      setSortStatus("ascending");
      setSortStatusText(name);
    }
  };
  useEffect(() => {
    if (sortStatus && sortStatusText) {
      setSortConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus, sortStatusText]);

  const setSortConfig = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    getQuestionList(
      searchText.length > 0 ? searchText : null,
      statusCheck,
      0,
      pageSize,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    // let sortableItems = [...questionList];

    // if (sortStatus === "ascending" || sortStatus === "descending") {
    //   if (sortStatusText === "QUESTIONS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.questionText.value.toLowerCase() <
    //         b.questionText.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.questionText.value.toLowerCase() >
    //         b.questionText.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setQuestionList(dataSorted);
    //   }

    //   if (sortStatusText === "ANSWER FIELD") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.answerType.value.toLowerCase() < b.answerType.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.answerType.value.toLowerCase() > b.answerType.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setQuestionList(dataSorted);
    //   }
    //   if (sortStatusText === "CATEGORY TAGS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.tagList.value.toLowerCase() < b.tagList.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.tagList.value.toLowerCase() > b.tagList.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setQuestionList(dataSorted);
    //   }
    //   if (sortStatusText === "STATUS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.isActive.value.toLowerCase() < b.isActive.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.isActive.value.toLowerCase() > b.isActive.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setQuestionList(dataSorted);
    //   }
    // }
  };

  return (
    <div>
      {/* Loader */}
      <Loader showLoading={loading} />
      <div className="">
        <div className="mx-auto mt-8 md:p-7 p-2">
          {isAddQAFormVisible ? (
            <div>
              <div className="flex justify-between py-3">
                <div className="justify-between flex gap-2 w-full">
                  <p className="text-[#c8102e] font-semibold text-[19px]">
                    {questionObject === null
                      ? utils.string.t("addqa.header")
                      : "Edit Q&A"}
                  </p>
                </div>
              </div>
              <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
                <div className="flex-none lg:flex md:flex md:space-x-4 py-4">
                  <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center text-[#394150] md:w-2/12  w-full font-bold">
                    {utils.string.t("addqa.addQuestionLabel")} :{" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full lg:w-10/12 md:w-10/12">
                    <TextareaAutosize
                      type="text"
                      id="qustion"
                      value={question}
                      maxRows={2}
                      onChange={(e) => handleChangeQuestion(e.target.value)}
                      placeholder="Enter the question here"
                      className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
             ${
               errorMessage && errorMessage.questionError
                 ? "border-red-400"
                 : "border-grey-300"
             } text-gray-900 rounded pl-2`}
                    />
                    {errorMessage.questionError && (
                      <div className="text-start">
                        <span className="text-red-500 text-sm">
                          Please enter the question
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none lg:flex md:flex-none w-full ">
                  <div className="w-full lg:w-6/12 flex-none md:flex md:space-x-4 py-4 ">
                    <p className="text-sm pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-2/12 text-[#394150] w-full font-bold">
                      {utils.string.t("addqa.answerTypeLabel")} :{" "}
                      <span className="text-red-500">*</span>
                    </p>
                    <div className="w-full lg:w-4/6 md:w-10/12 ">
                      <select
                        value={answerValue === null ? "DEFAULT" : answerValue}
                        id="ansType"
                        className={`border h-10   ${
                          errorMessage && errorMessage.ansTypeError
                            ? " border-red-400"
                            : "border-grey-400"
                        } w-full   text-gray-900 rounded px-2 focus:outline-none`}
                        onChange={(e) => {
                          handleChangeAnswerType(e.target.value);
                        }}
                      >
                        <option value="DEFAULT" disabled selected>
                          Select Answer Type
                        </option>
                        {answerType &&
                          answerType.map((item) => (
                            <option value={item.answerTypeID}>
                              {item.type}
                            </option>
                          ))}
                      </select>
                      {errorMessage.ansTypeError && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please choose the Answer Type
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 flex-none md:flex md:space-x-4 py-4 ">
                    <div className=" w-2/12 lg:hidden md:block hidden" />

                    {!isAnswerOptions ? null : (
                      <>
                        {/* <input
                    type="text"
                    value={ansTypeText}
                    onChange={(e) => handleChangeAnsTypeText(e.target.value)}
                    id="ansTypeValue"
                    placeholder="Enter the Answer Options"
                    className={`h-7 w-full focus:outline-none focus:shadow-outline border ${
                      errorMessage && errorMessage === "ansTypeTextError"
                        ? "border-red-400"
                        : "border-grey-300"
                    } p-2 text-gray-900 rounded `}
                  /> */}
                        <AutoComplete
                          tagSuggestion={[]}
                          createOption={createOption}
                          inputValue={ansOptionsInputValue}
                          setInputValue={setAnsOptionsInputValue}
                          value={ansOptionsValue}
                          setValue={setAnsOptionsValue}
                          onChange={handleAnswerOptions}
                          className={` ${
                            errorMessage.ansTypeTextError === true
                              ? "border border-red-400"
                              : ""
                          } rounded w-full lg:w-full  md:w-10/12`}
                          placeholder={"Please enter Answer Options here."}
                          styles={colourStyles}
                        />
                        {errorMessage.ansTypeTextError && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please Enter the Answer Options
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex-none lg:flex md:flex-none w-full ">
                  <div className="w-full lg:w-6/12 flex-none md:flex md:space-x-4 py-4 ">
                    <p className="text-sm  pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-2/12 text-[#394150] w-full font-bold">
                      Program Category:
                    </p>
                    <div className="w-full lg:w-4/6 md:w-10/12 ">
                      <Select
                        value={selectedProgramCategory}
                        options={programCategory}
                        className="w-full focus:outline-none focus:shadow-outline text-gray-900 rounded"
                        placeholder="Select Program Category"
                        onChange={(e) => handleChangeProgramCategory(e)}
                      />
                    </div>
                  </div>
                  {selectedProgramCategory && selectedProgramCategory.value && (
                    <div className="w-full lg:w-6/12 flex-none md:flex md:space-x-4 py-4 ">
                      <p className="text-sm   pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-2/12 text-[#394150] w-full font-bold">
                        Program Type: <span className="text-red-700">*</span>
                      </p>
                      <div className="w-full lg:w-4/6 md:w-10/12 ">
                        <Select
                          value={selectedProgramType}
                          options={programType}
                          isDisabled={disableProgramType}
                          className={` w-full focus:outline-none focus:shadow-outline${
                            errorMessage.programTypeError === true
                              ? "border border-red-400"
                              : ""
                          } text-gray-900 rounded`}
                          placeholder="Select Program Type"
                          onChange={(e) => {
                            handleChangeProgramType(e);
                          }}
                        />
                        {errorMessage.programTypeError && (
                          <span className="text-red-500 text-sm">
                            Please select the program type
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-none lg:flex md:flex md:space-x-4 py-4">
                  <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center  text-[#394150] md:w-2/12  w-full font-bold">
                    {utils.string.t("addqa.categoryTagsLabel")} :{" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full  lg:w-10/12 md:w-10/12">
                    <div className="flex-none lg:flex gap-3">
                      <AutoComplete
                        tagSuggestion={categoryTags}
                        createOption={createOption}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        value={value}
                        disable={disableCategory}
                        isCloseOutsideClick={true}
                        setValue={setValue}
                        className={`${
                          errorMessage.categoryTagError === true
                            ? "border border-red-400"
                            : ""
                        } rounded w-full lg:w-2/5`}
                        onChange={handleChangeCategoryTag}
                        placeholder={"Please Type tagname and select..."}
                        styles={colourStyles}
                      />
                      {errorMessage.categoryTagError && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please select the Category Tags
                          </span>
                        </div>
                      )}
                      <div className="lg:mt-0 mt-3 lg:flex-none flex justify-between flex-wrap">
                        <div className="inline-flex items-center  ">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value={isMandatory}
                            checked={isMandatory}
                            onChange={(e) => setIsMandatory(e.target.checked)}
                            className="w-4 h-6  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="default-checkbox"
                            className="ml-2 text-sm font-medium text-blue-900 mx-2 font-bold"
                          >
                            Required
                          </label>
                        </div>
                        {!isEditMode && (
                          <div className="inline-flex lg:items-center ">
                            <span className=" text-sm  lg:p-0 md:p-0 lg:text-end text-center text-blue-900 mx-2 font-bold">
                              {isActive ? "Active" : "InActive"}
                            </span>
                            <label className="inline-flex relative items-center  w-11">
                              <input
                                type="checkbox"
                                checked={isActive}
                                value={isActive}
                                className="sr-only peer cursor-pointer"
                                onChange={(e) => setIsActive(e.target.checked)}
                              />
                              <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4 py-4">
                  <div className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 md:w-2/12 font-bold" />
                  <ButtonContainer
                    handleSubmit={() => handleSubmit()}
                    ShowClone={isEditMode}
                    disableCloneBtn={isCloned}
                    handleClone={() => handleClone()}
                    handleCancel={() => {
                      resetState();
                      setIsAddQAFormVisible(false);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="flex justify-between py-3">
                <p className="text-[#c8102e] font-semibold text-[19px]">
                  {utils.string.t("addqa.tableHeader")}
                </p>
              </div>
              <div className="grid grid-cols-1  lg:grid-cols-2 gap-2 pb-4">
                <div className="flex gap-2">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full h-10 focus:outline-none focus:shadow-outline border px-2 border-grey-300 rounded "
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && searchText?.length > 0) {
                        handleSearchQuestionList();
                      }
                    }}
                  />
                  <div className="inline-flex items-center">
                    <label className="inline-flex relative items-center  w-11">
                      <input
                        type="checkbox"
                        checked={statusCheck}
                        value={statusCheck}
                        className="sr-only peer cursor-pointer"
                        onChange={(e) =>
                          handleStatusCheckChange(e.target.checked)
                        }
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    <span className="mx-2">
                      {statusCheck ? "Active" : "InActive"}
                    </span>
                  </div>
                </div>
                <div className="md:justify-between items-center flex-wrap flex gap-4">
                  {/* <button
                onClick={() => {
                  getQuestionList();
                }}
              >
                View Question List
              </button> */}
                  <div className="flex gap-3 flex-wrap">
                    <button
                      disabled={!searchText}
                      className={
                        searchText
                          ? "bg-blue-900  p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                          : "bg-gray-400  p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      }
                      onClick={() => handleSearchQuestionList()}
                    >
                      Search
                    </button>
                    <button
                      className={
                        searchText.length > 0
                          ? "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                          : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      }
                      onClick={() => {
                        if (searchText.length > 0) {
                          resetFilter();
                        }
                      }}
                      data-te-toggle="tooltip"
                      title="Reset filter"
                    >
                      Reset
                    </button>
                  </div>
                  <div className="flex-wrap flex gap-2">
                    <button
                      type="button"
                      onClick={() => handleAddQA()}
                      className="bg-blue-900 p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-white"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete()}
                      className="bg-blue-900 p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-white"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <Table
                data={questionList}
                column={column}
                isDeleteButton={true}
                handleClickEdit={handleClickEdit}
                handleDeleteUser={handleDeleteUser}
                setTableData={setTableData}
                handleOpenTagList={handleOpenTagList}
                onClickSelectAll={onClickSelectAll}
                onSelectTableRow={onSelectTableRow}
                sortStatusText={sortStatusText}
                sortStatus={sortStatus}
                handleSort={handleSort}
                sourceName={"Question"}
              />
              <Pagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                totalCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                setCanNextPage={setCanNextPage}
                setCanPreviousPage={setCanPreviousPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>

      {/* Modal Popup */}
      <Modal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        modalMessage={modalMessage}
        handleClick={
          modalMessage.title === "Edit"
            ? handleEditConfirm
            : modalMessage.title === "Multiple Delete"
            ? handleMultiDeteConfirm
            : modalMessage.title === "Clone"
            ? handleCloneConfirm
            : handleDeletConfirm
        }
      />

      {/* TagList Popup */}
      <Modal
        showModal={showTagModal}
        handleClose={() => setShowTagModal(false)}
        isTagList={true}
        data={selectedListOfTags}
        title={"Tag List"}
      />

      {/* Toaster */}

      <Toaster
        toasterMessage={toasterMessage}
        setToasterMessage={setToasterMessage}
      />
    </div>
  );
};

export default AddQA;
