import React from "react";
import TimeExpiration from "../../assets/time-expiration-icon.svg";

const AccountExpired =(props) => {
    return (
        <>
            <div className=" text-center mx-auto md:p-7 p-2 overflow-x-hidded" style={{padding: '15%'}}>
                <div className="time-expire-icon">
                    <img
                        src={TimeExpiration}
                        className="h-[5rem] w-full align-center justify-center items-center time-expire-icon "
                        alt="TimeExp"
                    />
                </div>
                <div className="mt-4">
                    Your account has expired. Please contact Huddle support team (huddle@nba.com) to reactivate it.
                </div>
            </div>
        </>
    );
}

export default AccountExpired;