import React , { useState, useRef } from "react";
import CloseIcon from "../../assets/close-blue.svg";
import TagIcon from "../../assets/tag-button.svg";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import DatePicker from "react-datepicker";

Modal.defaultProps = {
  isModalVisible: false,
};

export default function Modal(props) {
  // const { title, message } = props.modalMessage;
  const [expirationDate, setExpirationDate] = useState(null);
  const expirationDateRef = useRef(null);
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });

  const handleExpirationDateClick = (e)=> {
    if (expirationDate !== null){
      setExpirationDate(null)
      props.handleClick(expirationDate)
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select Date!",
      });
    }
  };

  return (
    <>
      {props.showModal ? (
        <>
          {props.isCalenderModal !== undefined && props.isCalenderModal === true ? (
            <div>
              <>
            <div
              id="medium-modal"
              tabIndex="-1"
              className="justify-center md:ustify-center md:items-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none"
            >
              <div className="relative w-full h-full max-w-lg md:h-auto">
                <div className="relative bg-white rounded-lg shadow-xl dark:bg-gray-900">
                <div className="flex items-start opacity-6 justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-2xl font-semibold">
                      {props.modalMessage.title}
                    </h3>
                    <button
                      className="p-1 ml-auto outline-none focus:outline-none"
                      onClick={() => props.handleClose()}
                    >
                      <img
                        src={CloseIcon}
                        className="h-15 w-5 transparent "
                        alt="close"
                      />
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative flex-auto text-center">
                    <p className="mt-4 text-slate-500 text-lg leading-relaxed">
                      {props.modalMessage.message}
                    </p>
                  </div>
                  <div className="p-4 flex flex-wrap">
                  <div className="md:w-12/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
                      <p className="text-sm pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Expiration Date :
                      </p>
                      <div className="w-full lg:w-3/6">
                      <div className="flex gap-2 ">
                      <DatePicker
                        className="h-9 w-full pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                        selected={expirationDate}
                        placeholderText={"MM/DD/YYYY"}
                        onChange={(date) =>
                          setExpirationDate(date)
                        }
                        ref={expirationDateRef}
                        wrapperClassName="w-full"
                        //minDate={new Date()}
                        //dateFormat="MM/dd/yyyy"
                        dateFormat="dd MMMM yyyy"
                        // minDate={
                        //   new Date()
                        // }
                      />
                      <button
                        className="text-blue-900"
                        onClick={() => expirationDateRef.current.setOpen(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 mt-2"
                        >
                          <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                          <path
                            fillRule="evenodd"
                            d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                          type="button"
                          onClick={() => props.handleClose()}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-900 h-8 p-1 w-2/6 font-semibold rounded-md text-white"
                          type="button"
                          onClick={(e) => handleExpirationDateClick(e)}
                        >
                          Submit Expiration Date
                        </button>
                      </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            <Toaster
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />
        </>
            </div>
          ):
          props.isTagList !== undefined && props.isTagList === true ? (
            <div
              id="medium-modal"
              tabIndex="-1"
              className="justify-center md:ustify-center md:items-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none"
            >
              <div className="relative w-full h-full max-w-lg md:h-auto">
                <div className="relative bg-white rounded-lg shadow-xl dark:bg-gray-900">
                  <div className="flex items-center justify-between py-2 px-4 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-medium text-blue-900 dark:text-white">
                      {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto outline-none focus:outline-none"
                      onClick={() => props.handleClose()}
                      data-te-toggle="tooltip"
                      title="Close"
                    >
                      <img
                        src={CloseIcon}
                        className="h-15 w-5 transparent "
                        alt="close"
                      />
                    </button>
                  </div>
                  <div className="p-4 flex flex-wrap">
                    {props.data !== undefined &&
                      props.data.length > 0 &&
                      props.data.map((tags) => {
                        return (
                          <p className=" bg-[#394150] mr-2 w-fit mb-2 flex rounded">
                            <span className="px-2 mx-1  whitespace-nowrap text-white text-sm font-weight-700">
                              {tags}
                            </span>
                            <img
                              src={TagIcon}
                              className="h-15 w-5 mr-1 "
                              alt="Tag"
                            />
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none">
              <div className="relative mx-auto">
                {/*content*/}
                <div className="rounded-lg shadow-2xl flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start opacity-6 justify-between px-3 py-3 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-2xl font-semibold">
                      {props.modalMessage.title}
                    </h3>
                    <button
                      className="p-1 ml-auto outline-none focus:outline-none"
                      onClick={() => props.handleClose()}
                    >
                      <img
                        src={CloseIcon}
                        className="h-15 w-5 transparent "
                        alt="close"
                      />
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto text-center">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      {props.modalMessage.message}
                    </p>
                  </div>
                  {/*footer*/}
                  <div>
                    {props.showOkayButton ? (
                      <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                          type="button"
                          onClick={() => props.handleClose()}
                        >
                          Okay
                        </button>
                      </div>
                    ) : (
                      <div className="flex space-x-4 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="border h-8 border-blue-900 w-2/6 text-blue-900 font-semibold p-1 rounded-md"
                          type="button"
                          onClick={() => props.handleClose()}
                        >
                          NO
                        </button>
                        <button
                          className="bg-blue-900 h-8 p-1 w-2/6 font-semibold rounded-md text-white"
                          type="button"
                          onClick={() => props.handleClick()}
                        >
                          YES
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
