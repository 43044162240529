import React, { useEffect, useState } from "react";
// import AzureConstant from '../../config/authConfigConstant';
// import ReportConstant from '../../config/reportConfigConstant';
import TableReport from "../../sharedComponent/Reports/tablereport";
import Helper from "../../utils/config/helper";
import { useLocation } from "react-router-dom";
import Reports from "../../sharedComponent/Reports/Report";

const Report = () => {
  const location = useLocation();
  const [URL, setURL] = useState(null);

  // let REPORT_URL = ReportConstant.LOCAL_REPORT_URL
  // if (window.location.href.includes(AzureConstant.DEV_APP)) {
  //   REPORT_URL = ReportConstant.DEV_REPORT_URL;
  // } else if (window.location.href.includes(AzureConstant.QA_APP)) {
  //   REPORT_URL = ReportConstant.QA_REPORT_URL;
  // }
  let REPORT_URL = Helper.getStorage("reporturl");

  const MEDICAL_MODULE_URL = window.location.href.includes(
    process.env.REACT_APP_QA_APP
  )
    ? "https://qa-dwhtab.nba.com/t/Site03-QA/views/MedicalModuleReport-QA/MedicalModuleReport"
    : window.location.href.includes(process.env.REACT_APP_PROD_APP_ALTER)
    ? "https://dwhtab.nba.com/t/Site03/views/MedicalModuleReport/MedicalModuleReport"
    : "https://qa-dwhtab.nba.com/t/Site03-QA/views/MedicalModuleReport/MedicalModuleReport";

  const SECURITY_MODULE_URL = window.location.href.includes(
    process.env.REACT_APP_QA_APP
  )
    ? "https://qa-dwhtab.nba.com/t/Site03-QA/views/SecurityModuleReport-QA/SecurityModuleReport"
    : window.location.href.includes(process.env.REACT_APP_PROD_APP_ALTER)
    ? "https://dwhtab.nba.com/t/Site03/views/SecurityModuleReport/SecurityModuleReport?:iid=2"
    : "https://qa-dwhtab.nba.com/t/Site03-QA/views/SecurityModuleReport/SecurityModuleReport";

  const OVER_ALL_PROGRAM_DETAILS = window.location.href.includes(
    process.env.REACT_APP_QA_APP
  )
    ? "https://qa-dwhtab.nba.com/t/Site03-QA/views/OverallProgramDashboard-QA/ProgramCategory"
    : window.location.href.includes(process.env.REACT_APP_PROD_APP_ALTER)
    ? "https://dwhtab.nba.com/t/Site03/views/OverallProgramDashboard/ProgramCategory"
    : "https://qa-dwhtab.nba.com/t/Site03-QA/views/OverallProgramDashboard/ProgramCategory";

  useEffect(() => {
    if (location?.pathname) {
      const updatedURL =
        location.pathname === "/programs/reports"
          ? REPORT_URL
          : location.pathname === "/security-module/reports"
          ? SECURITY_MODULE_URL
          : location?.pathname === "/overall-program-details/reports"
          ? OVER_ALL_PROGRAM_DETAILS
          : MEDICAL_MODULE_URL;
      setURL(updatedURL);
    }
  }, [location?.pathname]);
  return (
    <div className="mt-9">
      <div className="mx-auto md:p-7 p-2 overflow-x-hidded">
        <div>
          <p className="text-[#c8102e] font-semibold text-[19px] py-3">
            {location.pathname === "/programs/reports"
              ? "Program Reports"
              : location.pathname === "/security-module/reports"
              ? "Security Module Reports"
              : location.pathname === "/overall-program-details/reports"
              ? " Overall Program Details"
              : "Medical Module Reports"}
          </p>
        </div>
        <div className="mt-3">{URL !== null && <Reports url={URL} />}</div>
      </div>
    </div>
  );
};

export default Report;
