import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../sharedComponent/Loader/Loader";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const ResourceListView = (props) => {
  const [path, setPath] = useState([]);
  const [link, setLink] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const iframeRef = useRef(null);
  const interval = useRef();

  useEffect(() => {
    if (props && props.selectedPath !== undefined && path.length === 0) {
      let newPath = [];
      newPath.push({ uri: props.selectedPath });

      setLink(encodeURIComponent(props.selectedPath));
      setPath(newPath);
    }
  }, [props]);

  //const pdfUrl = createGdocsUrl(url);

  const clearCheckingInterval = () => {
    clearInterval(interval.current);
  };

  const onIframeLoaded = () => {
    clearCheckingInterval();
    setLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      try {
        setLoading(true);
        // google docs page is blank (204), hence we need to reload the iframe.
        if (iframeRef.current.contentWindow.document.body.innerHTML === "") {
          if (link === "") {
            const linkPath = encodeURIComponent(props.selectedPath);
            iframeRef.current.src = `https://docs.google.com/viewer?url=${linkPath}&embedded=true`;
            //setLoading(false)
          } else {
            iframeRef.current.src = `https://docs.google.com/viewer?url=${link}&embedded=true`;
            setLoading(false);
          }
        }
      } catch (e) {
        // google docs page is being loaded, but will throw CORS error.
        // it mean that the page won't be blank and we can remove the checking interval.
        onIframeLoaded();
      }
    }, 2000); // 2000ms is reasonable time to load 2MB document

    return clearCheckingInterval;
  }, []);

  return (
    <div className="justify-center md:justify-center md:items-center backdrop-brightness-75 h-full  md:items-center flex overflow-x-hidden overflow-y-auto fixed left-0 top-0 right-0 z-[10000] outline-none focus:outline-none">
      <Loader showLoading={loading} />
      <div
        data-te-modal-dialog-ref
        className="relative w-full lg:w-4/5 h-full md::h-4/5 md:h-auto bg-white "
      >
        <div class="relative p-0 md:p-4 ">
          {link.length > 0 ? (
            // <DocViewer documents={path} pluginRenderers={DocViewerRenderers} />
            <iframe
              title="docview"
              ref={iframeRef}
              onLoad={onIframeLoaded}
              src={`https://docs.google.com/viewer?url=${link}&embedded=true`}
              className="w-full h-[550px] lg:h-[550px]"
            ></iframe>
          ) : null}

          <div class="flex justify-end md:border-2 border-neutral-100  border-opacity-100 p-4 bg-white">
            <button
              onClick={() => props.handleCloseView()}
              className="bg-white p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-blue-900 border border-blue-900"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResourceListView;
