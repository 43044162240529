import React from "react";

const ButtonContainer = (props) => {
  return (
    <>
      <div className="flex space-x-4 w-full md:w-9/12  justify-between md:justify-start">
        {props.ShowClone !== undefined && props.ShowClone === true ? (
          <button
            type="button"
            className={`${
              props.disableCloneBtn ? "bg-[#ccc]" : "bg-green-700  text-white"
            } p-1 px-[1rem] h-[2.2rem] rounded-[3px]`}
            onClick={() => props.handleClone()}
            disabled={props.disableCloneBtn}
          >
            Clone
          </button>
        ) : null}
        <button
          className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
          onClick={() => props.handleSubmit()}
          data-galabel={`${props.sourceName === 'Create-Resource' ? 'Create-Resource' : ''}`}
        >
          Save
        </button>

        <button
          type="button"
          className="bg-white p-1 px-[1rem] h-[2.2rem] rounded-[3px] text-blue-900 border border-blue-900"
          onClick={() => props.handleCancel()}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
export default ButtonContainer;
