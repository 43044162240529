import { useEffect, useState } from "react";
import TableView from "./Table.view";

Table.defaultProps = {
  data: [],
  column: [],
};

export default function Table(props) {
  const [isAllSelected, setAllSelected] = useState(false);
  useEffect(() => {
    if (props && props?.data != null) {
      let isAllSelectedValue = [];
      props?.data?.forEach((item) => {
        if (item?.isSelected?.value == true) {
          isAllSelectedValue.push(item);
        }
      });

      setAllSelected(isAllSelectedValue?.length == props?.data?.length);
    }
  }, [props]);

  return (
    <TableView
      tableData={props.data}
      column={props.column}
      onClickSelectAll={props.onClickSelectAll}
      onSelectTableRow={props.onSelectTableRow}
      setAllSelected={setAllSelected}
      isAllSelected={isAllSelected}
      handleClickEdit={props.handleClickEdit}
      handleClickView={props.handleClickView}
      handleOpenModule={props.handleOpenModule}
      showCheckboxInHeader={
        props.showCheckboxInHeader ? props.showCheckboxInHeader : true
      }
      {...props}
    />
  );
}
