import React, { useEffect, useState, useRef } from "react";
import { AutoComplete, Modal, Table } from "../../sharedComponent";
import Pagination from "../../sharedComponent/Pagination/Pagination";
import Loader from "../../sharedComponent/Loader/Loader";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { useContext } from "react";
import { AppContext } from "../../store/context";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import UnauthorizedUI from "../../sharedComponent/AccessComponents/unauthorized";
import Helper from "../../utils/config/helper";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import moment from "moment";
import AccountExpired from "../../sharedComponent/AccessComponents/AccountExpired"

const UserList = () => {
  const {
    userDetails,
    dispatchRegions,
    userRegions,
    dispatchUserRoles,
    userRoles,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [statusCheck, setStatusCheck] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(Helper.getStorage("pageSize"));
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const [userList, setUserList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tableData, setTableData] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [disableRegionCountry, setDisableRegionCountry] = useState(false);
  const [roleInputValue, setRoleInputValue] = useState("");
  const [roleValue, setRoleValue] = useState([]);
  const [roleValueFilter, setRoleValueFilter] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [userID, setUserID] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isdeleteMode, setIsDeleteMode] = useState(false);
  const [userSelectedForDelete, setUserSelectedForDelete] = useState({});
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [isCalenderModal, setIsCalenderModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    firstName: false,
    lastName: false,
    email: false,
    contactNo: false,
    country: false,
    region: false,
    role: false,
  });
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [userSelectedForEdit, setUserSelectedForEdit] = useState({});
  // const multiselectRef = useRef();
  const isAccessible = userDetails && userDetails.roleIDList === "1" ? 1 : -1;
  const [sortStatus, setSortStatus] = useState("normal");
  const [sortStatusText, setSortStatusText] = useState("");
  const [regionFilters, setRegionFilters] = useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState(null);
  const expirationDateRef = useRef(null);

  const column = [
    { heading: "SR.NO", value: "checkbox", width: "w-11" },
    { heading: "First Name", value: "firstName", width: "w-40" },
    { heading: "Last Name", value: "lastName", width: "w-40" },
    { heading: "Email", value: "email", width: "w-40" },
    { heading: "Expiration Date", value: "expirationDate", width: "w-30" },
    {
      heading: "Contact",
      value: "contactNumber",
      width: "w-20",
      noSorting: true,
    },
    { heading: "Region", value: "regionNames", width: "w-30", noSorting: true },
    { heading: "Role", value: "roleList", width: "w-30" },
    { heading: "STATUS", value: "isActive", width: "w-30", noSorting: true },
    { heading: "ACTION", value: "action", width: "w-30", noSorting: true },
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#0D47A1",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFFFFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#0D47A1",
        color: "white",
      },
    }),
  };

  const [notificationObject, setNotificationObject] = useState({
    programEmailNotification: true,
    programAppNotification: true,
    commentEmailNotification: true,
    commentAppNotification: true,
  });

  useEffect(() => {
    if (isAccessible === 1) {
      let region =
        regionFilters.length > 0
          ? regionFilters
              .map((item) => {
                return item.value;
              })
              .join(",")
          : null;
      let roleId = selectedUserRole !== null ? selectedUserRole?.roleID : null;
      getUserList(
        searchText.length > 0 ? searchText : null,
        currentPage * 10,
        statusCheck,
        pageSize,
        region,
        roleId,
        null,
        sortStatusText,
        sortStatus === "ascending" ? "ASC" : "DESC"
      );
    }
  }, [currentPage, pageSize, isAccessible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAccessible === 1) {
      getUsersRoles();
      getRegionsList();
    }
  }, [isAccessible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userList.length > 0) {
      setTotalCount(userList[0].totalCount.value);
    }
  }, [userList]);

  useEffect(() => {
    if (region !== null && region && region?.length > 0) {
      if (region && region !== null && Object.keys(region)?.length !== 0) {
        getCountriesList();
      }
    } else {
      setCountriesList([]);
      setCountry(null);
    }
  }, [region]);
  const getCountriesList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAMCOUNTRIES_BY_REGION_IDS, {
      RegionIDs: region
        ?.map((item) => {
          return item.value;
        })
        .join(","),
    })
      .then((res) => {
        if (res) {
          const programCountries = res.map((item) => {
            return {
              value: item.countryID,
              label: item.countryName,
            };
          });
          if (programCountries && programCountries.length > 0) {
            setCountriesList([...programCountries]);
          }

          if (region !== null && region?.length !== regionsList?.length) {
            const regionData = region.filter((item) => {
              if (item.value === 3) {
                return item;
              } else if (item.value === 6) {
                return item;
              } else if (item.value === 9) {
                return item;
              } else if (item.value === 10) {
                return item;
              }
            });
            if (regionData !== undefined && regionData?.length > 0) {
              setCountry(programCountries[0]?.value);
            }
          } else {
            setCountry(null);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const setMultipleRegions = (e) => {};

  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleCountryChange = (phone, country) => {
    setSelectedCountry(country ? country.countryCode : "us");
  };

  const onClickSelectAll = (e, isAllSelected) => {
    e.stopPropagation();
    const rowData = userList?.map((ele) => {
      if (isAllSelected) {
        return { ...ele, ...{ isSelected: { value: false } } };
      } else {
        return { ...ele, ...{ isSelected: { value: true } } };
      }
    });
    setUserList(rowData);
  };

  const onSelectTableRow = (e, object) => {
    e.stopPropagation();
    const SelectedData = userList?.map((ele) => {
      if (ele.userID.value === object.userID.value) {
        return { ...ele, ...{ isSelected: { value: !ele?.isSelected.value } } };
      } else {
        return ele;
      }
    });
    setUserList(SelectedData);
  };
  const handleClickEdit = (item) => {
    //Defect BOVS-18406:
    setIsCalenderModal(false);
    //
    setShowModal(true);
    setUserSelectedForEdit(item);
    setModalMessage({
      title: "Edit",
      message: "Do you want to Edit this User ?",
    });
  };

  const handleEditConfirm = () => {
    setIsEditMode(true);
    setShowModal(false);
    setErrorMessage({
      firstName: false,
      lastName: false,
      email: false,
      contactNo: false,
      country: false,
      region: false,
      role: false,
    });
    setIsCreateUser(true);
    setFirstName(userSelectedForEdit?.firstName?.value);
    setLastName(userSelectedForEdit?.lastName?.value);
    setEmail(userSelectedForEdit?.email?.value);
    setExpirationDate(userSelectedForEdit?.expirationDate?.value ? new Date(userSelectedForEdit?.expirationDate?.value) : Date.parse(null))
    setCountry(userSelectedForEdit?.countryID?.value);
    const ids = userSelectedForEdit?.regionIDs?.value?.split(",");
    const selectedRegions = regionsList?.filter((item) => {
      return ids?.includes(item?.value?.toString());
    });

    setRegion(selectedRegions);

    if (
      userSelectedForEdit?.roleList?.value != null &&
      userSelectedForEdit?.roleList?.value?.length > 0
    ) {
      const roleLists = userSelectedForEdit?.roleList?.value
        ?.split(";")
        ?.map((item) => {
          return {
            label: item,

            value: item,
            roleID: parseInt(userSelectedForEdit?.roleListIDs?.value),
          };
        });
      setRoleValue(roleLists[0]);
    }
    // setSelectedUserRole({
    //   isActive: true,
    //   isDeleted: false,
    //   label: userSelectedForEdit?.roleList?.value,
    //   roleID: userSelectedForEdit?.roleListIDs?.value,
    //   roleName: userSelectedForEdit?.roleList?.value,
    //   value: userSelectedForEdit?.roleList?.value,
    // });
    let number = userSelectedForEdit?.contactNumber?.value;

    let numberNew = number !== null ? number.split("+").join("") : null;
    console.log(numberNew);
    setContactNo(numberNew);
    setSelectedCountry(userSelectedForEdit?.contactCountry?.value ?? "us");
    setIsActive(userSelectedForEdit?.isActive?.value);
    setUserID(userSelectedForEdit?.userID?.value);
    setShowModal(false);
    setNotificationObject({
      programEmailNotification:
        userSelectedForEdit?.programEmailNotification.value,
      programAppNotification: userSelectedForEdit?.programAppNotification.value,
      commentEmailNotification:
        userSelectedForEdit?.commentEmailNotification.value,
      commentAppNotification: userSelectedForEdit?.commentAppNotification.value,
    });
  };

  // multi expiration confirm
  const handleMultiExpireConfirm = (value) => {
    setShowModal(false);
    setIsCalenderModal(false);
    const startDateMonth =
        (new Date(value)?.getMonth() + 1).toString()
          .length === 2
          ? new Date(value)?.getMonth() + 1
          : `0${new Date(value)?.getMonth() + 1}`;
        const expirationDateToSubmit = value
        ? `${new Date(
          value
          )?.getFullYear()}-${startDateMonth}-${(
            "0" + new Date(value).getDate()
          ).slice(-2)}`.toString()
        : null;
    let deleteRequests = [];
    // eslint-disable-next-line array-callback-return
    userList.map((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.userID.value);
      }
    });
    if (deleteRequests.length > 0) {
      setLoading(true);

      const payload = {
        UserIDs: deleteRequests.join(","),
        ExpirationDate: expirationDateToSubmit,
      };
      
      APIHelper.post(APIURLConstant.UPDATE_USER_EXPIRATION_DATE, payload)
        .then((res) => {
          setToasterMessage({
            showToaster: true,
            errorType: "success",
            message: "User Updated successfully",
          });
          //Get Updated Users
          let region =
        regionFilters.length > 0
          ? regionFilters
              .map((item) => {
                return item.value;
              })
              .join(",")
          : null;
          let roleId = selectedUserRole !== null ? selectedUserRole?.roleID : null;
          getUserList(
            searchText.length > 0 ? searchText : null,
            currentPage * 10,
            statusCheck,
            pageSize,
            region,
            roleId,
            null,
            sortStatusText,
            sortStatus === "ascending" ? "ASC" : "DESC"
          );
        })
        .catch((err) => {
          console.log(err);
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "Error While Updating User Expiration Date!",
          });
          setLoading(false);
        });
    }
  };

  const handleDeletConfirm = () => {
    setShowModal(false);
    const payload = {
      userID: userSelectedForDelete?.userID?.value,
      firstName: userSelectedForDelete?.firstName?.value,
      lastName: userSelectedForDelete?.lastName?.value,
      email: userSelectedForDelete?.email?.value,
      contactNumber: userSelectedForDelete?.contactNo?.value,
      countryID: userSelectedForDelete?.country?.value,
      regionID: userSelectedForDelete?.region?.value,
      roleID: userSelectedForDelete?.roleID?.value,
      isActive: true,
      isDeleted: true,
    };
    createUser(payload);
  };

  const handleDeleteUser = (item) => {
    setShowModal(true);
    setIsDeleteMode(true);
    setUserSelectedForDelete(item);
    setModalMessage({
      title: "Delete",
      message: "Do you want to delete this User?",
    });
  };

  const handleChangeRegion = (value) => {
    if (value && value.length > 0) {
      setRegionFilters(value);
    } else {
      setRegionFilters(value);
      let roleId = roleValueFilter !== null ? roleValueFilter?.roleID : null;

      getUserList(
        searchText,
        0,
        statusCheck,
        pageSize,
        null,
        roleId,
        null,
        sortStatusText,
        sortStatus === "ascending" ? "ASC" : "DESC"
      );
    }
  };

  const handleStatusChange = (status) => {
    setStatusFilterValue(status);
    // if(status.value === 'Active') {
    //   handleStatusCheckChange(true, false);
    // } else if(status.value === 'InActive') {
    //   handleStatusCheckChange(false, false);
    // } else if(status.value === 'Expired') {
    //   handleStatusCheckChange(true, true);
    // } else {
    //   handleStatusCheckChange(true, false);
    // }
  };

  //Set Expiration Date for selected user
  const handleDelete = () => {
    let deleteRequests = [];
    setIsCalenderModal(true);
    userList.forEach((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.userID.value);
      }
    });

    let messageString = "";
    if (deleteRequests.length !== userList.length) {
      messageString = "Do you want to set Expiration Date for selected User(s) ?";
    } else {
      messageString = "Do you want to set Expiration Date for All Users ?";
    }
    if (deleteRequests.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Set Expiration Date",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select at least one User",
      });
    }
  };

  const handleSearchUserList = () => {
    if (searchText || regionFilters.length > 0 || roleValueFilter !== null || statusFilterValue !== null) {
      let region =
        regionFilters.length > 0
          ? regionFilters
              .map((item) => {
                return item.value;
              })
              .join(",")
          : null;
      let roleId = roleValueFilter !== null ? roleValueFilter?.roleID : null;

      getUserList(
        searchText,
        0,
        (statusFilterValue === null || statusFilterValue === undefined) ? true : statusFilterValue.value === 'InActive' ? false : true,
        pageSize,
        region,
        roleId,
        (statusFilterValue === null || statusFilterValue === undefined) ? false : statusFilterValue.value === 'Expired' ? true : false,
        sortStatusText,
        sortStatus === "ascending" ? "ASC" : "DESC"
      );
    }
  };

  const handleStatusCheckChange = (value, isExpired) => {
    let region =
      regionFilters.length > 0
        ? regionFilters
            .map((item) => {
              return item.value;
            })
            .join(",")
        : null;
    let roleId = selectedUserRole !== null ? selectedUserRole?.roleID : null;
    setStatusCheck(value);
    getUserList(
      searchText.length > 0 ? searchText : null,
      0,
      value,
      pageSize,
      region,
      roleId,
      isExpired,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setContactNo("");
    setSelectedCountry("us");
    setCountry(null);
    setRegion(null);
    setRoleValue([]);
    setExpirationDate("");

    setErrorMessage({
      firstName: false,
      lastName: false,
      email: false,
      contactNo: false,
      country: false,
      region: false,
      role: false,
    });
    setUserID(0);
    setIsActive(true);
    setIsEditMode(false);
    // multiselectRef.current.resetSelectedValues();
  };

  const resetFilter = () => {
    getUserList(null, 0, true, 25, null, null, null, "");
    setSearchText("");
    setStatusCheck(true);
    setSelectedUserRole(null);
    setRoleValueFilter(null);
    setRegionFilters([]);
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    setStatusFilterValue(null);
  };

  const getRegionsList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_PROGRAMREGIONS, { RegionIDs: "null" })
      .then((res) => {
        if (res) {
          const programRegions = res.map((item) => {
            return {
              value: item.regionID,
              label: item.regionName,
              ...item,
            };
          });
          if (programRegions && programRegions.length > 0) {
            setRegionsList([...programRegions]);
            const region = [...programRegions];
            dispatchRegions("ADD_REGIONS", region);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUsersRoles = () => {
    if (userRoles === undefined || userRoles?.length === 0) {
      setLoading(true);
      APIHelper.get(APIURLConstant.GET_USER_ROLES)
        .then((res) => {
          const updatedTags = res.map((item) => {
            return {
              label: item.roleName,
              value: item.roleName,
              ...item,
            };
          });
          if (updatedTags && updatedTags.length > 0) {
            setUserRoleList([...updatedTags]);
            const roles = [...updatedTags];
            dispatchUserRoles("ADD_ROLES", roles);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setUserRoleList([...userRoles]);
    }
  };

  const createUser = (payload) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.POST_CREATE_USER, payload)
      .then((res) => {
        if (res === "User already exists with selected information.") {
          setToasterMessage({
            showToaster: true,
            errorType: "error",
            message: "User already exists with selected information.",
          });
        } else {
          if (isdeleteMode) {
            let region =
              regionFilters.length > 0
                ? regionFilters
                    .map((item) => {
                      return item.value;
                    })
                    .join(",")
                : null;
            let roleId =
              selectedUserRole !== null ? selectedUserRole?.roleID : null;
            getUserList(
              null,
              0,
              statusCheck,
              pageSize,
              null,
              null,
              sortStatusText,
              sortStatus === "ascending" ? "ASC" : "DESC"
            );
            setIsDeleteMode(false);
          } else {
            //No Need this API call now as we are having only one role per user.
            setToasterMessage({
              showToaster: true,
              errorType: "success",
              message:
                modalMessage.title === "Edit"
                  ? "User updated successfully"
                  : modalMessage.title === "delete"
                  ? "User deleted successfully"
                  : "User created successfully",
            });
            setLoading(false);
            setIsCreateUser(false);
            resetForm();
            getUserList(
              null,
              currentPage * 10,
              statusCheck,
              pageSize,
              null,
              null,
              null,
              sortStatusText,
              sortStatus === "ascending" ? "ASC" : "DESC"
            );

            if (userDetails.userID === userID) {
              window.location.reload();
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getUserList = (
    searchTextValue,
    recordCount,
    statusValue,
    rowCount = pageSize,
    regionIds,
    roleId,
    isExpired,
    sortBy,
    sortDirection
  ) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_USERS_LIST, {
      searchText: searchTextValue === null ? "null" : searchTextValue.trim(),
      recordCount: (recordCount =
        currentPage === 0 ? 0 : currentPage * pageSize),
      rowCount: rowCount,
      status: statusValue,
      roleId: roleId ?? 0,
      regionIds: regionIds ?? "null",
      isExpired: isExpired === null ? false : isExpired,
      sortBy: sortBy === null ? "null" : sortBy,
      sortDirection: sortDirection === null ? "null" : sortDirection,
    })
      .then((data) => {
        let dataList = [];
        if (data?.length > 0) {
          data.forEach((item) => {
            dataList.push({
              userID: {
                value: item.userID,
              },
              email: {
                value: item.email,
              },
              firstName: {
                value: item.firstName,
              },
              lastName: {
                value: item.lastName,
              },
              isSelected: {
                value: false,
              },
              contactNumber: {
                value:
                  item.contactNumber !== null ? `+${item.contactNumber}` : null,
              },
              isActive: {
                value: item.isActive,
              },
              address: {
                value: item.address,
              },
              countryID: {
                value: item.countryID,
              },
              city: {
                value: item.city,
              },
              regionIDs: {
                value: item.regionIDs,
              },
              regionNames: {
                value: item.regionNames,
              },
              roleList: {
                value: item.roleList,
              },
              roleListIDs: {
                value: item.roleIDList,
              },
              totalCount: {
                value: item.totalCount,
              },
              programEmailNotification: {
                value: item.programEmailNotification,
              },
              programAppNotification: {
                value: item.programAppNotification,
              },
              commentEmailNotification: {
                value: item.commentEmailNotification,
              },
              commentAppNotification: {
                value: item.commentAppNotification,
              },
              expirationDate: {
                value: item.expirationDate,
              },
              isUserExpired: {
                value: item.isUserExpired,
              },
            });
            // if (data[0].totalCount === 10) {
            //   setCurrentPage(0);
            // }
          });
        } else {
          setCurrentPage(0);
          setCanPreviousPage(false);
          setCanNextPage(false);
          setTotalCount(0);
        }
        setUserList(dataList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const validateEmail = (email) => {
    if (email?.trim()?.length === 0) return true;
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const validatePhone = (number) => {
    var regx = /^\d{10}$/;
    if (regx.test(number)) {
      return false;
    } else {
      return true;
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    if (firstName?.trim()?.length === 0) {
      errorMessage.firstName = true;
      isFormValid = false;
    }
    if (lastName?.trim()?.length === 0) {
      errorMessage.lastName = true;
      isFormValid = false;
    }
    if (validateEmail(email)) {
      errorMessage.email = true;
      isFormValid = false;
    }

    if (roleValue?.length === 0 || roleValue === null) {
      errorMessage.role = true;
      isFormValid = false;
    }
    // if (country === null) {
    //   errorMessage.country = true;
    //   isFormValid = false;
    // }
    // if (region === null) {
    //   errorMessage.region = true;
    //   isFormValid = false;
    // }

    if (contactNo.length === 0) {
      errorMessage.contactNo = true;
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const regionsIds = region
        ?.map((item) => {
          return item.value;
        })
        .join(",");
      const regionNames = region
        ?.map((item) => {
          return item.label;
        })
        .join(",");
        const startDateMonth =
        (new Date(expirationDate)?.getMonth() + 1).toString()
          .length === 2
          ? new Date(expirationDate)?.getMonth() + 1
          : `0${new Date(expirationDate)?.getMonth() + 1}`;
        const expirationDateToSubmit = expirationDate
        ? `${new Date(
            expirationDate
          )?.getFullYear()}-${startDateMonth}-${(
            "0" + new Date(expirationDate).getDate()
          ).slice(-2)}`.toString()
        : null;

      const payload = {
        userID: userID,
        firstName: firstName,
        lastName: lastName,
        email: email,
        expirationDate: expirationDateToSubmit,
        contactNumber: contactNo,
        contactCountry: selectedCountry,
        countryID: country,
        regionID: null,
        regionIDs: regionsIds,
        regionName: regionNames,
        roleID: roleValue?.roleID,
        isActive: isEditMode ? isActive : true,
        isDeleted: false,
        programEmailNotification: notificationObject.programEmailNotification,
        programAppNotification: notificationObject.programAppNotification,
        commentEmailNotification: notificationObject.commentEmailNotification,
        commentAppNotification: notificationObject.commentAppNotification,
      };
      createUser(payload);
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter the required fields",
      });
    }
  };

  const setNotification = (key, value) => {
    const notification = { ...notificationObject };
    notification[key] = !value;
    setNotificationObject(notification);
  };

  const handleSort = (name) => {
    if (name !== "Region" && name !== "Contact") {
      if (sortStatus === "normal" || sortStatusText !== name) {
        setSortStatus("ascending");
        setSortStatusText(name);
      } else if (sortStatus === "ascending") {
        setSortStatus("descending");
        setSortStatusText(name);
      } else if (sortStatus === "descending") {
        setSortStatus("ascending");
        setSortStatusText(name);
      }
    }
  };
  useEffect(() => {
    if (sortStatus && sortStatusText) {
      if (sortStatusText !== "Region" && sortStatusText !== "Contact") {
        setSortConfig();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus, sortStatusText]);

  const setSortConfig = () => {
    setCurrentPage(0);
    setCanPreviousPage(false);
    setCanNextPage(true);
    let region =
      regionFilters.length > 0
        ? regionFilters
            .map((item) => {
              return item.value;
            })
            .join(",")
        : null;
    let roleId = selectedUserRole !== null ? selectedUserRole?.roleID : null;
    getUserList(
      searchText.length > 0 ? searchText : null,
      0,
      statusCheck,
      pageSize,
      region,
      roleId,
      null,
      sortStatusText,
      sortStatus === "ascending" ? "ASC" : "DESC"
    );
    // let sortableItems = [...userList];

    // if (sortStatus === "ascending" || sortStatus === "descending") {
    //   if (sortStatusText === "First Name") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.firstName.value.toLowerCase() < b.firstName.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.firstName.value.toLowerCase() > b.firstName.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setUserList(dataSorted);
    //   }

    //   if (sortStatusText === "Last Name") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.lastName.value.toLowerCase() < b.lastName.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.lastName.value.toLowerCase() > b.lastName.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setUserList(dataSorted);
    //   }
    //   if (sortStatusText === "Email") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.email.value.toLowerCase() < b.email.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.email.value.toLowerCase() > b.email.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setUserList(dataSorted);
    //   }
    //   if (sortStatusText === "Contact") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.contactNumber.value.toLowerCase() <
    //         b.contactNumber.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.contactNumber.value.toLowerCase() >
    //         b.contactNumber.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setUserList(dataSorted);
    //   }
    //   if (sortStatusText === "Region") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (
    //         a.regionNames.value.toLowerCase() <
    //         b.regionNames.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (
    //         a.regionNames.value.toLowerCase() >
    //         b.regionNames.value.toLowerCase()
    //       ) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setUserList(dataSorted);
    //   }
    //   if (sortStatusText === "STATUS") {
    //     const dataSorted = sortableItems.sort((a, b) => {
    //       if (a.isActive.value.toLowerCase() < b.isActive.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? -1 : 1;
    //       }
    //       if (a.isActive.value.toLowerCase() > b.isActive.value.toLowerCase()) {
    //         return sortStatus === "ascending" ? 1 : -1;
    //       }
    //       return 0;
    //     });
    //     setUserList(dataSorted);
    //   }
    // }
  };

  const handleChangeUserRole = (value) => {
    setRegion(null);
    setCountry(null);

    setRoleValue(value);
    setErrorMessage({ ...errorMessage, role: false });
    if (value?.roleID === 2 || value?.roleID === 3 || value?.roleID === 4) {
      let updatedRegionList = [];
      regionsList !== null &&
        regionsList.length > 0 &&
        regionsList?.forEach((item) => {
          if (item.value !== 10) {
            updatedRegionList.push(item);
          }
          setRegionsList(updatedRegionList);
        });
    } else {
      getRegionsList();
    }
  };
  useEffect(() => {
    if (
      selectedUserRole !== null &&
      regionsList !== null &&
      regionsList.length > 0
    ) {
      if (selectedUserRole?.roleID === 6) {
        setDisableRegionCountry(true);
        regionsList?.forEach((item) => {
          if (item.value === 10) {
            setRegion([item]);
          }
        });
      } else {
        setDisableRegionCountry(false);
      }
    }
  }, [regionsList, selectedUserRole]);

  useEffect(() => {
    if (!isEditMode && regionsList && regionsList.length > 0) {
      const usRegion = regionsList.filter((item) => item.value === 10);
      setRegion(usRegion);
    }
  }, [isCreateUser, isEditMode, regionsList]);

  return (
    <div className="mt-16">
       {userDetails?.isUserExpired == true && userDetails?.email != "" ? (
         <AccountExpired />
        ) : userDetails?.email != "" ? (
    <div>
      {isAccessible === 1 ? (
        <div>
          {/* Loader */}
          <Loader showLoading={loading} />
          <div className="mx-auto mt-9 md:p-7 p-2">
            {isCreateUser ? (
              <div>
                <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                  {isEditMode ? "Update User" : "Create User"}
                </p>

                <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
                  <div className="flex-none lg:flex md:flex-none w-full ">
                    <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4 ">
                      <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        First Name:
                      </p>
                      <div className="w-full lg:w-3/6 ">
                        <input
                          type="text"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setErrorMessage({
                              ...errorMessage,
                              firstName: false,
                            });
                          }}
                          placeholder="Please Enter First Name"
                          className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                    ${
                      errorMessage && errorMessage.firstName === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                        />
                        {errorMessage && errorMessage.firstName === true && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please enter the first Name
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4 ">
                      <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Last Name :
                      </p>
                      <div className="w-full lg:w-3/6">
                        <input
                          type="text"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setErrorMessage({
                              ...errorMessage,
                              lastName: false,
                            });
                          }}
                          placeholder="Please Enter last Name"
                          className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                    ${
                      errorMessage && errorMessage.lastName === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                        />
                        {errorMessage && errorMessage.lastName === true && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please enter the last Name
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex-none lg:flex md:flex-none w-full ">
                    <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
                      <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Email:
                      </p>
                      <div className="w-full lg:w-3/6 ">
                        <input
                          type="text"
                          id="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorMessage({ ...errorMessage, email: false });
                          }}
                          placeholder="Please Enter email"
                          className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                    ${
                      errorMessage && errorMessage.email === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                        />
                        {errorMessage && errorMessage.email === true && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please enter the email
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4 ">
                      <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Contact :
                      </p>
                      <div className="w-full lg:w-3/6">
                        <PhoneInput
                          country={selectedCountry}
                          inputStyle={{
                            height: "40px",
                            width: "100%",
                            border:
                              errorMessage.contactNo === true
                                ? "1px solid red"
                                : "1px solid #ccc",
                            backgroundColor: "white",
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.4375em",
                            letterSpacing: "0.00938em",
                          }}
                          value={contactNo}
                          onChange={(phone, country) => {
                            if (phone.trim() === "") {
                              setSelectedCountry("us");
                              phone = "+1";
                            }

                            setContactNo(phone);

                            setErrorMessage({
                              ...errorMessage,
                              contactNo: false,
                            });
                            handleCountryChange(phone, country);
                          }}
                          placeholder="Please Enter contact no"
                        />
                        {/* <input
                          type="text"
                          id="contact"
                          value={contactNo}
                          onChange={(e) => {
                            setContactNo(e.target.value);
                            setErrorMessage({
                              ...errorMessage,
                              contactNo: false,
                            });
                          }}
                          placeholder="Please Enter contact no"
                          className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                    ${
                      errorMessage && errorMessage.contactNo === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                        /> */}
                        {errorMessage && errorMessage.contactNo === true && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please enter the correct contact number
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex-none w-full">
                    <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
                      <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Roles :
                      </p>
                      <div className="w-full lg:w-3/6">
                        <AutoComplete
                          tagSuggestion={userRoleList}
                          inputValue={roleInputValue}
                          isCratable={false}
                          isMulti={false}
                          setInputValue={setRoleInputValue}
                          value={roleValue}
                          className={` ${
                            errorMessage.role === true
                              ? "border border-red-400"
                              : ""
                          } rounded`}
                          setValue={setRoleValue}
                          onChange={(value) => {
                            handleChangeUserRole(value);
                          }}
                          styles={colourStyles}
                          placeholder={"Please Select User Role"}
                        />
                        {errorMessage.role && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please select the user role
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
                      <p className="text-sm pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Region :
                      </p>
                      <div className="w-full lg:w-3/6">
                        <MultiSelect
                          displayValue="label"
                          showCheckbox={true}
                          onChange={(e) => {
                            setRegion(e);
                          }}
                          value={region !== null ? region : []}
                          disabled={disableRegionCountry}
                          options={regionsList}
                          placeholder={"Select Regions..."}
                          // ref={multiselectRef}
                        />
                        {/* <select
                    value={region === null ? "DEFAULT" : region}
                    id="region"
                    className={`textAreaHeight border h-7   ${
                      errorMessage && errorMessage === "region"
                        ? "border-red-400"
                        : "border-grey-300"
                    } w-full h-10  text-gray-900 rounded px-2 focus:outline-none`}
                    onChange={(e) => {
                      setRegion(e.target.value);
                      setErrorMessage({ ...errorMessage, region: false });
                    }}
                  >
                    <option value="DEFAULT" selected>
                      Select Region
                    </option>
                    {regionsList &&
                      regionsList.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                  </select> */}
                        {errorMessage.ansTypeError && (
                          <div className="text-start">
                            <span className="text-red-500 text-sm">
                              Please select region
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex-none w-full">           
                  <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
                    <p className="text-sm required pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                      Country :
                    </p>
                    <div className="w-full lg:w-3/6">
                      <select
                        disabled={disableRegionCountry}
                        value={country === null ? "DEFAULT" : country}
                        id="country"
                        className={`textAreaHeight border h-7 ${
                          errorMessage && errorMessage.country
                            ? "border-red-400"
                            : "border-grey-300"
                        } w-full h-10 text-gray-900 rounded px-2 focus:outline-none`}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          // setErrorMessage({
                          //   ...errorMessage,
                          //   country: false,
                          // });
                        }}
                      >
                        <option value="DEFAULT" disabled selected>
                          Select Country
                        </option>
                        {countriesList &&
                          countriesList.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                      </select>
                      {errorMessage.country && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please select country
                          </span>
                        </div>
                      )}
                    </div>
                    
                  </div>
                  <div className="md:w-12/12 lg:w-6/12 flex-none md:flex lg:flex lg:space-x-4 py-4">
                      <p className="text-sm pb-4 mt-2 lg:p-0 md:p-0 lg:text-end text-center lg:w-2/6 md:w-4/12 text-[#394150] w-full font-bold">
                        Expiration Date :
                      </p>
                      <div className="w-full lg:w-3/6">
                      <div className="flex gap-2 ">
                      <DatePicker
                        className="h-9 w-full pl-1 border focus:outline-none border border-grey-300 focus:shadow-outline border-grey-300 text-gray-900 rounded"
                        selected={expirationDate}
                        placeholderText={"MM/DD/YYYY"}
                        onChange={(date) =>
                          setExpirationDate(date)
                        }
                        ref={expirationDateRef}
                        wrapperClassName="w-full"
                        //minDate={new Date()}
                        //dateFormat="MM/dd/yyyy"
                        dateFormat="dd MMMM yyyy"
                        // minDate={
                        //   new Date()
                        // }
                      />
                      <button
                        className="text-blue-900"
                        onClick={() => expirationDateRef.current.setOpen(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 mt-2"
                        >
                          <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                          <path
                            fillRule="evenodd"
                            d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                      </div>
                    </div>
                  </div>
                  <div style={{
                              marginLeft: "16rem",
                            }} className="flex-none lg:flex md:flex-none w-full">
                  <div className="inline-flex items-center mt-2 md:mt-0">
                      <span className=" text-sm p-0 lg:text-end text-center text-blue-900 mx-2 font-bold">
                        {isActive ? "Active" : "InActive"}
                      </span>
                      <label className="inline-flex relative items-center  w-11">
                        <input
                          type="checkbox"
                          checked={isActive}
                          value={isActive}
                          className="sr-only peer cursor-pointer"
                          onChange={(e) => setIsActive(e.target.checked)}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12  w-full" />
                    <div className="w-full pl-0 md:pl-8">
                      <p className="text-sm pb-2 mt-2 text-[#394150] w-full font-bold border-b-2">
                        Program Notification(Date confirm/publish/Unpublish):
                      </p>
                      <div className="flex-none md:flex justify-between w-full md:w-3/6 pt-3">
                        <div className="flex gap-2 ">
                          <input
                            checked={notificationObject.programAppNotification}
                            onChange={() => {
                              setNotification(
                                "programAppNotification",
                                notificationObject.programAppNotification
                              );
                            }}
                            type={"checkbox"}
                          />
                          <span>Notification</span>
                        </div>

                        <div className="flex gap-2">
                          <input
                            checked={
                              notificationObject.programEmailNotification
                            }
                            onChange={() => {
                              setNotification(
                                "programEmailNotification",
                                notificationObject.programEmailNotification
                              );
                            }}
                            type={"checkbox"}
                          />
                          <span>Email</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12  w-full" />
                    <div className="w-full pl-0 md:pl-8">
                      <p className="text-sm pb-1 mt-2 text-[#394150] w-full font-bold border-b-2">
                        Comment Notification:
                      </p>
                      <div className="flex-none md:flex justify-between w-full md:w-3/6 pt-3">
                        <div className="flex gap-2 ">
                          <input
                            checked={notificationObject.commentAppNotification}
                            onChange={() => {
                              setNotification(
                                "commentAppNotification",
                                notificationObject.commentAppNotification
                              );
                            }}
                            type={"checkbox"}
                          />
                          <span>Notification</span>
                        </div>
                        <div className="flex gap-2 ">
                          <input
                            checked={
                              notificationObject.commentEmailNotification
                            }
                            onChange={() => {
                              setNotification(
                                "commentEmailNotification",
                                notificationObject.commentEmailNotification
                              );
                            }}
                            type={"checkbox"}
                          />
                          <span>Email</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-4 py-4">
                    <div className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end text-center text-blue-900 md:w-2/12 font-bold" />
                    <ButtonContainer
                      handleSubmit={() => handleSubmit()}
                      handleCancel={() => {
                        resetForm();
                        setIsCreateUser(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="2">
                <div className="flex justify-between">
                  <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                    Users List
                  </p>
                </div>
                <div className="w-full flex flex-wrap pb-2">
                  <div className="w-full pb-2 pr-2 md:w-6/12 lg:w-4/12">
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-full h-10 focus:outline-none focus:shadow-outline border px-2 border-grey-300 rounded "
                      onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                    />
                  </div>
                  <div className="w-full pb-2 pr-2 md:w-6/12 lg:w-3/12">
                    <Select
                      displayValue="label"
                      onChange={handleChangeRegion}
                      options={regionsList}
                      value={regionFilters}
                      isClearable={false}
                      isMulti
                      className=""
                    />
                  </div>
                  <div className="w-full pb-2 pr-2 md:w-6/12 lg:w-3/12 flex flex-wrap gap-2">
                    <Select
                      className="w-full"
                      placeholder={"Select User Role"}
                      onChange={(value) => {
                        setRoleValueFilter(value);
                      }}
                      value={roleValueFilter}
                      options={userRoleList}
                    />
                    {/* <AutoComplete
                      tagSuggestion={userRoleList}
                      inputValue={roleInputValue}
                      isCratable={false}
                      isClearable={false}
                      isMulti={false}
                      setInputValue={setRoleInputValue}
                      value={roleValueFilter}
                      className={`w-full ${
                        errorMessage.role === true
                          ? "border border-red-400"
                          : ""
                      } rounded`}
                      setValue={setRoleValueFilter}
                      onChange={(value) => {
                        setSelectedUserRole(value);
                      }}
                      styles={colourStyles}
                      placeholder={"Select User Role"}
                    /> */}
                  </div>
                  <div className="w-full md:w-5/12 lg:w-1/6 md:mt-0 mt-2">
                    <Select
                      value={statusFilterValue}
                      options={[
                        {
                          value: "Active",
                          label: "Active",
                        },

                        {
                          value: "Expired",
                          label: "Expired",
                        },
                        {
                          value: "InActive",
                          label: "InActive"
                        },
                      ]}
                      className=" w-full  focus:outline-none focus:shadow-outline text-gray-900 rounded"
                      placeholder="Status"
                      onChange={(e) => handleStatusChange(e)}
                    />
                  </div>
                  {/* <div className="inline-flex items-center w-full pb-2 pr-2 md:w-6/12 lg:w-1/12">
                    <label className="inline-flex relative items-center  w-11">
                      <input
                        type="checkbox"
                        checked={statusCheck}
                        value={statusCheck}
                        className="sr-only peer cursor-pointer"
                        onChange={(e) => {
                          handleStatusCheckChange(e.target.checked);
                        }}
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    <span className="mx-2">
                      {statusCheck ? "Active" : "InActive"}
                    </span>
                  </div> */}
                </div>
                <div className="w-full pb-4 flex md:justify-between lg:justify-end gap-2 flex-wrap">
                  <div className="flex gap-2">
                  <button
                    className="bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white text-sm"
                    onClick={() => handleDelete()}
                    data-galabel="All Users Delete-User(s)"
                  >
                    Set Expiration Date
                  </button>
                    <button
                      className={
                        searchText ||
                        regionFilters.length > 0 ||
                        roleValueFilter !== null ||
                        statusFilterValue !== null
                          ? "bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                          : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      }
                      onClick={() => {
                        handleSearchUserList();
                      }}
                    >
                      Filter
                    </button>
                    <button
                      className={
                        searchText.length > 0 ||
                        regionFilters.length > 0 ||
                        roleValueFilter !== null ||
                        statusFilterValue !== null
                          ? "bg-[#1D428A] p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                          : "bg-gray-400 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                      }
                      onClick={() => {
                        if (
                          searchText.length > 0 ||
                          regionFilters.length > 0 ||
                          roleValueFilter !== null ||
                          statusFilterValue !== null
                        ) {
                          resetFilter();
                        }
                      }}
                      data-te-toggle="tooltip"
                      title="Reset filter"
                    >
                      Reset
                    </button>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setIsCreateUser(true);
                    }}
                    className="bg-blue-900 p-1 px-[1rem] h-[2.2rem]  rounded-[3px] text-white"
                  >
                    Add User
                  </button>
                </div>
                <Table
                  data={userList}
                  column={column}
                  isDeleteButton={true}
                  handleClickEdit={handleClickEdit}
                  handleDeleteUser={handleDeleteUser}
                  setTableData={setTableData}
                  onClickSelectAll={onClickSelectAll}
                  onSelectTableRow={onSelectTableRow}
                  showCheckboxInHeader={true}
                  sortStatusText={sortStatusText}
                  sortStatus={sortStatus}
                  handleSort={handleSort}
                  sourceName="User"
                />
                <Pagination
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  setCanNextPage={setCanNextPage}
                  setCanPreviousPage={setCanPreviousPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
          <Modal
            showModal={showModal}
            handleClose={() => setShowModal(false)}
            modalMessage={modalMessage}
            isCalenderModal={isCalenderModal}
            handleClick={
              modalMessage.title === "Edit"
                ? handleEditConfirm
                : modalMessage.title === "Delete"
                ? handleDeletConfirm
                : modalMessage.title === "Set Expiration Date"
                ? handleMultiExpireConfirm
                : null
            }
          />
          <Toaster
            toasterMessage={toasterMessage}
            setToasterMessage={setToasterMessage}
          />
        </div>
      ) : (
        <UnauthorizedUI />
      )}
    </div>
     ) : null}
    </div>
  );
};

export default UserList;
