const AzureConstant = {
    LOCAL_APP: 'https://localhost:44334/',
    DEV_APP: 'https://app-ypp-dev-001.azurewebsites.net',
    QA_APP: 'https://app-ypp-qa-001.azurewebsites.net',
    PROD_APP: 'https://huddle.nba.com/',
    PROD_APP_ALTER: 'https://app-ypp-prod-001.azurewebsites.net',
    TENANT_ID: 'e898ff4a-4b69-45ee-a3ae-1cd6f239feb2',
    DEV_CLIENT: '44c7df1a-a07c-42b7-b795-d01494dcb23f',
    QA_CLIENT: '78191b2f-ce3d-4df7-a217-449022eefee6',
    PROD_CLIENT: 'aaf23f59-daeb-4c95-a9b7-e4b67cc1448c',
    REACT_APP_OTHER_CAPTCHA_SITE_KEY: process.env.REACT_APP_OTHER_CAPTCHA_SITE_KEY,
    REACT_APP_PROD_CAPTCHA_SITE_KEY: process.env.REACT_APP_PROD_CAPTCHA_SITE_KEY

};

export default AzureConstant;