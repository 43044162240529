import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

AutoComplete.defaultProps = {
  tagSuggestion: [],
  createOption: () => {},
};

export default function AutoComplete({
  tagSuggestion,
  createOption,
  inputValue,
  isCratable = true,
  setInputValue,
  value,
  setValue,
  onChange,
  placeholder,
  styles,
  isMulti = true,
  disable,
  disableOptions,
  className,
  isCloseOutsideClick,
  isClearable,
}) {
  const [isMenuExpand, setIsMenuExpand] = useState(false);

  const components = {
    DropdownIndicator: null,
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      //case "Enter":
      case "Tab":
        // console.log(inputValue);
        setValue((prev) => [...prev]);
        // setInputValue("");
        setIsMenuExpand(true);
        event.preventDefault();
    }
  };

  return (
    <>
      {isCratable && tagSuggestion ? (
        isCloseOutsideClick !== undefined && isCloseOutsideClick ? (
          <CreatableSelect
            components={components}
            inputValue={inputValue}
            options={tagSuggestion}
            isClearable={isClearable ? isClearable : true}
            isMulti={isMulti}
            // menuIsOpen={isMenuExpand}
            closeMenuOnSelect={true}
            onChange={(newValue) => {
              setValue(newValue);
              onChange(newValue);
            }}
            isDisabled={disable !== undefined ? disable : false}
            isOptionDisabled={(option) =>
              disableOptions !== undefined ? option.value > 0 : false
            }
            className={className}
            onInputChange={(newValue, actionMeta) => {
              if (actionMeta?.action === "set-value") {
                setIsMenuExpand(false);
              } else if (actionMeta?.action === "input-change") {
                setIsMenuExpand(true);
              }
              setInputValue(newValue);
            }}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            value={value}
            styles={styles}
          />
        ) : (
          <CreatableSelect
            components={components}
            inputValue={inputValue}
            options={tagSuggestion}
            isClearable={isClearable ? isClearable : true}
            isMulti={isMulti}
            menuIsOpen={isMenuExpand}
            closeMenuOnSelect={true}
            onChange={(newValue) => {
              setValue(newValue);
              onChange(newValue);
            }}
            isDisabled={disable !== undefined ? disable : false}
            isOptionDisabled={(option) =>
              disableOptions !== undefined ? option.value > 0 : false
            }
            className={className}
            onInputChange={(newValue, actionMeta) => {
              if (actionMeta?.action === "set-value") {
                setIsMenuExpand(false);
              } else if (actionMeta?.action === "input-change") {
                setIsMenuExpand(true);
              }
              setInputValue(newValue);
            }}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            value={value}
            styles={styles}
          />
        )
      ) : isCratable ? (
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable={isClearable ? isClearable : true}
          isMulti={isMulti}
          menuIsOpen={isMenuExpand}
          closeMenuOnSelect={true}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          isDisabled={disable !== undefined ? disable : false}
          isOptionDisabled={(option) =>
            disableOptions !== undefined ? option.value > 0 : false
          }
          className={className}
          onInputChange={(newValue, actionMeta) => {
            if (actionMeta?.action === "set-value") {
              setIsMenuExpand(false);
            } else if (actionMeta?.action === "input-change") {
              setIsMenuExpand(true);
            }
            setInputValue(newValue);
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={value}
          styles={styles}
        />
      ) : (
        <Select
          className={className}
          isMulti={isMulti}
          placeholder={placeholder}
          defaultValue={inputValue}
          isDisabled={disable !== undefined ? disable : false}
          isClearable={isClearable ? isClearable : true}
          isRtl={false}
          isSearchable={true}
          name=""
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          options={tagSuggestion}
        />
      )}
    </>
  );
}
