import React, { useState, useContext, useEffect } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { Modal, Table } from "../../sharedComponent";
import Loader from "../../sharedComponent/Loader/Loader";
import Toaster from "../../sharedComponent/Toaster/Toaster";
import { AppContext } from "../../store/context";
import APIHelper from "../../utils/config/apiHelper";
import APIURLConstant from "../../utils/config/apiURLConstant";
import Pagination from "../../sharedComponent/Pagination/Pagination";
import { useRef } from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ButtonContainer from "../../sharedComponent/ButtonContainer/ButtonContainer";
import UnauthorizedUI from "../../sharedComponent/AccessComponents/unauthorized";
import DraggableList from "../../sharedComponent/draggableList/draggableList";
import { useNavigate } from "react-router-dom";

const CreateCategory = () => {
  const [loading, setLoading] = useState(false);
  const { userDetails } = useContext(AppContext);
  const [isCreateCategory, setIsCreateCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [role, setRole] = useState(null);
  const [selectedRole, setSelectedRole] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [showTagModal, setShowTagModal] = useState(false);
  const [selectedListOfTags, setSelectedListOfTags] = useState([]);
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState(null);
  const [selectedCategoryForDelete, setSelectedCategoryForDelete] = useState(
    {}
  );
  const [subCatList, setSubCatList] = useState(null);
  const [firstChild, setFirstChild] = useState(null);
  const [secondChild, setSecondChild] = useState(null);
  const multiselectRef = useRef();
  const [toasterMessage, setToasterMessage] = useState({
    showToaster: false,
    errorType: "",
    message: "",
  });
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    category: false,
  });
  const [sortStatus, setSortStatus] = useState("normal");
  const [sortStatusText, setSortStatusText] = useState("");
  const navigate = useNavigate();

  const column = [
    // { heading: "SR.NO", value: "checkbox", width: "w-11" },
    { heading: "CATEGORY NAME", value: "sectionName", width: "w-40" },
    { heading: "ASSIGNED ROLES", value: "userRoleNames", width: "w-40" },
    { heading: "STATUS", value: "isActive", width: "w-20" },
    { heading: "ACTION", value: "action", width: "w-20" },
  ];

  const isAccessible = userDetails && userDetails.roleIDList === "1" ? 1 : -1;

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          {/* <input type="checkbox" checked={props.isSelected} onChange={null} />{" "} */}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    if (userDetails.email !== "") {
      getUsersRoles();
      getSubCatList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (userDetails.email !== "") {
      getCategoryList(currentPage * 10, pageSize);
    }
  }, [currentPage, pageSize, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (categoryList.length > 0) {
      setTotalCount(categoryList[0].totalCount.value);
    }
  }, [categoryList]);

  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const getSubCatList = () => {
    setLoading(true);
    APIHelper.post(APIURLConstant.GET_ALL_SECTIONS_AND_SUBSECTIONS)
      .then((res) => {
        if (res) {
          console.log(res);
          setSubCatList(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error while getting a sub categories",
        });
      });
  };

  const getUsersRoles = () => {
    setLoading(true);
    APIHelper.get(APIURLConstant.GET_USER_ROLES)
      .then((res) => {
        const updatedTags = res.map((item) => {
          return {
            label: item.roleName,
            value: item.roleName,
            ...item,
          };
        });
        if (updatedTags && updatedTags.length > 0) {
          const selectedRole = updatedTags.filter(
            (element) => element?.roleID === 1
          );
          setUserRoleList([...updatedTags]);
          selectedRole[0]["isDisabled"] = true;
          setSelectedRole(selectedRole);
          setRole(selectedRole);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setIsCreateCategory(false);
    resetForm();
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        sectionID: selectedCategoryForEdit?.sectionID?.value ?? 0,
        sectionName: categoryName,
        userRoleIDs: selectedRole
          ?.map((item) => {
            return item.roleID;
          })
          .join(","),
        basePath: "",
        createdBy: userDetails.userID,
        createdDate: new Date(),
        modifiedBy: userDetails.userID,
        modifiedDate: new Date(),
        isActive: true,
        isDeleted: false,
      };
      createCategory(payload);
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please enter required fields",
      });
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    if (categoryName === "") {
      errorMessage.category = true;
      isFormValid = false;
    }

    return isFormValid;
  };

  const resetForm = () => {
    setCategoryName("");
    const selectedRole = role.filter((element) => element?.roleID === 1);
    setErrorMessage({ category: false });
    setRole(selectedRole);
    setSelectedCategoryForEdit(null);
    setSelectedRole(selectedRole);
  };

  const createCategory = (payload) => {
    setLoading(true);
    APIHelper.post(APIURLConstant.CREATE_RESOURCE_CATEGORY, payload)
      .then((data) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Category created successfully",
        });
        resetForm();
        setLoading(false);
        getCategoryList(0, pageSize);
        setIsCreateCategory(false);
      })
      .catch((err) => {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: `Failed to Create category`,
        });
        setLoading(false);
      });
  };

  const getCategoryList = (recordCount, rowCount = pageSize) => {
    setLoading(true);
    APIHelper.get(
      APIURLConstant.GET_ALL_RESOURCES_CATEGORIES(
        (recordCount = currentPage === 0 ? 0 : currentPage * pageSize),
        50 //rowCount
      )
    )
      .then((res) => {
        let dataList = [];
        if (res?.length > 0) {
          res.forEach((item) => {
            dataList.push({
              sectionID: {
                value: item.sectionID,
              },
              sectionName: {
                value: item.sectionName,
              },
              userRoleIDs: {
                value: item.userRoleIDs,
              },
              isSelected: {
                value: false,
              },
              totalCount: {
                value: item.totalCount,
              },
              isActive: {
                value: item.isActive,
              },
              isDeleted: {
                value: item.isDeleted,
              },
              createdDate: {
                value: item.createdDate,
              },
              userRoleNames: {
                value: item.userRoleNames,
              },
            });
          });
        }
        setCategoryList(dataList);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const DeleteResourceCategory = (sectionIDs, isDeleted, modifiedBy) => {
    setLoading(true);
    APIHelper.post(
      APIURLConstant.DELETE_RESOURCE_CATEGORY(sectionIDs, isDeleted, modifiedBy)
    )
      .then((res) => {
        setToasterMessage({
          showToaster: true,
          errorType: "success",
          message: "Category Deleted successfully",
        });
        setLoading(false);
        getCategoryList(0, pageSize);
      })
      .catch((err) => {
        setToasterMessage({
          showToaster: true,
          errorType: "error",
          message: "Error While Deleting Category!",
        });
        setLoading(false);
      });
  };

  const onChangeRole = (value) => {
    setRole(value);
  };

  const handleClickEdit = (item) => {
    setModalMessage({
      title: "Edit",
      message: "Do you want to edit this category ?",
    });
    setSelectedCategoryForEdit(item);
    setShowModal(true);
  };

  const handleClickSubCat = (item) => {
    const name = item?.sectionName?.value;
    if (name !== undefined) {
      navigate(`/${name.replace(/\s+/g, "-").toLowerCase()}/sub-categories`, {
        state: {
          categoryDetails: {
            sectionName: item?.sectionName?.value,
            sectionID: item?.sectionID?.value,
          },
        },
      });
    }
  };

  const handleClickAccordion = (item) => {
    if (firstChild !== null && firstChild !== undefined) {
      if (firstChild[0]?.sectionID === item.sectionID?.value) {
        setFirstChild(null);
        setSecondChild(null);
      } else {
        filterChild(item, "1");
      }
    } else {
      filterChild(item, "1");
    }
  };

  const filterChild = (item, level) => {
    if (level === "1") {
      const sectionID = item?.sectionID?.value;
      let dataFiltered = [];
      if (subCatList !== null && subCatList?.length > 0) {
        subCatList.filter((subCat) => {
          if (
            subCat.sectionID === sectionID &&
            subCat.isDeleted === false &&
            subCat.parentSubSectionID === 0
          ) {
            dataFiltered.push(subCat);
          }
        });

        setFirstChild(dataFiltered);
        if (dataFiltered?.length === 0) {
          setToasterMessage({
            errorType: "success",
            showToaster: true,
            message: "No Child Categories",
          });
        }
      }
    } else if (level === "2") {
      let dataFiltered = [];
      if (subCatList !== null && subCatList?.length > 0) {
        subCatList.filter((subCat) => {
          if (
            subCat.parentSubSectionID !== null &&
            subCat.parentSubSectionID !== null &&
            subCat.parentSubSectionID === item.subSectionID &&
            subCat.isDeleted === false
          ) {
            dataFiltered.push(subCat);
          }
        });
      }
      setSecondChild(dataFiltered);
      if (dataFiltered?.length === 0) {
        setToasterMessage({
          errorType: "success",
          showToaster: true,
          message: "No Child Categories",
        });
      }
    }
  };

  const handleClickSubAccordion = (item) => {
    if (secondChild !== null && secondChild !== undefined) {
      if (item.subSectionID === secondChild[0]?.parentSubSectionID) {
        setSecondChild(null);
      } else {
        filterChild(item, "2");
      }
    } else {
      filterChild(item, "2");
    }
  };

  const handleEditConfirm = () => {
    setIsCreateCategory(true);
    setShowModal(false);
    setCategoryName(selectedCategoryForEdit?.sectionName?.value);
    const ids = selectedCategoryForEdit?.userRoleIDs?.value?.split(",");
    const selectedRoles = userRoleList?.filter((item) => {
      return ids?.includes(item?.roleID?.toString());
    });
    setRole(selectedRoles);
    setSelectedRole(selectedRoles);
  };

  const handleClickDelete = (item) => {
    setModalMessage({
      title: "Delete",
      message: "Do you want to delete this category ?",
    });
    setSelectedCategoryForDelete(item);
    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    setShowModal(false);
    DeleteResourceCategory(
      selectedCategoryForDelete?.sectionID?.value,
      true,
      userDetails?.userID
    );
  };

  const handleMultipleDelete = () => {
    let deleteRequests = [];
    categoryList.forEach((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.sectionID.value);
      }
    });
    let messageString = "";
    if (deleteRequests.length !== categoryList.length) {
      messageString = "Do you want to Delete selected category ?";
    } else {
      messageString = "Do you want to Delete All selected categories ?";
    }
    if (deleteRequests.length > 0) {
      setShowModal(true);
      setModalMessage({
        title: "Multiple Delete",
        message: messageString,
      });
    } else {
      setToasterMessage({
        showToaster: true,
        errorType: "error",
        message: "Please select atleast one Category",
      });
    }
  };

  const handleMultiDeteConfirm = () => {
    setShowModal(false);
    let deleteRequests = [];
    categoryList.forEach((row) => {
      if (row.isSelected.value === true) {
        deleteRequests.push(row.sectionID.value);
      }
    });
    DeleteResourceCategory(deleteRequests?.join(","), true, userDetails.userID);
  };

  const onSelectTableRow = (e, object) => {
    e.stopPropagation();
    const SelectedData = categoryList?.map((ele) => {
      if (ele.sectionID === object.sectionID) {
        return { ...ele, ...{ isSelected: { value: !ele?.isSelected.value } } };
      } else {
        return ele;
      }
    });
    setCategoryList(SelectedData);
  };

  const onClickSelectAll = (e, isAllSelected) => {
    e.stopPropagation();
    const rowData = categoryList?.map((ele) => {
      if (isAllSelected) {
        return { ...ele, ...{ isSelected: { value: false } } };
      } else {
        return { ...ele, ...{ isSelected: { value: true } } };
      }
    });
    setCategoryList(rowData);
  };

  const handleOpenList = (tags) => {
    setShowTagModal(true);
    setSelectedListOfTags(tags);
  };

  const handleSelect = (e) => {
    const adminRole = userRoleList.filter((element) => element?.roleID === 1);
    if (e.length === 0) {
      setSelectedRole(adminRole);
      return false;
    } else {
      const isAdminRole = e.find((element) => element?.roleID === 1);
      isAdminRole ? setSelectedRole(e) : setSelectedRole([...e, ...adminRole]);
    }
  };

  const handleSort = (name) => {
    if (sortStatus === "normal" || sortStatusText !== name) {
      setSortStatus("ascending");
      setSortStatusText(name);
    } else if (sortStatus === "ascending") {
      setSortStatus("descending");
      setSortStatusText(name);
    } else if (sortStatus === "descending") {
      setSortStatus("ascending");
      setSortStatusText(name);
    }
  };
  useEffect(() => {
    if (sortStatus && sortStatusText) {
      setSortConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus, sortStatusText]);

  const setSortConfig = () => {
    let sortableItems = [...categoryList];

    if (sortStatus === "ascending" || sortStatus === "descending") {
      if (sortStatusText === "CATEGORY NAME") {
        const dataSorted = sortableItems.sort((a, b) => {
          if (
            a.sectionName.value.toLowerCase() <
            b.sectionName.value.toLowerCase()
          ) {
            return sortStatus === "ascending" ? -1 : 1;
          }
          if (
            a.sectionName.value.toLowerCase() >
            b.sectionName.value.toLowerCase()
          ) {
            return sortStatus === "ascending" ? 1 : -1;
          }
          return 0;
        });
        setCategoryList(dataSorted);
      }

      if (sortStatusText === "ASSIGNED ROLES") {
        const dataSorted = sortableItems.sort((a, b) => {
          if (
            a.userRoleNames.value.toLowerCase() <
            b.userRoleNames.value.toLowerCase()
          ) {
            return sortStatus === "ascending" ? -1 : 1;
          }
          if (
            a.userRoleNames.value.toLowerCase() >
            b.userRoleNames.value.toLowerCase()
          ) {
            return sortStatus === "ascending" ? 1 : -1;
          }
          return 0;
        });
        setCategoryList(dataSorted);
      }
      if (sortStatusText === "STATUS") {
        const dataSorted = sortableItems.sort((a, b) => {
          if (a.isActive.value.toLowerCase() < b.isActive.value.toLowerCase()) {
            return sortStatus === "ascending" ? -1 : 1;
          }
          if (a.isActive.value.toLowerCase() > b.isActive.value.toLowerCase()) {
            return sortStatus === "ascending" ? 1 : -1;
          }
          return 0;
        });
        setCategoryList(dataSorted);
      }
    }
  };

  const onDragEnd = (props) => {};
  return (
    <div>
      {isAccessible === 1 ? (
        <div className="mt-16 md:mt-9 ">
          <Loader showLoading={loading} />
          <div className="mx-auto p-2 md:p-7">
            {isCreateCategory ? (
              <>
                <p className="text-[#c8102e] font-semibold text-[19px] py-3">
                  {selectedCategoryForEdit === null
                    ? "Create Category"
                    : "Edit Category"}
                </p>

                <div className="bg-white border border-gray-200 rounded-lg shadow-md w-full p-4">
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12 lg:2/12 w-full">
                      <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                        Category Name :
                      </p>
                    </div>
                    <div className="w-full lg:w-8/12 md:w-7/12">
                      <input
                        value={categoryName}
                        onChange={(e) => {
                          setCategoryName(e.target.value);
                          setErrorMessage({ category: false });
                        }}
                        placeholder="Please enter category name"
                        className={`question-input resize-none border textAreaHeight py-1 w-full border focus:outline-none focus:shadow-outline
                   
                    ${
                      errorMessage && errorMessage.category === true
                        ? "border-red-400"
                        : "border-grey-400"
                    } text-gray-900 rounded pl-2`}
                      />
                      {errorMessage && errorMessage.category === true && (
                        <div className="text-start">
                          <span className="text-red-500 text-sm">
                            Please enter the category name{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className="md:w-2/12 lg:2/12 w-full">
                      <p className="text-sm pb-4 lg:p-0 md:p-0 lg:text-end lg:pt-2 text-center required text-[#394150] font-bold">
                        Role :
                      </p>
                    </div>
                    <div className="w-full lg:w-4/12 md:w-7/12">
                      {/* <Multiselect
                    displayValue="value"
                    showCheckbox={true}
                    onSelect={(e) => onChangeRole(e)}
                    options={userRoleList}
                    selectedValues={selectedRole}
                    placeholder={"Select Role..."}
                    //ref={multiselectRef}
                    onRemove={(event) => {
                      setRole(event);
                    }}
                  /> */}
                      <ReactSelect
                        options={userRoleList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={(e) => handleSelect(e)}
                        value={selectedRole}
                      />
                    </div>
                  </div>

                  <div className="flex-none lg:flex md:flex lg:space-x-4 py-4">
                    <div className=" md:w-2/12 w-full" />
                    <ButtonContainer
                      handleSubmit={() => handleSubmit()}
                      handleCancel={() => handleCancel()}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="">
                <div className="flex flex-wrap justify-between w-full py-3">
                  <p className="text-[#c8102e] font-semibold text-[19px] ">
                    Resource Category List
                  </p>

                  <div className="flex flex-wrap gap-2">
                    <div className="justify-between items-end  space-x-2  ">
                      <button
                        type="button"
                        onClick={() => {
                          setIsCreateCategory(true);
                        }}
                        className="bg-blue-900 p-1 h-8 w-20 rounded-md text-white"
                      >
                        Add
                      </button>
                      {/* <button
                        type="button"
                        onClick={() => {
                          handleMultipleDelete();
                        }}
                        className="bg-blue-900 p-1 h-8 w-20 rounded-md text-white"
                      >
                        Delete
                      </button> */}
                    </div>
                  </div>
                </div>
                <DraggableList
                  data={categoryList}
                  isDeleteButton={true}
                  showSubCategory={true}
                  showChildAccordion={true}
                  handleClickEdit={handleClickEdit}
                  handleDeleteUser={handleClickDelete}
                  setTableData={setTableData}
                  handleOpenTagList={handleOpenList}
                  onClickSelectAll={onClickSelectAll}
                  onSelectTableRow={onSelectTableRow}
                  sortStatusText={sortStatusText}
                  sortStatus={sortStatus}
                  handleSort={handleSort}
                  onDragEnd={onDragEnd}
                  handleClickSubCat={handleClickSubCat}
                  handleClickAccordion={handleClickAccordion}
                  handleClickSubAccordion={handleClickSubAccordion}
                  firstChild={firstChild}
                  secondChild={secondChild}
                />
                {/* <Table
                  data={categoryList}
                  column={column}
                  isDeleteButton={true}
                  handleClickEdit={handleClickEdit}
                  handleDeleteUser={handleClickDelete}
                  setTableData={setTableData}
                  handleOpenTagList={handleOpenList}
                  onClickSelectAll={onClickSelectAll}
                  onSelectTableRow={onSelectTableRow}
                  sortStatusText={sortStatusText}
                  sortStatus={sortStatus}
                  handleSort={handleSort}
                />
                <Pagination
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  setCanNextPage={setCanNextPage}
                  setCanPreviousPage={setCanPreviousPage}
                  setCurrentPage={setCurrentPage}
                /> */}
              </div>
            )}
          </div>
          <Modal
            showModal={showModal}
            handleClose={() => {
              setShowModal(false);
              setSelectedCategoryForEdit(null);
            }}
            modalMessage={modalMessage}
            handleClick={
              modalMessage.title === "Edit"
                ? handleEditConfirm
                : modalMessage.title === "Multiple Delete"
                ? handleMultiDeteConfirm
                : handleDeleteConfirm
            }
          />

          {/* TagList Popup */}
          <Modal
            showModal={showTagModal}
            handleClose={() => setShowTagModal(false)}
            isTagList={true}
            data={selectedListOfTags}
            title={"Assigned User Roles"}
          />

          {/* Toaster */}

          <Toaster
            toasterMessage={toasterMessage}
            setToasterMessage={setToasterMessage}
          />
        </div>
      ) : (
        <UnauthorizedUI />
      )}
    </div>
  );
};
export default CreateCategory;
